@import "src/shared/styles/colors.scss";

.accordion-container {
    .accordion {
        overflow: hidden;

        &.overflow-initial {
            overflow: initial !important;
        }
        .accordion-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            padding: 16px 16px;
            background-color: white;
            height: 48px;
            color: $black;
            font-weight: bold;
            cursor: pointer;

            &.grey {
                background-color: $neutral-100;
            }

            &.white {
                background-color: white !important;
            }

            &.show {
                transition-delay: 0s;
                border-radius: 8px 8px 0 0;
            }

            &.hide {
                border-radius: 8px;
                transition-delay: 0.5s;
            }

            &.disabled {
                border-radius: 8px;
                pointer-events: none;
            }

            &:not(.disabled) {
                i.show {
                    transform: rotate(180deg);
                }
            }

            i {
                transition: 0.5s;
            }

            .header-content {
                display: flex;
                flex-direction: row;
                gap: 8px;

                .header-texts {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            &.step-status-icon {
                width: 40px;
                height: 40px;
                background: $lilas-50;
                color: $primary;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                &.validated {
                    background: $success-50;
                }
            }

            &.icon-button {
                width: 40px;
                height: 40px;
                justify-content: center;
                &.opened {
                    background: $neutral-200;
                    color: $neutral-900;
                    &:hover {
                        background: $neutral-500;
                    }
                }
            }

            &.title-icon-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .toggle-icon-container {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                border-radius: 8px;
                background-color: $neutral-200;
            }

            &.no-padding {
                padding: 0 16px;

                .toggle-icon-container {
                    width: 32px;
                    height: 32px;
                }
            }

            &:hover {
                .toggle-icon-container {
                    transition: 0.2s;
                }
            }

            &.size-md {
                height: 80px;

                &.show {
                    border-radius: 10px 10px 0 0;
                }

                &.hide {
                    border-radius: 10px;
                }

                &.disabled {
                    border-radius: 10px;
                    pointer-events: none;
                }

                .title-icon-wrapper {
                    width: 40px;
                    height: 40px;
                    background-color: $neutral-50;
                    border-radius: 10px;
                }

                .toggle-icon-container {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        &-step {
            .header {
                &.show {
                    border-bottom: 1px solid $neutral-200;
                }
                &.disabled {
                    filter: none;
                    opacity: 0.8;
                }
            }
            .footer {
                padding: 16px;
                border-top: 1px solid $neutral-200;
            }
        }

        .accordion-content {
            background-color: $white;
            overflow: hidden;
            box-sizing: border-box;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            max-height: 0;
            transition: 0.5s ease-in-out;

            &.overflow-initial {
                overflow: initial;
            }

            &.animating {
                overflow-y: hidden;
            }

            &.grey {
                background-color: $neutral-50;
            }

            &.white {
                background-color: white !important;
            }

            &.show {
                max-height: 500vh;
                transition: 0.5s ease-in-out;
            }

            .content-padding {
                padding: 0 16px 16px;
            }

            &:not(.hide).size-md {
                border-top: 1px solid $neutral-100;
            }

            &.size-md {
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }

        ::-webkit-scrollbar-track {
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            border: 6px solid transparent;
        }
    }
}
