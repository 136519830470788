@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.input-phone-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .input-label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    .input {
        width: calc(100% - 20px);
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $neutral-100;
        height: 40px;
        border-radius: 10px;
        padding: 0 10px;
        transition: 0.2s;
        font-size: 14px;
        background-color: $neutral-100;

        .input-field {
            width: 100% !important;
            height: 100%;
            background-color: transparent;

            all: unset;

            &:-webkit-autofill,
            &:-webkit-autofill:focus {
                transition: background-color 600000s 0s, color 600000s 0s;
            }

            &.disabled {
                color: $neutral-400;
            }
        }

        &.variant-primary {
            border: 1px solid transparent;

            &.focused {
                border: 1px solid $lilas-700;
            }
        }

        &.variant-success {
            border: 1px solid $success-500;
        }

        &.variant-error {
            border: 1px solid $error-500;
        }

        &.variant-warning {
            border: 1px solid $warning-500;
        }
    }

    .under-message {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.variant-primary {
            color: $primary;
        }

        &.variant-success {
            color: $success-500;
        }

        &.variant-error {
            color: $error-500;
        }

        &.variant-warning {
            color: $warning-500;
        }
    }

    .select-container {
        height: 20px;
        position: relative;

        @include breakpoint(sm) {
            width: 100%;
        }

        .select {
            height: 100%;

            .header {
                padding: 0 10px 0 0;
                height: 35px;
                border: 1px solid transparent !important;
                margin-bottom: -2px;
            }
        }
    }
}

.phone-prefix-select {
    border-right: 2px solid $neutral-200;
    margin-right: 20px;
    padding-right: 8px;

    .select .select-header {
        height: 20px;
        padding: 0;
        border: 0 !important;
        width: initial !important;
        gap: 4px;
    }

    .select .select-item {
        padding: 5px 0;

        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    .country-flag {
        width: 20px;
    }

    .label-item {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 60px;
    }

    &.disabled {
        opacity: 0.5;

        .select {
            .select-header {
                cursor: not-allowed;
            }
        }
    }
}