@import "src/shared/styles/colors.scss";

.linear-progress {
    height: 8px;
    width: 100%;
    border-radius: 24px;
    background-color: $neutral-200;
    position: relative;
    overflow: hidden;
}

.bar {
    height: 8px;
    width: 25%;
    border-radius: 24px;
    background-color: $lilas-900;
    position: absolute;
    left: 0;
    top: 0;
    animation: linear-progress-animation 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes linear-progress-animation {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}
