@import "src/shared/styles/colors.scss";

.simple-select-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .simple-select-option {
        display: inline-flex !important;

        &.disabled {
            color: $neutral-400;
        }
    }
    
    .under-message {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.variant-primary {
            color: $lilas-900;
        }

        &.variant-success {
            color: $success-500;
        }

        &.variant-error {
            color: $error-500;
        }

        &.variant-warning {
            color: $warning-500;
        }
    }
}
