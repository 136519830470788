@import "src/shared/styles/colors.scss";

.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .empty-img {
        width: 200px;
    }
}
