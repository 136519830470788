@import "src/shared/styles/colors.scss";

.page-header-container {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: $neutral-100;

    .title {
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 30px !important;
        padding-bottom: 8px;
    }
}
