@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.transfert-details-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .transfert-details-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .service-row {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            .transaction-service-img {
                height: 20px;
                width: 20px;
            }
            span {
                color: $black;
            }
        }
    }

    .input {
        background-color: $neutral-100;
    }

    .inputs-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .error {
        border: 1px solid $error;
        border-radius: 10px;
    }

    .amount-error-message {
        color: $error;
        font-weight: bold;
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    .divider {
        height: 1px;
        background-color: $neutral-200;
    }

    .form-amount-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
