@import "src/shared/styles/colors.scss";

$colors-map: (
    "primary": (
        "bg": $neutral-50,
        "border": $neutral-200,
    ),
    "success": (
        "bg": $success-50,
        "border": $success-200,
    ),
    "error": (
        "bg": $error-50,
        "border": $error-200,
    ),
    "warning": (
        "bg": $warning-50,
        "border": $warning-200,
    ),
);

$jla-alert-padding: 1rem;
$jla-alert-border-radius: 0.625rem;

$jla-alert-close-size: 1.25rem;
$jla-alert-close-color: $neutral-900;

.alert {
    padding: $jla-alert-padding;
    border-radius: $jla-alert-border-radius;

    @each $color, $data in $colors-map {
        &--#{$color} {
            border: 1px solid map-get($data, "border");
            background-color: map-get($data, "bg");
        }
    }

    &-content {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &-children {
        display: flex;
        width: 100%;
        align-items: center;
    }
}

.alert-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 10px;
    padding: 16px;

    &.alert-lilas {
        border-radius: 10px;
        border: 1px solid $lilas-200;
        background: $lilas-50;

        .icon {
            color: $lilas-900;
        }
    }

    &.alert-success {
        border-radius: 10px;
        border: 1px solid $success-200;
        background: $success-50;

        .icon {
            color: $success-500;
        }
    }

    &.alert-warning {
        border-radius: 10px;
        border: 1px solid $warning-200;
        background: $warning-50;

        .icon {
            color: $warning-500;
        }
    }

    &.alert-error {
        border-radius: 10px;
        border: 1px solid $error-200;
        background: $error-50;

        .icon {
            color: $error-500;
        }
    }

    .first-part {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;
        align-items: center;

        &.top-bottom {
            .content-part {
                flex-direction: column;

                .buttons-part {
                    margin-top: 8px;
                    align-self: flex-start;
                }
            }
        }
        &:not(.top-bottom) {
            .content-part {
                align-items: center;
            }
        }

        i {
            width: 24px;
            height: 24px;
        }

        .content-part {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 16px;

            .content-text {
                display: flex;
                flex-direction: column;
            }

            .buttons-part {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
        }
        .close-button-part {
            i {
                width: 20px;
                height: 20px;
            }
        }
    }

    .second-part {
        .buttons-part {
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
    }
}
