@import "./shared/styles/colors.scss";
@import "./shared/styles/general.scss";
@import "./shared/styles/borders.scss";
@import "./shared/styles/mixins.scss";
@import "./shared/styles/typography.scss";

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $neutral-100;
    --toastify-font-family: "Inter", sans-serif;
    --toastify-toast-bd-radius: 10px;
    --toastify-text-color-light: black;
}

body.cursor-wait,
body.cursor-wait * {
    cursor: wait !important;
}
