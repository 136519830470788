@import "src/shared/styles/colors.scss";

.create-export-template-personalised {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .create-export-template-form-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .required-hint {
            display: flex;
            flex-direction: row;
            justify-content: end;
            gap: 3px;
        }
        .date-range-label {
            display: flex;
            flex-direction: column;

            span {
                &:first-child {
                    font-weight: bold;
                }
                &:last-child {
                    color: $neutral-500;
                }
            }
        }
        .select-container {
            .select {
                .select-header {
                    width: calc(100% - 32px);
                }
            }
        }
    }

    .tooltip-container .tooltip-top {
        transform: translateX(-100%);
    }

    .date-picker-container {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .error-message-one-year {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $error;
            gap: 4px;
        }
    }
}

.export-format-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .email-list-show {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .item {
            display: flex;
            border-radius: 10px;
            background-color: $lilas-50;
            font-weight: bold;
            width: fit-content;
            padding: 3px 8px;
            gap: 4px;
            span {
                margin-top: 2px;
            }
        }
    }

    .email-input-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 8px;

        button {
            flex-grow: 1;
            margin-bottom: 1px;

            &.error {
                margin-bottom: 30px;
                transition: 0;
            }
        }
    }

    .checkbox-save-template {
        display: flex;
        flex-direction: column;
    }
}

.export-informations-drawer-body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .export-informations-item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: $white;
        border-radius: 10px;
        padding: 16px;

        .export-informations-item-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
        }

        .export-informations-item-list {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .listed::before {
                content: "";
                display: inline-block;
                width: 4px;
                height: 4px;
                background-color: $lilas-500;
                border-radius: 50%;
                margin-right: 8px;
                vertical-align: middle; /* Adjust as needed for alignment */
            }
        }
    }
}
