@import "src/shared/styles/colors.scss";

.task-success-dialog-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;

    .amount-summary {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
}
