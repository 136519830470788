@import "src/shared/styles/colors.scss";

.modal-container {
    .input {
        background-color: $neutral-100;
    }
    .password-validation-container {
        padding: 20px;
        border-radius: 10px;
        transition: 0.2s;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .password-validation-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: bold;
            gap: 16px;
        }

        .form-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    .modal {
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        overflow: auto;
        background-color: rgba($black, 0.4);
    }

    .modal-header {
        display: flex;
        padding: 15px 20px;
        border-bottom: 1px solid $neutral-200;

        .modal-header-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            width: 100%;

            .header-close-button {
                margin-left: auto;
            }
        }
    }

    .modal-content {
        background-color: $white;
        margin: auto;
        border-radius: 10px;

        &.sm {
            width: 440px;
        }

        &.md {
            width: 600px;
        }

        &.lg {
            width: 80vw;
            height: 80vh;
        }
        .modal-padding {
            padding: 16px;
        }

        .success-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            text-align: center;
            img {
                width: 50%;
            }
        }
    }

    .modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
        border-top: 1px solid $neutral-200;
        padding: 15px 20px;
    }

    .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
    }
}
