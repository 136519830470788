@import "src/shared/styles/colors.scss";

.drawer-input-container {
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
    justify-content: space-between;
    background-color: $neutral-100;
    border-radius: 10px;
    height: 24px;
    cursor: pointer;

    &.disabled {
        color: $neutral-500;
        cursor: not-allowed;
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        span {
            font-weight: bold;
        }

        .transaction-service-img {
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}
