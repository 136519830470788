@charset "utf-8";

@import "src/shared/styles/colors.scss";

.custom-date-picker {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .label-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .rdtPicker {
        position: relative;
        border: 0;
        padding: 0;
        box-shadow: none;
        border-radius: 10px;
        width: 100%;
        font-size: 14px;

        table {
            width: 100%;
            margin: 0;
        }

        th {
            border-bottom: none !important;
            text-align: center;
            box-sizing: border-box;
        }

        td {
            text-align: center;
            cursor: pointer;
            box-sizing: border-box;

            &:hover {
                background: $neutral-200;
            }
        }

        td.rdtMonth,
        td.rdtYear {
            height: 50px;
            width: 25%;
            cursor: pointer;
        }
        td.rdtMonth:hover,
        td.rdtYear:hover {
            background: #eee;
        }

        .rdtSwitch {
            width: 100%;

            &:hover {
                cursor: pointer;
                background: $neutral-200;
            }
        }

        .rdtPrev,
        .rdtNext {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                cursor: pointer;
                background: $neutral-200;
            }

            span {
                display: none;
            }
        }

        .rdtPrev {
            &::after {
                font-family: "Julaya-v2";
                content: "\e9af";
            }
        }

        .rdtNext {
            &::after {
                font-family: "Julaya-v2";
                content: "\e9b0";
            }
        }

        .rdtSwitch,
        .dow,
        .rdtDay {
            height: 40px;
        }

        .dow,
        .rdtDay {
            width: calc(100% / 7); // 7 days in a week
        }

        .rdtDay,
        .rdtMonth,
        .rdtYear {
            border-radius: 10px;
        }

        .rdtActive {
            background-color: $lilas-500 !important;
            color: $neutral-900 !important;
            text-shadow: none !important;

            &:hover {
                border-radius: 10px !important;
            }
        }

        .rdtInActiveRange {
            background-color: $lilas-50 !important;
            text-shadow: none !important;
            border-radius: revert;
        }

        .startDate {
            border-top-right-radius: revert;
            border-bottom-right-radius: revert;
        }

        .endDate {
            border-top-left-radius: revert;
            border-bottom-left-radius: revert;
        }

        .rdtToday {
            color: $lilas-900 !important;
            position: relative;

            &::before {
                content: "";
                display: inline-block;
                border-left: none !important;
                border-bottom: none !important;
                bottom: 20% !important;
                right: calc(50% - 2px) !important;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $lilas-900;
                position: absolute;
            }

            &.rdtActive {
                color: $neutral-900 !important;

                &::before {
                    background-color: $neutral-900;
                }
            }
        }

        .rdtNotInCurrentMonth {
            color: $neutral-500 !important;

            &.rdtActive {
                color: $neutral-900 !important;
            }
        }
    }
}
