@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.operation-details-container {
    margin-bottom: 16px;

    .accordion-container {
        width: 100%;
    }
    .accordion-container .accordion .accordion-header {
        background-color: $white;
    }

    .accordion-container .accordion .accordion-content {
        background-color: $white;
    }

    .operation-details-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .content-item {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            @include breakpoint(md) {
                flex-direction: column;
            }

            .status-icon-with-text {
                max-width: 100px;
            }
        }

        .content-item > *:first-child {
            flex: 1;
            text-align: left;
            padding-right: 8px;
            font-weight: bold;
            color: $neutral-500;
        }

        .content-item > *:last-child {
            flex: 1;
            font-weight: bold;
            text-align: left;
        }
    }
}
