@import "src/shared/styles/colors.scss";

.drawer-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid $neutral-200;
    background-color: $white;
    padding: 20px 16px;

    .drawer-header-title-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 475px;
        overflow: hidden;
        gap: 8px;

        .title-section-content {
            display: flex;
            flex-direction: column;
            .title {
                font-weight: bold;
            }
        }

        .title-section-image {
            i {
                width: 28px;
                height: 28px;
            }
        }
    }

    .drawer-header-buttons-section {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: center;

        .button-container {
            height: 40px;
            width: 40px;
        }
        .select-container .select .select-header {
            gap: 0;
        }
    }
}
