@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.create-beneficiary-drawer-body-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;

    .beneficiary-informations-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
    }

    .forms-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .beneficiary-informations-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .form-part {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .inline-inputs {
            display: flex;
            flex-direction: row;
            gap: 25px;

            .input-container {
                width: 100%;
            }
            @include breakpoint(sm) {
                flex-direction: column;
                gap: 0;
            }
        }

        .no-account-added {
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 10px;
        }

        .button-add-account {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            width: 100%;
        }

        .buttons-container {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        .account-list {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .account-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                background-color: $neutral-100;
                border-radius: 10px;
                padding: 10px;

                &.error {
                    border: 1px solid $error;
                }

                .account-item-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    .text-part {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .account-item-actions {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    button {
                        width: 40px !important;
                    }
                }
            }
        }
        .button-container {
            width: 100%;
        }

        .beneficiary-service-select {
            width: 100%;
            box-sizing: border-box;
            background-color: $neutral-50;
        }

        .add-account-button {
            button {
                width: 100%;
            }
        }
    }
    .button-send-beneficiary {
        display: flex;
        width: 95%;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 15px;
        align-self: center;
        text-align: center;

        .button-part {
            gap: 15px;
            display: flex;
            flex-direction: row;
        }

        .button-send {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    .button-part {
        display: flex;
        flex-direction: row;
        border-top: 2px solid $neutral-200;
        padding-top: 16px;
        gap: 16px;
        justify-content: flex-end;
    }

    .add-account-form {
        background-color: $neutral-100;
        border-radius: 10px;

        .form-part {
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .buttons-part {
            display: flex;
            flex-direction: row;
            gap: 16px;
            border-top: 1px solid $neutral-200;
            padding: 16px;

            .button-container {
                width: 100%;
            }
        }

        .select-container .select .select-header {
            background-color: $white;
        }

        .select-container .select .select-header.show {
            background-color: $white;
        }

        .input-container .input {
            background-color: $white;
        }

        .input-phone-container .input {
            background-color: $white;
        }
    }
}

.service-icon {
    width: 20px;
    border-radius: 4px;
}
