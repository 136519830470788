@import "src/shared/styles/colors.scss";
@import "src/shared/styles/fonts/icons.scss";

.create-task-drawer-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: $neutral-900;
    .create-task-drawer-body-item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .select-container {
            .select {
                .select-header {
                    width: calc(100% - 32px);
                    background-color: #ffffff;
                }
            }
        }

        .input {
            background-color: #ffffff;
        }
    }

    .switch-tooltip {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    .create-task-drawer-variables-container {
        display: flex;
        flex-direction: row;
        gap: 4px;

        .create-task-drawer-variables {
            cursor: pointer;
            background-color: $neutral-300;
            border-radius: 5px;
            padding: 4px;
            &:hover {
                background-color: $neutral-200;
            }
        }
    }

    #normal:checked + label {
        background: #4caf50;
        &:before {
            left: 67px;
        }
    }

    .task-information-tooltip-container {
        .tooltip-container {
            width: 100%;

            .tooltip {
                background-color: $neutral-100;
            }
        }
        .task-intofmation-tooltip {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px;
            gap: 8px;
            background-color: $neutral-100;
            height: 50px;
            border-radius: 10px;
            cursor: pointer;
            font-weight: bold;
        }
    }
}
