@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.debit-drawer-body-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: $neutral-100;

    .list-container li {
        background-color: transparent;
        margin-bottom: 0;
        border-radius: 0;
        border: 0px;
        transition: 0;
        padding: 0;
    }

    .search-input {
        margin-bottom: 16px;

        .input {
            background-color: $white;
        }
    }

    .error-message-no-wallets-available {
        padding: 0 15px;
        margin-bottom: 8px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .uneligible-image {
            width: 160px;
            height: 120px;
            margin: auto;
        }
    }

    .create-user-section {
        background-color: $white;
        border-radius: 10px;
        margin-bottom: 16px;
        .title-section {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            padding: 16px;
            border-bottom: 1px solid $neutral-200;

            .text {
                display: flex;
                flex-direction: column;
            }
        }

        .content-section {
            .button-container {
                width: 100%;
            }
            padding: 16px;
        }
    }

    .item-container {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 15px;
        background-color: $white;
        border-radius: 10px;
        transition: 0.2s;
        animation-duration: 0.5s;
        animation-delay: 0.1s;
        margin-bottom: 16px;

        &:hover {
            background-color: $neutral-200;
            cursor: pointer;
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .left-content {
            display: flex;
            flex-direction: row;
            align-items: center;

            i {
                width: 28px;
                height: 28px;
            }
        }

        .item-content {
            display: flex;
            flex-direction: column;
            padding-left: 8px;
            gap: 2px;
        }
    }
}
