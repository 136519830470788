@import "src/shared/styles/colors.scss";

.dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .stat-card-list {
        display: flex;
        align-items: stretch;
        flex-direction: row;
        grid-gap: 16px;
        gap: 16px;
        flex-wrap: wrap;
    }

    .stat-card-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 16px;
        border-radius: 10px;
        background-color: $white;
        gap: 16px;
        flex: 1 1;

        .stat-card-content {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        &.loading {
            height: 52px;
            animation: glow 0.7s ease-in-out infinite alternate;
        }

        &.disabled {
            background-color: $neutral-50;
        }

        &.delay-1 {
            animation-delay: 0.1s;
        }

        &.delay-2 {
            animation-delay: 0.2s;
        }

        &.delay-3 {
            animation-delay: 0.3s;
        }

        &.delay-4 {
            animation-delay: 0.4s;
        }

        &.delay-5 {
            animation-delay: 0.5s;
        }
    }

    .quest-row {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        .quest-name {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }

    .todo-widget-container {
        .todo-list-icon {
            width: 28px !important;
            height: 28px !important;
        }
        .todo-widget-header {
            height: 80px;
            border-radius: 10px 10px 0 0;
            background: $white;
            padding: 0 16px;
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            border-bottom: 1px solid $neutral-100;
        }

        .progress-bar-container {
            width: 100%;
            background-color: $neutral-200;
            border-radius: 8px;
            height: 16px;
            margin-bottom: 16px;
            margin-top: 8px;

            .progress-bar-fill {
                background-color: $lilas-900;
                border-radius: 8px;
                height: 16px;
                transition: width 0.3s;
            }
        }

        .todo-list-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            background: $white;
            padding: 16px;
            border-radius: 0 0 10px 10px;

            &.no-gap {
                gap: 0px;
            }

            .item-list-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
                border-radius: 10px;

                .left-content {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;

                    i {
                        max-height: 28px !important;
                    }

                    .left-content-texts {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .button-link {
                    display: flex;
                    justify-content: flex-end;
                    min-width: 20%;
                }
            }

            .loading-placeholder {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .placeholder {
                    width: 100%;
                    height: 72px;
                    border-radius: 10px;
                    animation: glow 0.7s ease-in-out infinite alternate;

                    &:nth-child(1) {
                        animation-delay: 0.1s;
                    }
                    &:nth-child(2) {
                        animation-delay: 0.2s;
                    }
                    &:nth-child(3) {
                        animation-delay: 0.6s;
                    }
                }
            }

            .up-to-date {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 16px;

                .text-part {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    text-align: center;
                }

                .up-to-date-image {
                    width: 200px;
                }
            }
        }
    }
}

$base-size: 1rem;
$border-radius: 0.625rem;

$card-map: (
    "wallet": (
        "img-bg": $lilas-500,
        "bg": $lilas-50,
    ),
    "creditcard": (
        "img-bg": $pink-500,
        "bg": $pink-50,
    ),
    "user": (
        "img-bg": $blue-500,
        "bg": $blue-100,
    ),
    "beneficiary": (
        "img-bg": $warning-400,
        "bg": $warning-50,
    ),
);

@mixin card-variant($self, $variant, $vals) {
    &-#{$variant} {
        border-color: map-get($vals, "img-bg");
        background-color: map-get($vals, "img-bg");

        #{$self}-content {
            background-color: map-get($vals, "bg");
        }
    }
}

.marketing-cards {
    flex-wrap: nowrap;
    grid-gap: 1rem;
    gap: 1rem;
    flex-direction: row;
    display: flex;
    align-items: stretch;

    button {
        width: 100%;
    }
}

.marketing-card {
    $self: &;
    border-radius: 0.625rem;
    border-width: 2px;
    border-style: solid;
    display: flex;
    width: 25rem !important;
    height: 10.625rem !important;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;

    .placeholder {
        width: 98%;
        height: 95%;
        margin: auto;
        border-radius: 10px;
        background-color: $neutral-50;
        animation: glow-transparent 0.7s ease-in-out infinite alternate;
    }

    @each $variant, $vals in $card-map {
        @include card-variant($self, $variant, $vals);
    }

    #{$self}-title {
        margin-bottom: 0.5rem;
    }

    #{$self}-img {
        width: 8.125rem;
        flex-basis: 8.125rem;
        flex-grow: 0;
        flex-shrink: 0;
        height: 100% !important;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    #{$self}-content {
        flex-grow: 1;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @keyframes glow-transparent {
        from {
            background-color: $neutral-100;
        }
        to {
            background-color: transparent;
        }
    }
}

@for $i from 0 through 100 {
    .width-#{$i} {
        width: unquote($i + "%");
    }
}
