@import "src/shared/styles/colors.scss";

.button-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    transition: 0.2s;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    inline-size: max-content;
    border: 0;

    span,
    i {
        transition: 0.2s;
    }

    &:hover {
        cursor: pointer;

        &.disabled {
            cursor: not-allowed;
        }
    }

    &.size-lg {
        height: 48px;
        padding: 12px;
    }

    &.size-md {
        height: 40px;
        padding: 10px;
    }

    &.size-sm {
        height: 32px;
        padding: 6px;

        span {
            font-size: 12px;
        }
    }

    &.variant-primary {
        border: 0;
        span {
            color: $white;
        }
        &.primary {
            background-color: $neutral-900;
            span {
                color: $white;
            }
            i {
                color: $white;
            }
            &.disabled {
                background-color: $neutral-300;
                span {
                    color: $white;
                }
                i {
                    color: $white;
                }
            }
            &:hover:not(.disabled) {
                background-color: $neutral-600;
            }
            &:active:not(.disabled) {
                background-color: $neutral-600;
                span {
                    color: $neutral-200;
                }
                i {
                    color: $neutral-200;
                }
            }
        }
        &.lilas {
            background-color: $lilas-500;
            span {
                color: $white;
            }
            i {
                color: $white;
            }
            &.disabled {
                background-color: $lilas-300;
                span {
                    color: $white;
                }
                i {
                    color: $white;
                }
            }
            &:hover:not(.disabled) {
                background-color: $lilas-600;
            }
            &:active:not(.disabled) {
                background-color: $lilas-600;

                span {
                    color: $lilas-200;
                }
                i {
                    color: $lilas-200;
                }
            }
        }
        &.success {
            background-color: $success-500;
            span {
                color: $white;
            }
            i {
                color: $white;
            }
            &.disabled {
                background-color: $success-300;
                span {
                    color: $white;
                }
                i {
                    color: $white;
                }
            }
            &:hover:not(.disabled) {
                background-color: $success-600;
            }
            &:active:not(.disabled) {
                background-color: $success-600;
                span {
                    color: $success-200;
                }
                i {
                    color: $success-200;
                }
            }
        }
        &.warning {
            background-color: $warning-500;
            span {
                color: $white;
            }
            i {
                color: $white;
            }
            &.disabled {
                background-color: $warning-300;
                span {
                    color: $white;
                }
                i {
                    color: $white;
                }
            }
            &:hover:not(.disabled) {
                background-color: $warning-600;
            }
            &:active:not(.disabled) {
                background-color: $warning-600;
                span {
                    color: $warning-200;
                }
                i {
                    color: $warning-200;
                }
            }
        }
        &.error {
            background-color: $error-500;
            span {
                color: $white;
            }
            i {
                color: $white;
            }
            &.disabled {
                background-color: $error-300;
                span {
                    color: $white;
                }
                i {
                    color: $white;
                }
            }
            &:hover:not(.disabled) {
                background-color: $error-600;
            }
            &:active:not(.disabled) {
                background-color: $error-600;
                span {
                    color: $error-200;
                }
                i {
                    color: $error-200;
                }
            }
        }
    }

    &.variant-secondary {
        &.primary {
            background-color: $white;
            span {
                color: $neutral-900;
            }
            i {
                color: $neutral-900;
            }
            &.disabled {
                background-color: $neutral-50;
                span {
                    color: $neutral-300;
                }
                i {
                    color: $neutral-300;
                }
            }
            &:hover:not(.disabled) {
                background-color: $neutral-100;
                span {
                    color: $neutral-600;
                }
                i {
                    color: $neutral-600;
                }
            }
            &:active:not(.disabled) {
                background-color: $neutral-100;

                span {
                    color: $neutral-500;
                }
                i {
                    color: $neutral-500;
                }
            }
        }
        &.lilas {
            background-color: $lilas-50;
            span {
                color: $lilas-900;
            }
            i {
                color: $lilas-900;
            }
            &.disabled {
                background-color: $lilas-50;
                span {
                    color: $lilas-500;
                }
                i {
                    color: $lilas-500;
                }
            }
            &:hover:not(.disabled) {
                background-color: $lilas-100;
                span {
                    color: $lilas-800;
                }
                i {
                    color: $lilas-800;
                }
            }
            &:active:not(.disabled) {
                background-color: $lilas-100;
                span {
                    color: $lilas-700;
                }
                i {
                    color: $lilas-700;
                }
            }
        }
        &.success {
            background-color: $success-50;
            span {
                color: $success-500;
            }
            i {
                color: $success-500;
            }
            &.disabled {
                background-color: $success-50;
                span {
                    color: $success-300;
                }
                i {
                    color: $success-300;
                }
            }
            &:hover:not(.disabled) {
                background-color: $success-100;

                span {
                    color: $success-600;
                }
                i {
                    color: $success-600;
                }
            }
            &:active:not(.disabled) {
                background-color: $success-100;

                span {
                    color: $success-500;
                }
                i {
                    color: $success-500;
                }
            }
        }
        &.warning {
            background-color: $warning-50;
            span {
                color: $warning-500;
            }
            i {
                color: $warning-500;
            }
            &.disabled {
                background-color: $warning-50;
                span {
                    color: $warning-300;
                }
                i {
                    color: $warning-300;
                }
            }
            &:hover:not(.disabled) {
                background-color: $warning-100;

                span {
                    color: $warning-600;
                }
                i {
                    color: $warning-600;
                }
            }
            &:active:not(.disabled) {
                background-color: $warning-100;

                span {
                    color: $warning-500;
                }
                i {
                    color: $warning-500;
                }
            }
        }
        &.error {
            background-color: $error-50;
            span {
                color: $error-500;
            }
            i {
                color: $error-500;
            }
            &.disabled {
                background-color: $error-50;
                span {
                    color: $error-300;
                }
                i {
                    color: $error-300;
                }
            }
            &:hover:not(.disabled) {
                background-color: $error-100;
                span {
                    color: $error-600;
                }
                i {
                    color: $error-600;
                }
            }
            &:active:not(.disabled) {
                background-color: $error-100;

                span {
                    color: $error-500;
                }
                i {
                    color: $error-500;
                }
            }
        }
    }

    &.variant-tertiary {
        &.primary {
            background-color: $neutral-200;
            span {
                color: $neutral-900;
            }
            i {
                color: $neutral-900;
            }
            &.disabled {
                background-color: $neutral-200;
                span {
                    color: $neutral-400;
                }
                i {
                    color: $neutral-400;
                }
            }
            &:hover:not(.disabled) {
                background-color: $neutral-300;
                span {
                    color: $neutral-700;
                }
                i {
                    color: $neutral-700;
                }
            }

            &:active:not(.disabled) {
                background-color: $neutral-300;
                span {
                    color: $neutral-500;
                }
                i {
                    color: $neutral-500;
                }
            }
        }
        &.lilas {
            border: $lilas-500 solid 1px;
            background-color: transparent;
            span {
                color: $lilas-900;
            }
            i {
                color: $lilas-900;
            }
            &.disabled {
                background-color: transparent;
                border: 1px solid $lilas-200;

                span {
                    color: $lilas-500;
                }
                i {
                    color: $lilas-500;
                }
            }
            &:hover:not(.disabled) {
                background-color: $lilas-300;
                border: $lilas-300 solid 1px;

                span {
                    color: $lilas-800;
                }
                i {
                    color: $lilas-800;
                }
            }

            &:active:not(.disabled) {
                background-color: $lilas-300;
                border: $lilas-300 solid 1px;
                span {
                    color: $lilas-700;
                }
                i {
                    color: $lilas-700;
                }
            }
        }
        &.success {
            border: $success-200 solid 1px;
            background-color: transparent;
            span {
                color: $success-500;
            }
            i {
                color: $success-500;
            }
            &.disabled {
                background-color: transparent;
                border: 1px solid $lilas-200;

                span {
                    color: $lilas-500;
                }
                i {
                    color: $lilas-500;
                }
            }
            &:hover:not(.disabled) {
                background-color: $success-50;
                border: $success-300 solid 1px;

                span {
                    color: $success-500;
                }
                i {
                    color: $success-500;
                }
            }

            &:active:not(.disabled) {
                background-color: $success-50;
                border: $success-300 solid 1px;
                span {
                    color: $success-400;
                }
                i {
                    color: $success-400;
                }
            }
        }
        &.warning {
            border: $warning-200 solid 1px;
            background-color: transparent;
            span {
                color: $warning-500;
            }
            i {
                color: $warning-500;
            }
            &.disabled {
                background-color: transparent;
                border: 1px solid $warning-200;

                span {
                    color: $warning-300;
                }
                i {
                    color: $warning-300;
                }
            }
            &:hover:not(.disabled) {
                background-color: $warning-50;
                border: $warning-300 solid 1px;

                span {
                    color: $warning-500;
                }
                i {
                    color: $warning-500;
                }
            }

            &:active:not(.disabled) {
                background-color: $warning-50;
                border: $warning-300 solid 1px;
                span {
                    color: $warning-400;
                }
                i {
                    color: $warning-400;
                }
            }
        }
        &.error {
            border: $error-200 solid 1px;
            background-color: transparent;
            span {
                color: $error-500;
            }
            i {
                color: $error-500;
            }
            &.disabled {
                background-color: transparent;
                border: 1px solid $error-200;

                span {
                    color: $error-300;
                }
                i {
                    color: $error-300;
                }
            }
            &:hover:not(.disabled) {
                background-color: $error-50;
                border: $error-300 solid 1px;

                span {
                    color: $error-500;
                }
                i {
                    color: $error-500;
                }
            }

            &:active:not(.disabled) {
                background-color: $error-50;
                border: $error-300 solid 1px;
                span {
                    color: $error-400;
                }
                i {
                    color: $error-400;
                }
            }
        }
    }

    &.variant-outline {
        &.primary {
            background-color: transparent;
            border: 1px solid $neutral-900;
            i {
                color: $neutral-900;
            }
            span {
                color: $neutral-900;
            }
            &.disabled {
                background-color: transparent;
                border: 1px solid $neutral-300;

                span {
                    color: $neutral-300;
                }
                i {
                    color: $neutral-300;
                }
            }
            &:hover:not(.disabled) {
                border: 1px solid $neutral-400;
                span {
                    color: $neutral-600;
                }
                i {
                    color: $neutral-600;
                }
            }
            &:active:not(.disabled) {
                border: 1px solid $neutral-400;
                span {
                    color: $neutral-400;
                }
                i {
                    color: $neutral-400;
                }
            }
        }
        &.lilas {
            background-color: transparent;
            border: 1px solid $lilas-900;
            i {
                color: $lilas-900;
            }
            span {
                color: $lilas-900;
            }
            &.disabled {
                background-color: transparent;
                border: 1px solid $lilas-500;

                span {
                    color: $lilas-500;
                }
                i {
                    color: $lilas-500;
                }
            }
            &:hover:not(.disabled) {
                border: 1px solid $lilas-800;
                span {
                    color: $lilas-800;
                }
                i {
                    color: $lilas-800;
                }
            }
            &:active:not(.disabled) {
                border: 1px solid $lilas-700;
                span {
                    color: $lilas-700;
                }
                i {
                    color: $lilas-700;
                }
            }
        }
        &.success {
            background-color: transparent;
            border: 1px solid $success-500;
            i {
                color: $success-500;
            }
            span {
                color: $success-500;
            }
            &.disabled {
                background-color: transparent;
                border: 1px solid $success-300;

                span {
                    color: $success-300;
                }
                i {
                    color: $success-300;
                }
            }
            &:hover:not(.disabled) {
                border: 1px solid $success-400;
                span {
                    color: $success-600;
                }
                i {
                    color: $success-600;
                }
            }
            &:active:not(.disabled) {
                border: 1px solid $success-500;
                span {
                    color: $success-400;
                }
                i {
                    color: $success-400;
                }
            }
        }
        &.warning {
            background-color: transparent;
            border: 1px solid $warning-500;
            i {
                color: $warning-500;
            }
            span {
                color: $warning-500;
            }
            &.disabled {
                background-color: transparent;
                border: 1px solid $warning-300;

                span {
                    color: $warning-300;
                }
                i {
                    color: $warning-300;
                }
            }
            &:hover:not(.disabled) {
                border: 1px solid $warning-400;
                span {
                    color: $warning-600;
                }
                i {
                    color: $warning-600;
                }
            }
            &:active:not(.disabled) {
                border: 1px solid $warning-400;
                span {
                    color: $warning-400;
                }
                i {
                    color: $warning-400;
                }
            }
        }
        &.error {
            background-color: transparent;
            border: 1px solid $error-500;
            i {
                color: $error-500;
            }
            span {
                color: $error-500;
            }
            &:hover:not(.disabled) {
                border: 1px solid $error-400;
                span {
                    color: $error-600;
                }
                i {
                    color: $error-600;
                }
            }
            &.disabled {
                background-color: transparent;
                border: 1px solid $warning-300;
                span {
                    color: $warning-300;
                }
                i {
                    color: $warning-300;
                }
            }
            &:active:not(.disabled) {
                border: 1px solid $error-400;
                span {
                    color: $error-400;
                }
                i {
                    color: $error-400;
                }
            }
        }
    }

    &.variant-ghost {
        background-color: transparent;
        &.primary {
            i {
                color: $neutral-900;
            }
            span {
                color: $neutral-900;
            }
            &.disabled {
                i {
                    color: $neutral-300;
                }
                span {
                    color: $neutral-300;
                }
            }
            &:hover:not(.disabled) {
                span {
                    color: $neutral-600;
                }
                i {
                    color: $neutral-600;
                }
            }
            &:active:not(.disabled) {
                span {
                    color: $neutral-400;
                }
                i {
                    color: $neutral-400;
                }
            }
        }
        &.lilas {
            i {
                color: $lilas-900;
            }
            span {
                color: $lilas-900;
            }
            &.disabled {
                i {
                    color: $lilas-500;
                }
                span {
                    color: $lilas-500;
                }
            }
            &:hover:not(.disabled) {
                span {
                    color: $lilas-800;
                }
                i {
                    color: $lilas-800;
                }
            }
            &:active:not(.disabled) {
                span {
                    color: $lilas-700;
                }
                i {
                    color: $lilas-700;
                }
            }
        }
        &.success {
            i {
                color: $success-500;
            }
            span {
                color: $success-500;
            }
            &.disabled {
                i {
                    color: $success-300;
                }
                span {
                    color: $success-300;
                }
            }
            &:hover:not(.disabled) {
                span {
                    color: $success-600;
                }
                i {
                    color: $success-600;
                }
            }
            &:active:not(.disabled) {
                span {
                    color: $success-400;
                }
                i {
                    color: $success-400;
                }
            }
        }
        &.warning {
            i {
                color: $warning-500;
            }
            span {
                color: $warning-500;
            }
            &.disabled {
                i {
                    color: $warning-300;
                }
                span {
                    color: $warning-300;
                }
            }
            &:hover:not(.disabled) {
                span {
                    color: $warning-600;
                }
                i {
                    color: $warning-600;
                }
            }
            &:active:not(.disabled) {
                span {
                    color: $warning-400;
                }
                i {
                    color: $warning-400;
                }
            }
        }
        &.error {
            i {
                color: $error-500;
            }
            span {
                color: $error-500;
            }
            &.disabled {
                i {
                    color: $error-300;
                }
                span {
                    color: $error-300;
                }
            }
            &:hover:not(.disabled) {
                span {
                    color: $error-600;
                }
                i {
                    color: $error-600;
                }
            }
            &:active:not(.disabled) {
                span {
                    color: $error-400;
                }
                i {
                    color: $error-400;
                }
            }
        }
    }
}
