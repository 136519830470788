@import "src/shared/styles/mixins.scss";

.two-factor-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .step {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .app-store-img-container {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .app-store-img {
            width: 100px;
            height: 30px;
        }
    }

    .qr-code-img {
        height: 180px;
        width: 180px;
    }

    .modal-content {
        width: 480px;
    }
    .success-modal-tfa {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 16px;
    }
}
