@import "./mixins.scss";

$lg-text: 1.125rem;
$md-text: 1rem;
$sm-text: 0.875rem;
$xs-text: 0.75rem;

.heading-large {
    font-size: 3.25rem;
    margin-bottom: 0;
}

.heading-small {
    font-size: 2.75rem;
    margin-bottom: 0;
}

h1 {
    font-size: 2.5rem;
    margin: 0;
}

h2 {
    font-size: 2.25rem;
    margin: 0;
}

h3 {
    font-size: 2rem;
    margin: 0;
}

h4 {
    font-size: 1.75rem;
    margin: 0;
}

h5 {
    font-size: 1.5rem;
    margin: 0;
}

h6 {
    font-size: 1.25rem;
    margin: 0;
}

p {
    &.lg {
        font-size: $lg-text;
        margin-bottom: 0;
    }
    &.md {
        font-size: $md-text;
        margin-bottom: 0;
    }
    &.sm {
        font-size: $sm-text;
        margin-bottom: 0;
    }
    &.xs {
        font-size: $xs-text;
        margin-bottom: 0;
    }
}

span {
    &.lg {
        font-size: $lg-text;
        margin-bottom: 0;
    }
    &.md {
        font-size: $md-text;
        margin-bottom: 0;
    }
    &.sm {
        font-size: $sm-text;
        margin-bottom: 0;
    }
    &.xs {
        font-size: $xs-text;
        margin-bottom: 0;
    }
}
