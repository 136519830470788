@import "src/shared/styles/colors.scss";

.scrollable-list-container {
    max-height: calc(100% - 52px);
    overflow-y: auto;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &.primary {
        li {
            background-color: $neutral-50;
            margin-bottom: 10px;
            border-radius: 0.625rem;
            border: 2px solid transparent;
            transition: 0.2s;
            padding: 15px;
            &.active {
                border: 2px solid #ff8157;
                transition: 0.2s;
            }
            &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }

    &.secondary {
        li {
            background-color: $white;
            margin-bottom: 10px;
            border-radius: 0.625rem;
            border: 2px solid transparent;
            transition: 0.2s;
            padding: 15px;
            &.active {
                border: 2px solid #ff8157;
                transition: 0.2s;
            }
            &.disabled {
                opacity: 0.5;
            }
        }
    }

    .button-see-more {
        margin-top: 16px;
        width: 100%;
        .button-container {
            width: 100%;
        }
    }
}

.table-placeholder {
    animation: glow 0.7s ease-in-out infinite alternate;
}
