@import "src/shared/styles/colors.scss";

.step-accordion-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .accordion-header {
        &:not(.hide) {
            border-bottom: 1px solid $neutral-200;
        }
    }

    .icon-md {
        font-size: 20px;
    }
    .subtitle {
        font-weight: initial;
    }

    .accordion .header.disabled {
        opacity: 0.5;
    }

    .accordion-content-closed {
        background-color: white !important;
        border-radius: 0 0 8px 8px;
        padding: 16px;
    }

    .accordion-content {
        .content-padding {
            padding: 16px !important;
        }
    }
}
