@import "src/shared/styles/colors.scss";

.number-input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;

    .input-container {
        height: 40px !important;
        width: 40px !important;
        text-align: center;
    }

    .buttons-container {
        display: flex;
        flex-direction: row;

        .button {
            width: 40px;
            height: 40px;
            background-color: $neutral-100;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s;
            cursor: pointer;

            &:first-of-type {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-right: 1px solid $neutral-200;
            }
            &:last-of-type {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &:hover {
                background-color: $neutral-200;
            }
        }
    }
}
