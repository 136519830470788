@import "src/shared/styles/colors.scss";

.arc-gauge-container {
    position: relative;

    .message-container {
        position: absolute;
        text-align: center;
        bottom: 5px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
}
