@import "src/shared/styles/colors.scss";
@import "src/shared/styles/general.scss";

.tasks-panel {
    height: 100%;
    overflow: none;

    .filters-select {
        width: 340px;

        .footer-content {
            display: flex;
            gap: 12px;
            width: 100%;

            .footer-btn {
                flex: 1 1 0;
            }
        }
    }

    .tasks-table-list-container {
        overflow: scroll;
        min-height: calc(100% - 50px);
    }

    .tasks-table-list {
        display: flex;
        gap: 16px;

        .tasks-table-column {
            .table-column-status {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $white;
                padding: 16px 18px;
                border-radius: 10px;
                margin-bottom: 1rem;
                width: 300px;
                box-sizing: border-box;
                font-weight: bold;

                &.status-to-validate {
                    background: $neutral-300;
                }

                &.status-pending {
                    background: $warning-100;
                }

                &.status-to-retry {
                    background: $error-100;
                }

                &.status-sending {
                    background: $success-100;
                }

                &.status-complete {
                    background: $success-100;
                }
            }

            .complete-task-link {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background: $lilas-50;
                color: $lilas-900;
                cursor: pointer;
            }

            .empty-task-card {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background: rgba($neutral-500, 0.1);
            }

            .task-table-card {
                background: $white;
                box-shadow: $shadow-md;
                transition: 0.2s all;
                width: 300px;
                box-sizing: border-box;
                padding: 1rem;
                margin-bottom: 1rem;
                border-radius: 0.625rem;
                position: relative;

                .task-card-content {
                    height: 100%;
                    display: flex;
                    transition: 0.1s all;

                    &:before {
                        content: "";
                        border-radius: 10px;
                        max-height: 100%;
                        width: 0.3rem;
                        margin-right: 1rem;

                        display: block;
                        background: $neutral-100;
                    }

                    &.status-to-validate {
                        &:before {
                            background: $neutral-100;
                        }
                    }

                    &.status-pending {
                        &:before {
                            background: $warning-500;
                        }
                    }

                    &.status-to-retry {
                        &:before {
                            background: $error-500;
                        }
                    }

                    &.status-complete {
                        &:before {
                            background: $success-500;
                        }
                    }

                    .task-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0.625rem;
                    }

                    .task-card-body {
                        height: 3.75rem;
                        width: 250px;
                        margin-top: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 16px;
                    }
                }

                &:hover {
                    box-shadow: none;
                    background-color: $white !important;

                    .task-card-content {
                        filter: blur(3px);
                    }

                    .task-card-overlay {
                        .task-card-overlay-btn {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }

                .task-card-overlay {
                    position: absolute;
                    z-index: 1;
                    padding: 3rem;
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .task-card-overlay-btn {
                        display: flex;
                        align-items: center;
                        border-radius: 0.625rem;
                        cursor: pointer;

                        box-shadow: $shadow-md;
                        padding: 0.9rem 1rem;

                        background: $white;
                        border: 1px solid $neutral-200;

                        font-size: 0.85rem;
                        font-weight: bold;

                        opacity: 0;
                        transform: translateY(5px);
                        transition: 0.5s all;

                        span,
                        i {
                            color: $neutral-500;
                        }
                    }
                }
            }
        }
    }
}

.filter-task-container {
    display: flex;
    align-items: center;

    .filters-select {
        width: 340px;

        .chip-container {
            display: flex;
            gap: 8px;
        }

        .body-content {
            gap: 15px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .footer-content {
            display: flex;
            gap: 12px;
            width: 100%;

            .footer-btn {
                flex: 1 1 0;
            }
        }

        .select-container {
            .select {
                .header {
                    background-color: $neutral-100;
                }
            }
        }
    }

    .input-container {
        .input {
            background-color: $white;
        }
    }
}
