@import "src/shared/styles/colors.scss";

.companies-container {
    .companies-list-container {
        padding: 0;
        li {
            height: 100%;
            background: $white;
        }

        .icon-briefcase {
            width: 16px;
            height: 16px;
        }

        .disabled {
            opacity: 0.5;
        }

        .user-list-nb-users {
            font-weight: bold;
            font-size: 20px !important;
        }
    }

    .add-company-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px dashed $lilas-700;
        padding: 10px;
        cursor: pointer;

        i {
            height: 34px;
            width: 34px;
        }
    }

    .action-items-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .checkbox-text-container {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .tooltip-right {
                width: 300px;
                font-size: 14px;
            }
        }
    }
}
