@import "src/shared/styles/colors.scss";

.bulk-transfert-panel-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .bulk-transfert-info-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .bulk-transfert-file-form-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .upload-text-part {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        .generic-file-uploader {
            margin: 0 !important;
        }

        .upload-file-container {
            padding: 16px;
            border: 1px solid $lilas-700;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
                border-color: $lilas-500;
                transition: 0.2s;
            }

            .upload-file-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 12px;

                .download-sentence {
                    display: flex;
                    flex-direction: row;
                }
            }
        }

        .subscription-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
        }
    }

    .divider {
        height: 1px;
        background-color: $neutral-200;
    }

    .sms-generator-variables-container {
        display: flex;
        flex-direction: row;
        gap: 4px;

        .sms-generator-variables {
            cursor: pointer;
            background-color: $neutral-300;
            border-radius: 5px;
            padding: 4px;
            &:hover {
                background-color: $neutral-200;
            }
        }
    }

    .error-message {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
    }
}
