@import "src/shared/styles/colors.scss";

.add-beneficiary-select-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 600px;
    justify-content: center;
    align-self: center;
    margin: 30px auto;

    @media screen and (max-height: 750px) {
        margin: auto;
    }

    .titles {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .buttons-container {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .button {
            width: 100%;
            background-color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 8px;
            cursor: pointer;
            padding: 20px;
            border-radius: 10px;
        }
    }
}

.add-beneficiary-bottom-actions {
    width: calc(100vw - 280px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background-color: $white;
    bottom: 25px;
    border-radius: 10px;
    padding: 8px;
    padding-left: 12px;

    .buttons {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
}

.add-beneficiary-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
}

.beneficiaries-empty-table {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    margin-top: -55px;

    img {
        width: 200px;
    }
}
