@import "src/shared/styles/colors.scss";

.activity-log-container {
    &.blur{
        li{
            filter: blur(0.2rem) !important;
            user-select: none !important;
        }
    }

    overflow: hidden;
    height: 100%;

    .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 16px;

        .select-filter {
            width: 290px;
            padding-left: 8px;

            .select-filter-content {
                display: flex;
                flex-direction: column;
                gap: 14px;
            }
        }

        .input-container .input {
            background-color: $white !important;
        }
    }

    .empty-container {
        display: flex;
        height: calc(100% - 56px);
        align-items: center;
        justify-content: center;
    }

    .footer-content {
        display: flex;
        gap: 16px;
        width: 100%;

        .footer-btn {
            flex: 1 1 0;
        }
    }
}

.activity-log-item-container {
    display: flex;

    .item {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
    }
}
