@import "src/shared/styles/colors.scss";

.switch-container {
    vertical-align: top;
    margin: 0 0 0 0;
    user-select: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;

    &.disabled {
        pointer-events: none;
        opacity: 0.2;
    }

    input {
        display: none;
    }

    .subtitle {
        font-weight: initial;
    }

    .button {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 1rem;
        line-height: 20px;
        text-transform: uppercase;
        color: $white;
        background-color: #d2d2d2;
        border-radius: 20px;

        width: 40px;
        height: 21px;
        cursor: pointer;
        transition: all 0.3 ease;

        .icon {
            margin-bottom: 2px;
        }
    }

    .button::before {
        position: absolute;
        top: 2px;
        left: 2px;
        content: "";
        display: inline-block;
        height: 17px;
        width: 17px;
        background: $white;
        border-radius: 100%;
        transition: all 0.3s ease;
    }

    input:checked + .button {
        background: $lilas-900;
    }

    input:checked + .button::before {
        content: "";
        left: 21px;
        background-color: $white;
    }
}
