@import "src/shared/styles/colors.scss";

.disposal-proof-helper-drawer-container {
    padding: 16px;

    .disposal-proof-drawer-divider {
        margin: 16px -16px 16px -16px;
        height: 2px;
        background-color: $neutral-100;
    }

    .disposal-proof-drawer-image {
        padding-top: 16px;
        object-fit: contain;
        width: 100%;
        height: 407px;
        max-height: 407px;
    }
}
