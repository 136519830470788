@import "src/shared/styles/colors.scss";

.disposal-info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .disposal-list-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: bold;
    }

    .service-img {
        height: 20px;
        width: 20px;
        border-radius: 3px;
        margin-left: 8px;
    }

    .input-container {
        .input {
            background-color: $neutral-100;
        }
    }
}
