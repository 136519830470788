@import "src/shared/styles/colors.scss";

.wallet-selector-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    background-color: $white;
    border-radius: 10px;
    padding: 16px;
    cursor: pointer;

    .left-part {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        width: 100%;

        .wallet-info {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            height: 100%;

            .wallet-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                .dot {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: $lilas-500;
                }

                .reference-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    i {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .skeleton {
        width: 120px;
    }

    .right-part {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        width: 100%;

        .wallet-selector-container-divider {
            width: 2px;
            height: 100%;
            background-color: $neutral-100;
        }
    }
}

.wallet-selector-card-list {
    .wallet-selector-list-item-container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .list-item-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title-container {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 4px;
                }
            }
        }

        .amount-container {
            display: flex;
            align-items: flex-end;
        }
    }
}
