@import "src/shared/styles/colors.scss";

.quick-actions-container {
    .quick-actions-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .header {
            padding: 8px 0;
        }
        .content {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .action {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 16px 8px;
                cursor: pointer;
                border-radius: 10px;
                flex: 1 0 0;
                background-color: $white;
                text-align: center;
                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }

            .action-loading {
                height: 70px;
                flex: 1 0 0;
                background-color: $white;
                border-radius: 10px;
                cursor: wait;
                animation-duration: 1.5s;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: placeholder-glow;
                animation-timing-function: linear;

                &:nth-child(1) {
                    animation-delay: 0.1s;
                }
                &:nth-child(2) {
                    animation-delay: 0.2s;
                }
                &:nth-child(3) {
                    animation-delay: 0.3s;
                }
                &:nth-child(4) {
                    animation-delay: 0.4s;
                }
                &:nth-child(5) {
                    animation-delay: 0.5s;
                }
            }
        }
    }
}

@keyframes placeholder-glow {
    from {
        background-color: $neutral-300;
    }
    to {
        background-color: $neutral-200;
    }
}
