.text-ellipsis {
    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 340px;
    }
}

.expense-policy-amount-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;

    .unit-of-time-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;

        .input-container {
            width: 100%;
        }
        .simple-select-container {
            width: 100%;
            .select {
                .select-header {
                    width: calc(100% - 32px);
                }
            }
        }
    }
}

.icon-trash {
    width: 40px;
}

.expense-policy-amount-step {
    .accordion-container .accordion .accordion-header {
        height: initial;
    }
}
