@import "src/shared/styles/colors.scss";

.admin-wallet-form-container {
    .signataires-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }

    .signature-sent {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        margin-top: 16px;
    }
    .accordion-container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .accordion-header {
            background-color: $white !important;
        }

        .accordion-content {
            background-color: $white;
        }

        .form-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .first-part {
            .button-continue-container {
                border-top: 1px solid $neutral-200;
                margin-top: 15px;
                padding-top: 15px;
                .continue-button {
                    width: 150px;
                    margin-left: auto;
                }
            }
        }

        .second-part {
            .switch-container {
                span {
                    color: $neutral-500;
                }
            }

            .signature-header {
                display: flex;
                flex-direction: row;
                gap: 16px;
                align-items: center;
                .title {
                    display: flex;
                    flex-direction: row;
                    gap: 4px;
                    align-items: center;
                }
                .tooltip {
                    width: 420px;
                }
                .buttons-container {
                    display: flex;
                    gap: 10px;
                    flex-direction: row;

                    .user-research-select-container {
                        width: 250px;

                        .select-container .select .content.show {
                            max-height: 150px;
                            overflow-y: scroll;
                        }

                        .user-research-select-item {
                            border-bottom: 1px solid $neutral-100;
                            padding: 8px 4px;

                            &:hover {
                                background-color: $neutral-200;
                            }
                        }
                    }
                }
            }

            .signature-content {
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                border-left: 2px dashed #5856c6;
                padding-left: 30px;
                margin-left: 20px;
                margin-bottom: 16px;

                .signataire-content-list {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 16px;
                    animation: fadeInRight 0.2s;

                    .signataire-container {
                        background-color: $neutral-100;
                        width: 100%;
                        padding-left: 8px;
                    }
                    .icon-trash-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $neutral-500;
                        width: 57px;
                        height: 57px;
                        background-color: $neutral-100;
                        border-radius: 10px;
                        cursor: pointer;
                        transition: 0.2s;

                        &:hover {
                            background-color: $neutral-200;
                            transition: 0.2s;
                        }
                    }
                }

                .signature-placeholder {
                    height: 100px;
                    width: 100%;
                    background-color: $neutral-100;
                    margin-top: 10px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    .signature-placeholder-content {
                        display: flex;
                        flex-direction: column;
                        color: $neutral-900;
                    }
                }
            }

            .validation-section {
                width: 100%;
                height: 60px;
                border-top: 1px solid $neutral-100;

                .validation-button {
                    width: 250px;
                    margin-left: auto;
                    margin-top: 15px;
                }
            }
        }
    }
}
