@import "src/shared/styles/colors.scss";

.icon-button {
    display: flex;
    border: none;
    align-items: center;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 7px;
    padding: 6px;
    cursor: pointer;
    transition: 0.2s;

    &.color-primary {
        background-color: $neutral-50;

        &:hover {
            background-color: $neutral-200;
        }
    }

    &.color-red {
        background-color: $error-50;

        &:hover {
            background-color: $error-200;
        }
    }

    &.disabled {
        &:hover {
            background-color: transparent !important;
        }
    }

    &.color-grey {
        background-color: $neutral-50;

        &:hover {
            background-color: $neutral-200;
        }
    }

    &.color-grey-dark {
        background-color: $neutral-200;

        &:hover {
            background-color: $neutral-400;
        }
    }

    &:disabled {
        cursor: not-allowed;
    }
}
