@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/colors.scss";

@keyframes alarm-animation {
    from {
        transform: translate(-50%, -50%) scale(0.1);
        opacity: 1;
    }
    to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}

.connected-layout {
    display: flex;
    flex-direction: row;
    background-color: $neutral-100;
    overflow: hidden;

    .navbar-container {
        display: flex;
        flex-direction: column;
        width: 245px;
        height: 100%;
        position: fixed;
        overflow-y: auto;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 245px);
        margin-left: 245px;

        .header-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $neutral-100;
            height: 62px;
            width: 100%;

            .header-buttons {
                display: flex;
                flex-direction: row;
                margin-right: 17px; // scrollbar width
                gap: 8px;

                &.firefox {
                    margin-right: 30px;
                }

                .notification-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    width: 40px;
                    height: 40px;
                    background: $white;
                    border-radius: 10px;
                    transition: 0.2s;

                    &:hover {
                        cursor: pointer;
                        background: $neutral-50;
                        transition: 0.2s;
                    }

                    .new-badge {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        height: 1rem;
                        width: 1rem;
                        border-radius: 50%;
                        background-color: $error;
                        transform: translate(30%, 30%);

                        &:before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background: rgba($error, 0.4);
                            height: 3.75rem;
                            width: 3.75rem;
                            border-radius: 50%;
                            opacity: 0;
                            animation: 1.5s linear 0s 2 normal alarm-animation;
                        }
                    }
                }
            }
        }

        .router-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 62px); // 100vh - header breadcrumb height

            overflow: scroll;

            &.demo-warning {
                height: calc(100vh - 110px); // 100vh - header breadcrumb height - alert height
            }

            &.firefox {
                width: calc(100vw - 245px - 17px); // 100vw - navbar menu - scrollbar width
                padding-right: 17px;
            }
        }
    }
}

.download-app-modal-content {
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    padding: 16px;

    .title {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
    }
}

.modal-inactivity-content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;
    align-items: center;
}
