@keyframes fadeInUp {
    0% {
        transform: translate(0px, 100px);
        opacity: 0;
    }
    100% {
        transform: translate(0px, 0);
        opacity: 1;
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

.fadeOutUp {
    animation-name: fadeInUp;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

.fadeInRight {
    animation-name: fadeInRight;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.fadeOutRight {
    animation-name: fadeOutRight;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.fadeInLeft {
    animation-name: fadeInLeft;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.fadeOutLeft {
    animation-name: fadeOutLeft;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.fadeInDown {
    animation-name: fadeInDown;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.fadeOutDown {
    animation-name: fadeOutDown;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

