@import "src/shared/styles/colors.scss";

.users-details-drawer-identity {
    .content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .status {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;

            & > * {
                flex: 1;
                text-align: left;
            }
        }
    }

    .button-part {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .button-container {
            width: 100%;
        }

        .edit-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }
    }
}

.users-details-drawer-phone {
    .drawer-content-gap {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .drawer-content-phone {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > * {
            flex: 1;
            text-align: left;
        }
    }
    .button-part {
        display: flex;
        justify-content: end;
        gap: 8px;
    }
}

.users-details-drawer-personal-info {
    .drawer-content-personal-info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .personal-info-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            & > * {
                flex: 1;
                text-align: left;
            }
        }
    }
    .button-part {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .button-container {
            width: 100%;
        }
    }
}

.users-details-drawer-role {
    .roles-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .button-part {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;
        .button-container {
            width: 100%;
        }
    }
}

.users-details-drawer-archive-user {
    .content-section {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .button-part {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;

        .button-container {
            width: 100%;
        }
    }

    .disabled {
        color: $error-200;
        cursor: not-allowed;
    }
}
