@import "./fonts/fontface.scss";
@import "./fonts/icons.scss";

.cursor-pointer {
    cursor: pointer;
}

.italic {
    font-style: italic;
}
.underline {
    text-decoration: underline;
}
.line-through {
    text-decoration: line-through;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.full-divider {
    width: calc(100% + 32px);
    height: 2px;
    background: $neutral-100;
    margin-left: -16px;
}

.border-box {
    box-sizing: border-box;
}

.divider {
    width: 100%;
    height: 2px;
    background: $neutral-100;
}

.file-input-display-none {
    display: none;
}

.dot-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    .dot {
        width: 5px;
        height: 5px;
        border-radius: 10px;
        background-color: $lilas-500;
    }
}

.tooltip-limited {
    display: flex;
    flex-direction: column;
}

a {
    color: $black;
    text-decoration: none;

    &:hover {
        color: $black;
    }
}

.fw-bold {
    font-weight: bold !important;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-lighter {
    font-weight: lighter !important;
}

@mixin media-breakpoint-up($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @warn "Invalid breakpoint name: #{$breakpoint}.";
    }
}

$breakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

// Définition des classes d-flex
.d-flex {
    display: flex !important;
}

// Définition des classes d-none
.d-none {
    display: none !important;
}

// Définition des classes flex-direction
.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

// Définition des classes align-items
.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

// Définition des classes justify-content
.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

// Définition des classes place-items
.place-items-auto {
    place-items: auto !important;
}

.place-items-start {
    place-items: start !important;
}

.place-items-end {
    place-items: end !important;
}

.place-items-center {
    place-items: center !important;
}

.place-items-stretch {
    place-items: stretch !important;
}
// Définition des classes align-self
.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

.white-space-nowrap {
    white-space: nowrap;
}

// Définition des classes responsives
$breakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

// Boucle for imbriquée pour générer des classes responsives
@each $breakpoint, $size in $breakpoints {
    // Classes d-flex responsives
    .d-flex-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            display: flex !important;
        }
    }

    // Classes flex-direction responsives
    .flex-row-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            flex-direction: row !important;
        }
    }

    .flex-column-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            flex-direction: column !important;
        }
    }

    // Classes align-items responsives
    .align-items-start-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-items: flex-start !important;
        }
    }

    .align-items-end-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-items: flex-end !important;
        }
    }

    .align-items-center-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-items: center !important;
        }
    }

    .align-items-baseline-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-items: baseline !important;
        }
    }

    .align-items-stretch-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-items: stretch !important;
        }
    }

    // Classes justify-content responsives
    .justify-content-start-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            justify-content: flex-start !important;
        }
    }

    .justify-content-end-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            justify-content: flex-end !important;
        }
    }

    .justify-content-center-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            justify-content: center !important;
        }
    }

    .justify-content-between-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            justify-content: space-between !important;
        }
    }

    .justify-content-around-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            justify-content: space-around !important;
        }
    }

    // Classes place-items responsives
    .place-items-auto-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            place-items: auto !important;
        }
    }

    .place-items-start-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            place-items: start !important;
        }
    }

    .place-items-end-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            place-items: end !important;
        }
    }

    .place-items-center-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            place-items: center !important;
        }
    }

    .place-items-stretch-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            place-items: stretch !important;
        }
    }
    // Classes align-self responsives
    .align-self-auto-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-self: auto !important;
        }
    }

    .align-self-start-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-self: flex-start !important;
        }
    }

    .align-self-end-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-self: flex-end !important;
        }
    }

    .align-self-center-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-self: center !important;
        }
    }

    .align-self-baseline-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-self: baseline !important;
        }
    }

    .align-self-stretch-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
            align-self: stretch !important;
        }
    }
}

.w-100 {
    width: 100% !important;
}

.w-75 {
    width: 75% !important;
}

.w-50 {
    width: 50% !important;
}

.w-25 {
    width: 25% !important;
}

.h-100 {
    height: 100% !important;
}

.h-75 {
    height: 75% !important;
}

.h-50 {
    height: 50% !important;
}

.h-25 {
    height: 25% !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.overflow-x-visible {
    overflow-x: visible !important;
}

.overflow-x-scroll {
    overflow-x: scroll !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

.overflow-y-visible {
    overflow-y: visible !important;
}

.overflow-y-scroll {
    overflow-y: scroll !important;
}

.img-fluid {
    max-width: 100%;
    height: auto !important;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto !important;
}

/* Padding */
.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}
.p-2 {
    padding: 0.5rem !important;
}
.p-3 {
    padding: 1rem !important;
}
.p-4 {
    padding: 1.5rem !important;
}
.p-5 {
    padding: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}
.pt-1 {
    padding-top: 0.25rem !important;
}
.pt-2 {
    padding-top: 0.5rem !important;
}
.pt-3 {
    padding-top: 1rem !important;
}
.pt-4 {
    padding-top: 1.5rem !important;
}
.pt-5 {
    padding-top: 3rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}
.pb-2 {
    padding-bottom: 0.5rem !important;
}
.pb-3 {
    padding-bottom: 1rem !important;
}
.pb-4 {
    padding-bottom: 1.5rem !important;
}
.pb-5 {
    padding-bottom: 3rem !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}
.pl-2 {
    padding-left: 0.5rem !important;
}
.pl-3 {
    padding-left: 1rem !important;
}
.pl-4 {
    padding-left: 1.5rem !important;
}
.pl-5 {
    padding-left: 3rem !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}
.pr-2 {
    padding-right: 0.5rem !important;
}
.pr-3 {
    padding-right: 1rem !important;
}
.pr-4 {
    padding-right: 1.5rem !important;
}
.pr-5 {
    padding-right: 3rem !important;
}

.px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}

.px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}
.px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
.px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.px-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

/* Margin */
.mt-0 {
    margin-top: 0 !important;
}
.mt-1 {
    margin-top: 0.25rem !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.mt-4 {
    margin-top: 1.5rem !important;
}
.mt-5 {
    margin-top: 3rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}
.mb-2 {
    margin-bottom: 0.5rem !important;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.mb-5 {
    margin-bottom: 3rem !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}
.ml-2 {
    margin-left: 0.5rem !important;
}
.ml-3 {
    margin-left: 1rem !important;
}
.ml-4 {
    margin-left: 1.5rem !important;
}
.ml-5 {
    margin-left: 3rem !important;
}
.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}
.mr-2 {
    margin-right: 0.5rem !important;
}
.mr-3 {
    margin-right: 1rem !important;
}
.mr-4 {
    margin-right: 1.5rem !important;
}
.mr-5 {
    margin-right: 3rem !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}
.mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}
.mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}
.mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}
.mx-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}
.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.px-auto {
    padding-left: auto !important;
    padding-right: auto !important;
}

.py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
}

.gap-1 {
    gap: 0.25rem !important;
}
.gap-2 {
    gap: 0.5rem !important;
}
.gap-3 {
    gap: 1rem !important;
}
.gap-4 {
    gap: 1.5rem !important;
}
.gap-5 {
    gap: 3rem !important;
}

.rounded {
    border-radius: 8px;
}

.rounded-sm {
    border-radius: 4px;
}

.rounded-lg {
    border-radius: 16px;
}

.rounded-xl {
    border-radius: 24px;
}

.drawer-content-section {
    background-color: $white;
    border-radius: 10px;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &:not(:has(.drawer-content-section-body)) > .drawer-content-section-header {
        border-bottom: 0;
    }

    .drawer-content-section-header {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $neutral-200;

        > .disabled {
            pointer-events: none;
        }
    }

    .drawer-content-section-body {
        padding: 16px;
    }

    .drawer-content-section-footer {
        border-top: 1px solid $neutral-200;
        padding: 16px;
    }

    .accordion .accordion-header {
        background-color: $neutral-100;
    }

    .accordion .accordion-header.show {
        background-color: $neutral-100;
    }

    .accordion .accordion-content {
        background-color: $neutral-100;
    }

    .accordion .accordion-content.show {
        background-color: $neutral-100;
    }

    .select-container .select .select-header {
        background-color: $neutral-100;
    }

    .select-container .select .select-header.show {
        background-color: $neutral-100;
    }

    .input-container .input {
        background-color: $neutral-100;
    }

    .input-phone-container .input {
        background-color: $neutral-100;
    }

    .text-area {
        background-color: $neutral-100;
    }
}

.relative {
    position: relative;
}

.blocked {
    filter: blur(5px);
    user-select: none;
}

.Toastify__toast {
    font-size: 14px;
}

$spacing: 1rem !default;

$spacings-map: (
    "none": 0,
    "xxx-sm": $spacing * 0.125,
    "xx-sm": $spacing * 0.25,
    "x-sm": $spacing * 0.5,
    "sm": $spacing * 0.75,
    "md": $spacing * 1,
    "big": $spacing * 1.25,
    "x-big": $spacing * 1.5,
    "xx-big": $spacing * 1.75,
    "xxx-big": $spacing * 2,
    "lg": $spacing * 2.5,
    "x-lg": $spacing * 3,
    "xx-lg": $spacing * 4,
    "xxx-lg": $spacing * 5,
    "huge": $spacing * 6,
    "x-huge": $spacing * 8,
    "xx-huge": $spacing * 10,
);

$shadow-black: $black;
$shadow-xs: 0px 4px 5px rgba($black, 0.03);
$shadow-sm: 0px 4px 10px rgba($black, 0.04);
$shadow-md: 0px 4px 20px rgba($black, 0.05);
$shadow-lg: 0px 8px 30px rgba($black, 0.06);
$shadow-xl: 0px 12px 40px rgba($black, 0.07);
$shadow-xxl: 0px 16px 50px rgba($black, 0.08);
