@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.history-wallet-drawer-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: bold;

    .history-wallet-drawer-status {
        .status-data-value {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
        }
    }

    .history-wallet-drawer-cancel {
        .drawer-cancel-button-section {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        .cancel-drawer-open {
            .alert-content {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .drawer-cancel-list {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin: 16px 0;

                .drawer-cancel-item {
                    display: flex;
                    flex-direction: row;

                    span {
                        width: 50%;

                        &:first-of-type {
                            font-weight: bold;
                            color: $neutral-500;
                        }

                        &:last-of-type {
                            font-weight: bold;
                        }

                        .color-error {
                            color: $error-500;
                        }
                    }
                }
            }

            .drawer-cancel-motive {
                border-top: 1px solid $neutral-100;
                padding-top: 16px;
                margin-top: 8px;
                span {
                    color: $neutral-900;
                    font-weight: bold;
                }

                .select-container {
                    .select {
                        .select-header {
                            width: calc(100% - 32px);
                            background-color: $neutral-100;
                        }
                    }
                }
            }
        }
    }

    .history-wallet-drawer-retry {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: #fdedec;
        border: 1px solid #f8a8a5;
        padding: 16px 8px;
        border-radius: 10px;

        .retry-content {
            display: flex;
            flex-direction: row;
            gap: 16px;
            margin-left: 8px;
            .content {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
        }
        .button-section {
            display: flex;
            flex-direction: row;
            gap: 8px;
            margin-left: 8px;
        }
    }

    .history-wallet-drawer-comments {
        .comment-button {
            color: $neutral-500;
            font-weight: bold;
            cursor: pointer;
        }

        textarea {
            width: calc(100% - 22px);
        }

        .drawer-comment-content {
            display: flex;
            flex-direction: row;

            span {
                &:first-of-type {
                    width: 25%;
                }
                width: 75%;
            }
        }

        .button-section {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .button-container {
                width: 100%;
            }
        }
    }

    .history-wallet-drawer-details {
        .history-wallet-drawer-details-container {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .history-wallet-drawer-details-item {
                display: flex;
                flex-direction: row;
                align-items: center;

                & > * {
                    flex: 1;
                    text-align: left;
                }

                .transaction-item {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                }
            }
        }
    }

    .history-wallet-drawer-signatures {
        .signature-list-container {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .signature-item-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border: 1px solid $neutral-100;
                border-radius: 10px;
                padding: 16px;

                .signature-number-squared {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $neutral-100;
                    border-radius: 10px;
                    color: $neutral-500;
                    font-weight: bold;
                }

                .signature-content {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
            }
        }
    }

    .history-wallet-drawer-recovery-list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .history-wallet-drawer-recovery-item {
            display: flex;
            flex-direction: row;
            background-color: $neutral-100;
            border-radius: 10px;
            padding: 8px;

            .item-title {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 30%;

                .item-title-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 4px;
                }
            }

            .item-content {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
        }
    }
}

.top-up-dashed-line-container {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;

    .dashed-line {
        width: 1.5px;
        height: 40px;
        border-left: 1.5px dashed $neutral-500;
    }
}

.top-up-status-step-container {
    display: flex;
    align-items: center;
    gap: 16px;

    .top-up-status-step {
        display: flex;
        flex-direction: column;
    }
}

.drawer-top-up-notification-container {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .drawer-top-up-notification {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}
