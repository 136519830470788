@import "src/shared/styles/colors.scss";

.profile-page-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 16px;

    .tabs-container {
        position: absolute;
        display: flex;
        background-color: #f5f5f5;
        width: calc(100vw - 256px); // maxwidth - left navbar width
        padding-bottom: 16px;
        z-index: 1;
    }

    .user-settings-content-container {
        margin-top: calc(50px + 16px);
    }

    .form-container {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-radius: 10px;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border-bottom: 2px solid $neutral-100;

            .left-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                width: 100%;

                .text-part {
                    display: flex;
                    flex-direction: column;
                }
            }

            .right-content {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }
        }

        .body {
            padding: 16px;
        }

        .footer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 12px;
            padding: 16px;
            border-top: 2px solid $neutral-100;
        }
    }

    .edit-modal-content {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
    }
}
