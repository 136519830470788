@import "src/shared/styles/colors.scss";

.top-billers-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .skeleton {
        width: 273.75px;
        height: 280px;
        border-radius: 10px;
        animation: glow 0.7s ease-in-out infinite alternate;
    }

    @for $i from 0 through 20 {
        .skeleton-delay-#{$i} {
            width: #{$i * 100}ms !important;
        }
    }

    .top-billers-filters {
        display: flex;
        flex-direction: row;
        align-items: center;

        .select-filter {
            width: 290px;
            padding-left: 8px;

            .select-filter-content {
                display: flex;
                flex-direction: column;
                gap: 14px;

                .select-filter-button {
                    width: auto;
                }
            }
        }

        .input-container .input {
            background-color: $white !important;
        }
    }

    .top-biller-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .footer-content {
        display: flex;
        gap: 16px;
        width: 100%;

        .footer-btn {
            flex: 1 1 0;
        }
    }

    .favorite-billers-empty {
        background-color: $neutral-200;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
    }

    .top-biller-sector-carousel-item {
        display: flex;
        width: 120px;
        max-width: 120px;
        height: 104px;
        max-height: 104px;
        padding: 16px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #ffffff;
        gap: 16px;
        text-align: center;
        cursor: pointer;
    }

    .top-biller-item {
        display: flex;
        width: 273.75px;
        max-width: 273.75px;
        height: 280px;
        max-height: 280px;
        flex-direction: column;
        border-radius: 10px;
        background-color: #ffffff;
        cursor: pointer;

        &.error {
            width: 100%;
        }

        .top-biller-description {
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 4px;

            .top-biller-informations {
                display: flex;
                gap: 8px;

                .informations {
                    color: $neutral-900;
                    position: relative;
                    display: inline-block;
                    word-wrap: break-word;
                    overflow: hidden;
                    max-height: 4.8em;
                    line-height: 1.2em;
                }
            }
        }

        .top-biller-image {
            min-height: 50%;
            border-radius: 10px 10px 0 0;
            position: relative;
            background-repeat: no-repeat;

            .top-biller-center-sector {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }
            .top-biller-sector {
                position: absolute;
                top: 16px;
                left: 16px;
                border-radius: 8px;
                padding: 4px;
                display: flex;
                align-items: center;
                gap: 8px;
                background-color: #ffffff;
            }
            .top-biller-favorite-button {
                position: absolute;
                top: 16px;
                right: 16px;
                border-radius: 8px;
                background-color: #ffffff;
            }

            .top-biller-avatar {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px 10px 0 0;
            }
        }
    }
}
