@import "src/shared/styles/colors.scss";

.pay-bill-container {
    .pay-bill-filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 16px;

        .select-filter {
            width: 290px;
            padding-left: 8px;

            .select-filter-content {
                display: flex;
                flex-direction: column;
                gap: 14px;

                .select-simple-custom {
                    width: 100%;
                    box-sizing: border-box;
                }

                .select-filter-button {
                    width: auto;
                }
            }
        }

        .input-container .input {
            background-color: $white !important;
        }
    }
    .footer-content {
        display: flex;
        gap: 16px;
        width: 100%;

        .footer-btn {
            flex: 1 1 0;
        }
    }

    .biller-table-item-container {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        padding: 8px;

        .nowrap {
            text-wrap: nowrap;
        }

        .biller-table-item {
            display: flex;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            width: 75%;
            gap: 8px;

            .avatar {
                min-width: 48px;
                min-height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $lilas-50;
                border-radius: 8px;
            }
        }

        .biller-table-item-description {
            display: flex;
            align-items: flex-start !important;
            flex-direction: column;
        }

        .reference-name-container {
            display: flex;
            gap: 8px;
            padding-bottom: 8px;

            .dot-container {
                display: flex;
                align-items: center;
                justify-content: center;
                .dot {
                    width: 5px;
                    height: 5px;
                    border-radius: 10px;
                    background-color: $lilas-500;
                }
            }
        }

        .type-country-container {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-bottom: 8px;

            .flex {
                display: flex;
                gap: 8px;
                align-items: center;

                .tag {
                    padding: 4px 8px;
                    background-color: $lilas-50;
                    border-radius: 6px;
                }
            }
        }

        .description-container {
            display: flex;
            text-align: left;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            max-height: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            gap: 8px;
        }

        .image {
            min-width: 48px;
            width: 48px;
            min-height: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            padding: 8px;
            border-radius: 10px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .empty-part {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: auto;
        width: 550px;

        .empty-state-container {
            text-align: center;
            font-weight: bold;
            white-space: pre-line;
        }

        .copyable-text {
            background-color: $white;
        }
    }
}
