@import "src/shared/styles/colors.scss";

.expense-policy-parameters {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

    .select-container {
        .select {
            .select-header {
                background-color: white !important;
            }
        }
    }

    .input {
        background-color: white !important;
    }
}

.expense-policy-beneficiary {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    gap: 16px;

    .switch-container {
        display: flex;
        gap: 16px;
    }

    .alert {
        margin-top: 16px;
    }
}
