@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/general.scss";

.navbar-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;

    .accordion-container {
        .content {
            margin-bottom: 10px;

            .list-container {
                display: flex;
                flex-direction: column;
                padding-left: 15px;
                border-left: 2px $lilas-500 solid;
                width: 170px;

                .menu-button {
                    border-radius: 0.625rem;
                    height: 40px;
                    padding: 0 12px;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                    justify-content: flex-start;
                    transition: 0.2s;

                    i {
                        color: $neutral-900;
                        width: 20px;
                        height: 20px;
                    }

                    span {
                        color: $neutral-900;
                        white-space: nowrap;
                    }

                    &:hover {
                        background-color: $neutral-200;
                        transition: 0.2s;
                    }

                    &:active {
                        background-color: $neutral-100;
                        transition: 0.2s;
                    }

                    &.active {
                        background: $gradiant-pink-horizontal-1;
                        transition: 0.2s;
                    }
                }
            }
        }

        .accordion {
            overflow: hidden;
            width: 221px;

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                padding: 0 15px;
                height: 48px;
                color: $neutral-900;
                font-weight: bold;
                cursor: pointer;
                background-color: $white;

                &.show {
                    background-color: $white;
                    transition-delay: 0s;
                }

                &.show {
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                }

                &.hide {
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    transition-delay: 0.5s;
                }

                &.disabled {
                    filter: grayscale(1);
                    cursor: not-allowed;
                }

                i {
                    transition: 0.5s;
                    &.show {
                        transform: rotate(180deg);
                    }
                }

                .header-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    .accordion-badge {
                        padding: 2px 4px;
                        background-color: $error-500;
                        border-radius: 8px;
                        display: flex;

                        span {
                            font-style: italic;
                            font-weight: bold;
                            color: $white;
                            font-size: 12px;
                        }
                    }
                }
            }

            .content {
                background-color: $white;
                overflow: hidden;
                box-sizing: border-box;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                max-height: 0;
                transition: 0.7s ease-in-out;

                &.show {
                    max-height: 100vh;
                    transition: 0.7s ease-in-out;
                }

                .content-padding {
                    padding: 0 16px 16px 23px;
                }
            }
        }
    }
}

.navigation-menu-container {
    position: relative;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;

    .navigation-menu-button {
        display: flex;
        width: 221px;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        cursor: pointer;
        transition: 0.2s;

        span {
            font-size: 14px !important;
        }

        .chevron-container {
            padding-right: 13px;
            .chevron-down-open {
                transition: 0.5s;

                &.show {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .navigation-menu {
        background-color: $neutral-50;
        transition: 0.2s ease-in-out;
        position: absolute;
        display: none;
        border-radius: 10px;
        width: 221px;
        z-index: 1000;
        box-shadow: $shadow-lg;

        &.show {
            display: block;
            transition: 0.2s ease-in-out;
        }

        .content-header {
            .user-role-header {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $lilas-50;
                color: $lilas-900;
                border-radius: 10px 10px 0px 0px;
                height: 36px;
                margin-bottom: 8px;
            }

            span {
                font-size: 14px !important;
            }
        }

        .menu-divider {
            border: 1px solid $neutral-100;
        }

        .content-menu {
            padding: 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .navbar-button-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px;
                gap: 8px;
                border-radius: 10px;
                cursor: pointer;

                &.active {
                    background: $gradiant-pink-horizontal-1;
                }

                &.disabled {
                    opacity: 0.5;
                    cursor: forbid;
                }

                &:hover {
                    background-color: $neutral-100;
                }
            }

            .menu-button {
                width: 100%;
                justify-content: flex-start;

                i {
                    color: $black;
                }
                span {
                    font-size: 14px !important;
                }

                &:hover {
                    background-color: $neutral-200;
                }

                &.active {
                    background-color: $neutral-500;
                    i {
                        color: $white;
                    }
                    span {
                        color: $white;
                    }
                }

                &.disconnect-button {
                    &:hover {
                        background-color: $error-100;
                    }
                }

                &:last-of-type {
                    margin-bottom: 16px;
                    border: 0;

                    i,
                    span {
                        color: $error-500;
                    }
                }
            }
        }
    }

    .navbar-user-container {
        width: 100%;
        display: flex;
        overflow: hidden;
        flex-direction: row;
        gap: 8px;

        .navbar-user-text {
            display: flex;
            flex-direction: column;
            align-self: center;
            max-width: 140px;

            span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    @keyframes fadeInDown {
        from {
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInUp {
        animation-name: fadeInUp;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
}

.julaya-pro-image {
    width: 100px;
    padding-bottom: 116px;
    padding-left: 24px;
    margin-top: auto;
}
