@import "src/shared/styles/colors.scss";

.beneficiary-drawer-body-container {
    .beneficiary-drawer-searchbar-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 16px;

        .input-container {
            width: 100%;
        }

        .input {
            background-color: $white;
        }
    }

    .beneficiaries-categories-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .beneficiaries-list-container {
            display: flex;
            flex-direction: column;
            gap: 8px;

            li {
                background-color: $white;
            }

            .beneficiaries-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: transparent;
                transition: 0.2s;
                animation-duration: 0.5s;
                animation-delay: 0.1s;

                .left-content {
                    display: flex;
                    flex-direction: row;

                    .list-item-title {
                        display: flex;
                        flex-direction: column;
                        margin: auto;
                        margin-left: 8px;
                    }
                }

                .right-content {
                    display: flex;
                    flex-direction: row;

                    .list-item-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-right: 8px;

                        .list-item-content-right {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 8px;

                            .list-item-flag {
                                width: 15px;
                            }
                        }
                    }
                }

                &.disabled {
                    color: $neutral-400;
                    cursor: not-allowed;
                    .transaction-service-img {
                        filter: grayscale(0.87);
                    }
                }
            }
        }
    }
}
