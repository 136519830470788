@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.signataire-container {
    height: 57px;
    border-radius: 10px;
    background-color: $neutral-100;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;

    .signataire-content-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        margin-left: 5px;
        align-items: center;

        @include breakpoint(sm) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        .signataire-name {
            display: flex;
            flex-direction: column;
        }

        .super-signataire {
            margin-left: auto;
            margin-right: 10px;

            @include breakpoint(sm) {
                margin-left: 0;
                margin-right: 5px;
            }
        }
    }
}
