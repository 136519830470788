@import "src/shared/styles/colors.scss";

.rechargement-drawer-alert {
    margin-bottom: 16px;
}

.rechargement-helper-drawer-button {
    cursor: pointer;
    text-decoration: underline;
    color: $neutral-500;
    width: fit-content;
}

.no-text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-white-space-pre {
    white-space: pre;
}
