@import "src/shared/styles/colors.scss";
@import "src/shared/styles/typography.scss";

.selectable-table-container {
    border-spacing: 0;
    border-radius: 10px;
    margin-bottom: 73px;
    width: 100%;

    thead {
        tr {
            background-color: $neutral-50;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        th {
            padding: 16px;
            border: 1px solid $neutral-100;
            text-align: start;

            &:first-child {
                border-top-left-radius: 10px;
            }

            &:last-child {
                border-top-right-radius: 10px;
            }

            .header-item-container {
                display: flex;
                flex-direction: column;

                &.center {
                    align-items: center;
                }

                &.left {
                    align-items: flex-start;
                }

                &.right {
                    align-items: flex-end;
                }

                .header-subtitle {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 4px;
                    color: $neutral-500;
                }
            }
        }
    }

    tbody {
        tr {
            background-color: $white;
            transition: 0.2s;

            &.selected {
                background-color: $neutral-200;
                transition: 0.2s;
            }

            &:last-child {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                td {
                    &:first-child {
                        border-bottom-left-radius: 10px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 10px;
                    }

                    &.selectable-row-td {
                        width: 20px;
                    }
                }
            }
        }

        td {
            border: 1px solid $neutral-100;
            padding: 6px 16px;
            transition: 0.2s;

            &.text {
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100px;
            }

            .td-content-container {
                display: flex;
                flex-direction: row;
                gap: 4px;
                min-height: 14px;

                .cell-row-item {
                    color: $neutral-900;

                    &.text {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .tooltip {
                    white-space: wrap;
                    min-width: 250px;
                }

                .tooltip-container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .selectable-table-status-tooltip {
                    min-width: 400px;
                    padding: 0;
                }

                .status {
                    width: 100%;
                }
            }

            &.image,
            &.button {
                width: 23px;
                .td-content-container {
                    justify-content: center;
                }
            }

            &.status {
                width: 47px;
            }

            &.success {
                background-color: $success-50;
                border: 1px solid $success-500;
            }

            &.warning {
                background-color: $warning-50;
                border: 1px solid $warning-500;
            }

            &.error {
                background-color: $error-50;
                border: 1px solid $error-500;
            }
        }
    }

    .center-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .checkbox-container input[type="checkbox"] {
        margin-right: 0;
    }

    .hover-effect-row {
        &:hover {
            background-color: $neutral-100;
            transition: 0.2s;
        }
    }

    .hover-effect-cell {
        &:hover {
            background-color: $neutral-100;
            transition: 0.2s;
        }
    }

    .on-click-cell {
        cursor: pointer;
    }
}
