@import "src/shared/styles/colors.scss";

.toggler {
    display: inline-block;
    background: $white;

    &.disabled {
        opacity: 0.7;
        pointer-events: none;
    }
    &.dark {
        background: $white;
    }

    &.small {
        .toggler-item {
            min-width: 7rem;
        }
    }
}

.toggler-wrapper {
    background-color: $white;
    border-radius: 10px;
    padding: 4px;
    transition: 0.2s;

    &.disabled {
        transition: 0.2s;
        opacity: 0.7;
    }

    .toggler-inner {
        position: relative;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &:before {
            content: "";
            z-index: 0;
            position: absolute;
            background: $neutral-900;
            color: $white;
            border-radius: 10px;
            width: 50%;
            height: 100%;
            left: 0rem;
            top: 0rem;
            transition: 0.3s all;
        }

        &.active {
            &:before {
                left: 50%;
            }
            .toggler-item {
                &:first-child {
                    i {
                        color: $neutral-900;
                    }
                    span {
                        color: $neutral-900;
                    }
                }
                &:last-child {
                    color: $white;
                }
            }
        }

        .toggler-item {
            flex: 1;
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            white-space: nowrap;
            height: 34px;
            width: 100%;
            vertical-align: middle;
            cursor: pointer;
            user-select: none;
            background-color: transparent;
            padding: 0 10px;
            z-index: 1;
            min-width: 100px;
            justify-content: center;

            &.small {
                font-size: 80%;
            }

            &:first-child {
                transition: 0.3s all;
                color: $white;
            }
            &:last-child {
                transition: 0.3s all;
            }
        }
    }
}
