@import "src/shared/styles/colors.scss";
@import "src/shared/styles/typography.scss";

.table-container {
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;
    padding-bottom: 58px;

    th {
        text-align: left;
        color: $neutral-600;
        &:last-child {
            text-align: right;
            padding-right: 8px;
        }
    }

    tbody {
        tr {
            height: 58px;
            background-color: $white;
            border-radius: 10px;

            td:first-child {
                padding-left: 8px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            td:last-child {
                padding-right: 8px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                text-align: right;

                div,
                p {
                    justify-content: flex-end;
                }
            }

            &.table-placeholder {
                animation: glow 0.7s ease-in-out infinite alternate;
            }

            @keyframes glow {
                from {
                    background-color: $white;
                }
                to {
                    background-color: $neutral-200;
                }
            }
        }
    }

    &.clickable {
        tbody {
            tr {
                cursor: pointer;
                transition: 0.2s;
                &:hover {
                    background-color: $neutral-200;
                    transition: 0.2s;
                }
            }
        }
    }
}

@for $i from 0 through 19 {
    .skeleton-delay-#{$i} {
        animation-delay: #{$i * 100}ms !important;
    }
}
