.admin-wallet-table-container {
    display: flex;
    flex-direction: column;

    .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .create-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2px;
            cursor: pointer;
        }
    }

    .avatar-button-container {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .avatar-button {
            button {
                width: 40px !important;
            }
        }
    }

    .body-content {
        button {
            width: 100%;
        }
    }

    .selectable-table-container {
        margin-bottom: 87px;
    }

    .centered-cell {
        display: flex;
        justify-content: center;
    }

    .tooltip-container .tooltip {
        width: 100%;
    }

    .beneficiary-only-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        i {
            cursor: pointer;
        }

        .tooltip {
            width: 230px;
            left: -110px;
        }
    }
}
