@import "src/shared/styles/colors.scss";

.document-item {
    padding: 10px;
    border: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $neutral-100;
    border-radius: 10px;
}

.user-drawer-roles-form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .radio-container {
        background-color: $neutral-100;
        padding: 16px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 8px 0;

        .radio-roles-select {
            display: flex;
            flex-direction: column;
            gap: 4px;

            &.disabled {
                color: $neutral-500;
                cursor: not-allowed;
            }
        }
    }

    .switch-container {
        span {
            font-weight: bold;
        }
    }

    .user-roles-title-switch {
        .subtitle {
            color: $neutral-500;
            margin-left: 56px;
        }
    }

    .select-container {
        .select {
            .select-header {
                width: calc(100% - 32px);
            }
        }
    }
    .input {
        background: $white !important;
        width: calc(100% - 24px);
    }

    .collector-role-form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .access-text {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .wave-text {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .avatar {
                flex-shrink: 0;
            }
        }
    }
}
