@import "src/shared/styles/colors.scss";

.bank-select-drawer {
    .service-img {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 0.625rem;
        margin-right: 1rem;
    }

    .bank-select-drawer-body {
        .input {
            background-color: $white;
        }
        .services-list {
            .align-text-center {
                text-align: center;
            }

            .services-list-item {
                border: 1px solid transparent;
                border-radius: 0.625rem;
                background-color: transparent;
                display: flex;
                align-items: center;

                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                .default-image {
                    margin-right: 1rem;
                }

                &:hover {
                    cursor: pointer;
                }

                .time-item {
                    background-color: transparent;
                    color: $neutral-900;
                    border: 1px solid $neutral-200;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    padding: 4px;

                    &.neutral {
                        border-color: $neutral-200;
                    }

                    &.success {
                        border-color: $success;
                    }

                    &.warning {
                        border-color: $warning;
                    }
                }
            }
        }
    }
}
