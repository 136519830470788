@import "src/shared/styles/colors.scss";

.admin-document-panel-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .loading-placeholder {
        display: flex;
        margin-top: 18px;
        flex-direction: column;
        gap: 16px;

        .placeholder {
            width: 100%;
            height: 72px;
            border-radius: 10px;
            animation: glow 0.7s ease-in-out infinite alternate;

            &:nth-child(1) {
                animation-delay: 0.1s;
            }
            &:nth-child(2) {
                animation-delay: 0.2s;
            }
            &:nth-child(3) {
                animation-delay: 0.6s;
            }
        }
    }

    .admin-documents-item-avatar {
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        font-size: 24px;
    }

    .kyb-indicator-container {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-radius: 10px;

        .kyb-indicator-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px;
            gap: 8px;
            border-bottom: 2px solid $neutral-100;
        }

        .kyb-indicator-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            padding: 16px;

            .going-further-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                border-radius: 10px;

                &.to-complete {
                    border: 2px solid $pink-500;
                    background-color: $pink-50;

                    .image-part {
                        background-color: $pink-500;
                    }

                    .kyb-indicator-divider {
                        width: 2px;
                        height: 80%;
                        align-self: center;
                        background-color: $pink-500;
                    }
                }

                &.approved {
                    border: 2px solid $blue-500;
                    background-color: $blue-50;

                    .image-part {
                        background-color: $blue-500;
                    }

                    .kyb-indicator-divider {
                        width: 2px;
                        height: 80%;
                        align-self: center;
                        background-color: $blue-500;
                    }
                }

                &.certified {
                    border: 2px solid $lilas-500;
                    background-color: $lilas-50;

                    .image-part {
                        background-color: $lilas-500;
                    }

                    .kyb-indicator-divider {
                        width: 2px;
                        height: 80%;
                        align-self: center;
                        background-color: $lilas-500;
                    }
                }

                .image-part {
                    padding: 16px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 100px;
                        height: 100px;
                    }
                }

                .first-part {
                    width: 30%;
                    padding: 16px;

                    .text-part {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        height: 100%;
                        justify-content: center;

                        .link {
                            text-decoration: underline;
                            color: $lilas-900;
                            cursor: pointer;
                        }
                    }
                }

                .second-part {
                    padding: 16px;
                    width: 30%;

                    .text-part {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        height: 100%;
                        justify-content: center;

                        .advantages-list {
                            display: flex;
                            gap: 8px;
                            flex-direction: column;

                            .advantage-item {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 8px;
                            }
                        }
                    }
                }

                .third-part {
                    padding: 16px;
                    width: 30%;
                    display: flex;
                    justify-content: center;
                }
            }

            .accordion-container {
                width: 100%;
            }

            .type-of-folders-container {
                display: flex;
                flex-direction: column;
                padding-top: 16px;
                gap: 16px;

                .tooltip-sentence {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 4px;

                    .tooltip {
                        width: 325px;
                        margin-top: 35px;
                    }
                }

                .advantages-table {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;

                    .button-container {
                        width: 100%;
                    }

                    .advantages-list {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        text-align: center;
                        width: 100%;
                        gap: 16px;
                        padding-bottom: 16px;
                    }

                    .advantages-table-item {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        border-radius: 10px;
                        padding: 16px;
                        gap: 16px;
                        background-color: $blue-50;
                        border: 2px solid $blue-500;

                        &:last-child {
                            background-color: $lilas-50;
                            border: 2px solid $lilas-500;
                            .item {
                                background-color: $lilas-200;
                            }
                        }
                    }

                    .item-list {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        &.blue {
                            .item {
                                background-color: $blue-200;
                            }
                        }

                        &.lilas {
                            .item {
                                background-color: $lilas-200;
                            }
                        }

                        .item {
                            display: flex;
                            height: 44px;
                            border-radius: 10px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            &.row {
                                flex-direction: row;
                                justify-content: start;
                                gap: 8px;
                                padding: 0 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .admin-documents-item-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .admin-documents-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-bottom: 14px;
            border-bottom: 2px solid $neutral-100;

            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }

            .document-file-uploader {
                display: flex;
                flex-direction: column;
                margin-top: 8px;
            }

            .preview-img {
                max-width: 100%;
                height: auto;
                display: block;
                object-fit: cover;
            }
            .iframe-preview {
                width: 100%;
                height: 100%;
            }
        }

        .item-container {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .item-header {
                display: flex;
                flex-direction: column;
            }
            .item-content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .left-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 12px;
                    font-weight: bold;
                }

                .right-content {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;

                    .icon-button {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }

        .alert-document-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            margin: 4px 0 8px 0;
            border-radius: 10px;
            background-color: $lilas-50;
            border: 1px solid $lilas-200;

            .first-part {
                display: flex;
                flex-direction: row;
                gap: 16px;

                span {
                    margin: auto;
                }
            }
        }
    }

    .admin-documents-file {
        display: flex;
        align-items: center;
        margin: 8px 0;
    }
}
