@import "src/shared/styles/colors.scss";

.analytics-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .filter-container {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .select-header {
                background-color: white;
            }

            .select-container {
                min-width: 300px;
            }

            .header-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
            }

            .body-content {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .select-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8px;
                    border-radius: 10px;
                    gap: 8px;

                    &:hover {
                        transition: 0.2s;
                        background-color: $neutral-100;
                    }

                    &.selected {
                        background-color: $neutral-100;
                    }
                }

                .custom-radio {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    padding: 8px;
                }
            }
        }
    }

    .custom-date-picker {
        min-width: 300px;
    }

    .transactions-report-container {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .wallet-transactions-pie-chart-container {
            width: 30%;
        }

        .wallet-transactions-bar-chart-container {
            width: 70%;
        }

        @media screen and (max-width: 1150px) {
            flex-direction: column;

            .wallet-transactions-pie-chart-container {
                width: 100%;
            }

            .wallet-transactions-bar-chart-container {
                width: 100%;
            }
        }
    }
}
