.rechargement-helper-drawer-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;

    .helper-description-section {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
    }
}
