@import "src/shared/styles/colors.scss";

.top-up-drawer-body-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .top-up-drawer-body-section {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-radius: 10px;
        background: $white;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
        &.active {
            cursor: pointer;
        }

        .top-up-drawer-deadline {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;
        }

        .top-up-drawer-body-chip {
            display: flex;
            height: 28px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            background: $neutral-900;
            color: $white;
        }
    }
    li {
        background-color: #fafafa;
        margin-bottom: 16px !important;
        border: none !important;
        transition: 0.2s;
        padding: 0 !important;
        cursor: pointer;
    }
}
