$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

@mixin breakpoint($point) {
    @if $point == lg {
        @media (max-width: $lg) {
            @content;
        }
    } @else if $point == md {
        @media (max-width: $md) {
            @content;
        }
    } @else if $point == sm {
        @media (max-width: $sm) {
            @content;
        }
    } @else if $point == xs {
        @media (max-width: $xs) {
            @content;
        }
    }
}
