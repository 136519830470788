@import "src/shared/styles/colors.scss";

.wallet-information-drawer-archive-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    border: 1px solid $error-500;

    .header {
        height: 40px;
        padding: 16px;
        font-weight: bold;
        align-content: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 2px solid $neutral-100;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;

        .select-header {
            width: calc(100% - 32px);
        }

        .select-content {
            margin-top: calc(-100% + 60px);
        }
    }
    .footer {
        border-top: 2px solid $neutral-100;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;
        padding: 16px;
    }
}
