@import "src/shared/styles/colors.scss";

.range-slider-container {
    .range-slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 3px;
        background: transparent;
        outline: none;
        cursor: pointer;
        position: relative;

        &:hover {
            opacity: 1;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: calc(50% - 5px);
            height: 3px;
            background-color: $lilas-900;
            border-radius: 5px;
        }

        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: calc(50% - 5px);
            height: 3px;
            background-color: $white;
            border-radius: 5px;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 20px;
            height: 20px;
            background: $lilas-900;
            border-radius: 50%;
            cursor: pointer;
            margin-top: -8px;
            z-index: 2;
            position: relative;
        }

        &::-moz-range-thumb {
            width: 20px;
            height: 20px;
            background: $lilas-900;
            border-radius: 50%;
            cursor: pointer;
        }
    }
}
