@import "src/shared/styles/colors.scss";

.selectable-table-bar {
    width: calc(100% - 32px);
    background-color: $neutral-900;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-radius: 10px;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    max-height: 100px;
    opacity: 1;

    &.collapsed {
        max-height: 0;
        opacity: 0;
        padding: 0 16px;
    }

    .content {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        &.left {
            gap: 8px;
            color: $white;

            .button-container {
                width: 32px;

                i {
                    color: $neutral-900;
                }
            }
        }

        .select-header {
            font-weight: bold;
        }
        .select-content {
            min-width: 234px;
        }
    }

    .selectable-bar-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 8px;
        font-weight: bold;

        &:hover {
            cursor: pointer;
            background-color: $neutral-100;
            border-radius: 10px;
        }

        &.disabled {
            color: $neutral-300;
            cursor: not-allowed;

            &:hover {
                background-color: transparent;
            }
        }
    }
}
