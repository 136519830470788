@import "src/shared/styles/colors.scss";

.edit-task-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    margin-top: auto;
    border-radius: 10px;
    height: 50px;
    padding: 8px 16px 8px 16px;
    position: sticky;
    bottom: 0;
    overflow: visible;

    .edit-task-status-amount-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .edit-task-footer-status-amount {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
}

.edit-task-footer-item {
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
    height: 36px;
    border: 2px solid transparent;
}

.edit-task-footer-item-border {
    border: 2px dashed $lilas-700;
}

.task-edit-tooltip {
    width: 220px;
}

.task-edit-vertical-divider {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: -1rem;
    margin-left: -1rem;
    background-color: $neutral-200;
    width: calc(100% + 2rem);
    height: 1px;
}

.task-manager-notification {
    width: 100% !important;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease-in-out;
    bottom: 0rem;

    &.open {
        opacity: 1;
        visibility: visible;
        bottom: 6rem;
    }

    .task-manager-notification-content {
        display: flex;
        background-color: $white;
        border-radius: 10px;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        padding: 0.625rem;
        border: 2px solid $error-400;
    }
}

.edit-task-footer-modal-content {
    width: 450px;
    padding: 16px;
}
