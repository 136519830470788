@import "src/shared/styles/colors.scss";

.edit-task-table-cell-content {
    text-decoration: none;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90px;
}

.edit-task-table-cell-amount {
    text-decoration: none;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    max-width: 120px;
}

.edit-task-table-cell-input {
    cursor: text;
    height: 16px;
}

@keyframes rotate-and-scale-one {
    0% {
        transform: scale(1);
        background-color: $lilas-600;
    }
    25% {
        transform: scale(0.75);
        background-color: $lilas-300;
    }
    50% {
        transform: scale(0.5);
        background-color: $lilas-50;
    }
    75% {
        transform: scale(0.75);
        background-color: $lilas-300;
    }
    100% {
        transform: scale(1);
        background-color: $lilas-600;
    }
}

@keyframes rotate-and-scale-two {
    0% {
        transform: rotate(240deg) scale(0.75);
        background-color: $lilas-300;
    }
    15% {
        transform: rotate(240deg) scale(0.5);
        background-color: $lilas-50;
    }
    50% {
        transform: rotate(240deg) scale(0.75);
        background-color: $lilas-300;
    }
    63% {
        transform: rotate(240deg) scale(1);
        background-color: $lilas-600;
    }
    100% {
        transform: rotate(240deg) scale(0.75);
        background-color: $lilas-300;
    }
}

@keyframes rotate-and-scale-three {
    0% {
        transform: rotate(120deg) scale(0.75);
        background-color: $lilas-50;
    }
    33% {
        transform: rotate(120deg) scale(1);
        background-color: $lilas-600;
    }
    50% {
        transform: rotate(120deg) scale(0.75);
        background-color: $lilas-300;
    }
    75% {
        transform: rotate(120deg) scale(0.5);
        background-color: $lilas-300;
    }
    100% {
        transform: rotate(120deg) scale(0.75);
        background-color: $lilas-50;
    }
}

@keyframes slow-rotation {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.edit-task-recap-container {
    padding: 16px;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 280px;
}

.edit-task-recap-recap-section {
    position: sticky;
    top: 0;
    height: fit-content;
}

.edit-task-loading-image-container {
    margin-top: 50px;
    padding-bottom: 50px;
    height: 300px;
    width: 300px;
    display: inline-block;
    transform: rotate(20deg);
    animation: slow-rotation 16s linear infinite;

    .first-image,
    .second-image,
    .third-image {
        width: 100px;
        height: 60px;
        display: inline-block;
        border-radius: 10px;
        padding: 16px;
        transform-origin: 80px;
    }

    .first-image {
        position: relative;
        left: calc(50% - 58px);
        animation: rotate-and-scale-one 16s linear infinite;
    }

    .second-image {
        position: relative;
        top: 185px;
        left: -159px;
        animation: rotate-and-scale-two 16s linear infinite;
    }

    .third-image {
        position: relative;
        top: 97px;
        left: 187px;
        animation: rotate-and-scale-three 16s linear infinite;
    }
}

.edit-task-loading-container {
    height: 10px;
    border-radius: 8px;
    width: 80%;
    background-color: $neutral-200;
    margin-top: 16px;

    .edit-task-loading-loader {
        height: 10px;
        background-color: $lilas-900;
        border-radius: 8px;
    }
}

.task-fees-cell {
    cursor: default;
    white-space: nowrap;
}

.bulk-amount-input-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .input-cell-edit-task {
        display: flex;
        height: 40px;
        padding: 1.5px 7px;
        align-items: center;
        gap: 5px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 10px;
        background: var(--grey-default, #f6f5f5);
        border: 0;
        font-size: 14px;
    }

    .button-error {
        background-color: $error-50 !important;
        i {
            color: $black !important;
        }
    }

    .button-success {
        background-color: $success-50 !important;
        i {
            color: $black !important;
        }
    }
}

.edit-task-select-button {
    background-color: $neutral-900;
    border-radius: 10px;
    &.disabled {
        background-color: $neutral-300;
    }
}

.edit-task-row-error {
    background-color: $error-100 !important;
}

tbody {
    .alert-container {
        .content-text {
            span {
                text-align: left;
            }
        }
    }
}

.edit-task-select-container {
    white-space: nowrap;
    .select-item {
        display: flex;
        align-items: center;

        &.disabled {
            color: $neutral-500;
            cursor: not-allowed;
        }
    }
}

.edit-task-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    padding-right: 0px;
    gap: 16px;
    .edit-task-footer {
        position: sticky;
        bottom: 0;
        overflow: visible;
        margin-top: auto;
    }

    .edit-task-table {
        max-width: 60%;
    }

    .tooltip-limited {
        display: flex;
        flex-direction: column;
    }

    .tooltip {
        width: 100%;
    }
}

.row-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.task-button-vertical-divider {
    width: 2px;
    min-height: 100%;
    background-color: $white;
}

.task-button-select-button {
    border-radius: 0px 10px 10px 0px !important;
    background-color: $neutral-900 !important;
    padding: none;
    color: $white !important;
    border: none !important;
}

.task-button-select-content {
    right: -34px;
}

.task-items-error-message {
    text-align: start;
}
