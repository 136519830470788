@import "src/shared/styles/colors.scss";

.task-details-drawer {
    .task-details-body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .task-details-body-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            span {
                width: 50%;
            }
        }
    }
}

.task-details-signatures {
    .drawer-details-features-body {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .task-signatures-container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .line-container {
            display: flex;
            justify-content: center;
            width: 48px;
            margin-top: 8px;

            .purple-line {
                border-right: dashed 1.5px $lilas-900;
                height: 100%;
            }
            .warning-line {
                border-right: dashed 1.5px $warning-500;
                height: 100%;
            }
        }

        .task-signatures {
            display: flex;
            background-color: $neutral-100;
            align-items: center;
            padding: 8px;
            border-radius: 10px;
            width: calc(100% - 60px);
            margin-bottom: 8px;
            margin-top: 8px;

            .task-signature {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-right: 8px;
            }
        }
    }
}

.task-details-activity {
    .task-details-activity-body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .input-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        .comment-section {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .comment-task-container {
                display: flex;
                flex-direction: row;
                gap: 8px;

                .comment-task-infos {
                    width: 100%;

                    .title {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}

.task-details-transaction-status {
    .transaction-button-link {
        margin-top: 16px;
        button {
            width: 100%;
        }
    }
}
