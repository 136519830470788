@import "src/shared/styles/colors.scss";

.wallet-history-widget {
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    .wallet-history-widget-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .selectable-table-container {
            margin-bottom: 0;
        }
    }
}
