@import "src/shared/styles/colors.scss";

.bill-field-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    margin-bottom: 16px;

    .bill-field-header {
        display: flex;
        flex-direction: column;
        padding: 16px;
        background-color: $neutral-100;
        border-radius: 10px;
        gap: 8px;
    }
    .bill-field {
        display: flex;
        gap: 12px;
    }
}
