@import "src/shared/styles/colors.scss";

.tag-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    gap: 8px;
    width: fit-content;
    border: 1px solid transparent;

    .hoverable {
        &:hover {
            cursor: pointer;
        }
    }
    &:hover {
        &.disabled {
            cursor: not-allowed;
        }
    }

    &.size-lg {
        padding: 6px;
        i {
            font-size: 20px;
        }
    }

    &.size-md {
        padding: 4px;
        i {
            font-size: 20px;
        }
    }

    &.size-sm {
        padding: 3px;

        i {
            font-size: 16px;
        }
    }

    span {
        inline-size: max-content;
    }

    &.type-filled {
        span {
            color: $neutral-50;
            font-weight: bold;
        }

        i {
            color: white !important;
        }

        &.hoverable {
            &:hover {
                &.primary {
                    background-color: $neutral-700;
                }
                &.success {
                    background-color: $success-600;
                }
                &.error {
                    background-color: $error-600;
                }
                &.warning {
                    background-color: $warning-600;
                }
                &.neutral {
                    background-color: $neutral-700;
                }
                &.blue {
                    background-color: $blue-600;
                }
                &.lilas {
                    background-color: $lilas-600;
                }
            }
        }

        &.primary {
            background-color: $neutral-900;
            i {
                color: $neutral-400;
            }
            &.disabled {
                background-color: $neutral-300;
            }
        }
        &.success {
            background-color: $success-500;
            i {
                color: $success-400;
            }
            &.disabled {
                background-color: $success-300;
            }
        }
        &.error {
            background-color: $error-500;
            i {
                color: $error-400;
            }
            &.disabled {
                background-color: $error-300;
            }
        }
        &.warning {
            background-color: $warning-500;
            i {
                color: $warning-400;
            }
            &.disabled {
                background-color: $warning-300;
            }
        }
        &.neutral {
            background-color: $neutral-900;
            i {
                color: $neutral-400;
            }
            &.disabled {
                background-color: $neutral-300;
            }
        }
        &.blue {
            background-color: $blue-500;
            i {
                color: $blue-400;
            }
            &.disabled {
                background-color: $blue-300;
            }
        }
        &.lilas {
            background-color: $lilas-500;
            i {
                color: $lilas-400;
            }
            &.disabled {
                background-color: $lilas-300;
            }
        }
    }

    &.type-accent {
        span {
            color: $black;
            font-weight: bold;
        }
        &.hoverable {
            &:hover {
                &.primary {
                    background-color: $neutral-100;
                }
                &.success {
                    background-color: $success-100;
                }
                &.error {
                    background-color: $error-100;
                }
                &.warning {
                    background-color: $warning-100;
                }
                &.neutral {
                    background-color: $neutral-100;
                }
                &.blue {
                    background-color: $blue-100;
                }
                &.lilas {
                    background-color: $lilas-100;
                }
            }
        }

        &.primary {
            background-color: $neutral-100;
            i {
                color: $neutral-900;
            }
            &.disabled {
                background-color: $neutral-50;
            }
        }
        &.success {
            background-color: $success-50;
            i {
                color: $success-500;
            }
            &.disabled {
                background-color: $success-50;
            }
        }
        &.error {
            background-color: $error-50;
            i {
                color: $error-500;
            }
            &.disabled {
                background-color: $error-50;
            }
        }
        &.warning {
            background-color: $warning-50;
            i {
                color: $warning-500;
            }
            &.disabled {
                background-color: $warning-50;
            }
        }
        &.neutral {
            background-color: $neutral-100;
            i {
                color: $neutral-900;
            }
            &.disabled {
                background-color: $neutral-50;
            }
        }
        &.blue {
            background-color: $blue-50;
            i {
                color: $blue-900;
            }
            &.disabled {
                background-color: $blue-50;
            }
        }
        &.lilas {
            background-color: $lilas-50;
            i {
                color: $lilas-900;
            }
            &.disabled {
                background-color: $lilas-50;
            }
        }
    }

    &.type-outline {
        span {
            color: $black;
            font-weight: bold;
        }

        &.hoverable {
            &:hover {
                &.primary {
                    background-color: $neutral-100;
                }
                &.success {
                    background-color: $success-100;
                }
                &.error {
                    background-color: $error-100;
                }
                &.warning {
                    background-color: $warning-100;
                }
                &.neutral {
                    background-color: $neutral-100;
                }
                &.blue {
                    background-color: $blue-100;
                }
                &.lilas {
                    background-color: $lilas-100;
                }
            }
        }

        &.primary {
            background-color: transparent;
            border: 1px solid $neutral-200;
            i {
                color: $neutral-500;
            }

            &.disabled {
                border: 1px solid $neutral-300;
                background-color: transparent;
                i {
                    color: $neutral-300;
                }
            }
        }
        &.success {
            background-color: transparent;
            border: 1px solid $success-500;
            i {
                color: $success-500;
            }

            &.disabled {
                border: 1px solid $success-300;
                background-color: transparent;
                i {
                    color: $success-300;
                }
            }
        }
        &.error {
            background-color: transparent;
            border: 1px solid $error-500;
            i {
                color: $error-500;
            }

            &.disabled {
                border: 1px solid $error-300;
                background-color: transparent;
                i {
                    color: $error-300;
                }
            }
        }
        &.warning {
            background-color: transparent;
            border: 1px solid $warning-500;
            i {
                color: $warning-500;
            }

            &.disabled {
                border: 1px solid $warning-300;
                background-color: transparent;
                i {
                    color: $warning-300;
                }
            }
        }
        &.neutral {
            background-color: transparent;
            border: 1px solid $neutral-200;
            i {
                color: $neutral-500;
            }

            &.disabled {
                border: 1px solid $neutral-300;
                background-color: transparent;
                i {
                    color: $neutral-300;
                }
            }
        }
        &.blue {
            background-color: transparent;
            border: 1px solid $blue-500;
            i {
                color: $blue-500;
            }

            &.disabled {
                border: 1px solid $blue-300;
                background-color: transparent;
                i {
                    color: $blue-300;
                }
            }
        }
        &.lilas {
            background-color: transparent;
            border: 1px solid $lilas-500;
            i {
                color: $lilas-500;
            }

            &.disabled {
                border: 1px solid $lilas-300;
                background-color: transparent;
                i {
                    color: $lilas-300;
                }
            }
        }
    }

    &.type-ghost {
        span {
            font-weight: bold;
        }

        &.hoverable {
            &:hover {
                &.primary {
                    i {
                        color: $neutral-600;
                    }
                    span {
                        color: $neutral-600;
                    }
                }
                &.success {
                    i {
                        color: $success-600;
                    }
                    span {
                        color: $success-600;
                    }
                }
                &.error {
                    i {
                        color: $error-600;
                    }
                    span {
                        color: $error-600;
                    }
                }
                &.warning {
                    i {
                        color: $warning-600;
                    }
                    span {
                        color: $warning-600;
                    }
                }
                &.neutral {
                    i {
                        color: $neutral-600;
                    }
                    span {
                        color: $neutral-600;
                    }
                }
                &.blue {
                    i {
                        color: $blue-600;
                    }
                    span {
                        color: $blue-600;
                    }
                }
                &.lilas {
                    i {
                        color: $lilas-600;
                    }
                    span {
                        color: $lilas-600;
                    }
                }
            }
        }

        &.primary {
            i {
                color: $neutral-500;
            }
            span {
                color: $neutral-500;
            }

            &.disabled {
                i {
                    color: $neutral-300;
                }
                span {
                    color: $neutral-300;
                }
            }
        }
        &.success {
            i {
                color: $success-500;
            }
            span {
                color: $success-500;
            }

            &.disabled {
                i {
                    color: $success-300;
                }
                span {
                    color: $success-300;
                }
            }
        }
        &.error {
            i {
                color: $error-500;
            }
            span {
                color: $error-500;
            }

            &.disabled {
                i {
                    color: $error-300;
                }
                span {
                    color: $error-300;
                }
            }
        }
        &.warning {
            i {
                color: $warning-500;
            }
            span {
                color: $warning-500;
            }

            &.disabled {
                i {
                    color: $warning-300;
                }
                span {
                    color: $warning-300;
                }
            }
        }
        &.neutral {
            i {
                color: $neutral-500;
            }
            span {
                color: $neutral-500;
            }

            &.disabled {
                i {
                    color: $neutral-300;
                }
                span {
                    color: $neutral-300;
                }
            }
        }
        &.blue {
            i {
                color: $blue-500;
            }
            span {
                color: $blue-500;
            }

            &.disabled {
                i {
                    color: $blue-300;
                }
                span {
                    color: $blue-300;
                }
            }
        }
        &.lilas {
            i {
                color: $lilas-500;
            }
            span {
                color: $lilas-500;
            }

            &.disabled {
                i {
                    color: $lilas-300;
                }
                span {
                    color: $lilas-300;
                }
            }
        }
    }
}
