@import "src/shared/styles/colors.scss";
@import "src/shared/styles/general.scss";

.select-container {
    .select {
        user-select: none;
        position: relative;

        .select-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            padding: 0 15px;
            gap: 8px;

            &.variant-icon-menu {
                padding: 0 8px !important;
            }

            background-color: $white;
            height: 40px;
            color: $black;
            font-weight: 500;
            cursor: pointer;
            border-radius: 10px !important;
            transition: 0.2s;

            &.disabled {
                filter: grayscale(1);
                cursor: not-allowed;

                span {
                    color: $neutral-300;
                }

                i {
                    color: $neutral-300;
                }
            }

            &.header-color-white {
                background: $white;

                &:hover {
                    background: $neutral-50;
                }
            }

            &.header-color-grey {
                background: $neutral-100;

                &:hover {
                    background: $neutral-200;
                }
            }

            &.header-color-darkgrey {
                background: $neutral-200;

                &:hover {
                    background: $neutral-300;
                }
            }

            i {
                transition: 0.2s;
                &.show {
                    transform: rotate(180deg);
                }
            }

            &.type-primary {
                border: 1px solid transparent;
                &.show {
                    border: 1px solid $lilas-700;
                }
            }

            &.type-success {
                border: 1px solid $success-500;
                &.show {
                    border: 1px solid $success-500;
                }
            }

            &.type-warning {
                border: 1px solid $warning-500;
                &.show {
                    border: 1px solid $warning-500;
                }
            }

            &.type-error {
                border: 1px solid $error-500;
                &.show {
                    border: 1px solid $error-500;
                }
            }

            .select-header-label {
                display: flex;
                align-items: center;
                gap: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: clip;
            }
        }

        .select-content {
            background-color: $white;
            transition: 0.2s ease-in-out;
            position: absolute;
            margin-top: 5px;
            opacity: 0;
            border-radius: 10px;
            z-index: 1000;
            box-shadow: $shadow-lg;
            border: 1px solid $neutral-200;

            &.full-width {
                width: 100%;
            }
            &.fit-content {
                width: fit-content;
            }
            &.show {
                transition: 0.2s ease-in-out;
            }
            &.hide {
                display: none;
            }

            .content-header {
                padding: 0 10px;
                height: 50px;
                border-bottom: 1px solid $neutral-100;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .content-body {
                padding: 8px;

                &.overflow-auto {
                    overflow: auto;
                }
                &.bordered {
                    border-radius: 10px;
                }

                .classic-select {
                    &:hover {
                        background-color: $neutral-200;
                    }
                }
            }

            &.left {
                right: 0px;
            }

            .content-footer {
                padding: 10px;
                border-top: 1px solid $neutral-100;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &.high-render {
        .select-content {
            overflow-y: auto;
            max-height: 350px;
        }
    }

    .title-overflow {
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: hidden;
        text-wrap: nowrap;

        .color-grey {
            color: grey;
        }
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 10px, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInUp {
        animation-name: fadeInUp;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
}
