@import "src/shared/styles/colors.scss";

.transfer-drawer-body-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .transfer-drawer-body-section {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 16px;
        border-radius: 10px;
        background: $white;
        cursor: pointer;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .transfer-drawer {
            display: flex;
            flex-direction: column;
        }
    }

    li {
        background-color: #fafafa;
        margin-bottom: 16px !important;
        border: none !important;
        transition: 0.2s;
        padding: 0 !important;
        cursor: pointer;
    }
}
