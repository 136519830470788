@import "src/shared/styles/colors.scss";

.export-form-column-selection {
    display: flex;
    flex-direction: column;

    .title {
        margin-bottom: 4px;
    }
    .select-container {
        .select {
            .select-header {
                background-color: $lilas-100 !important;
            }
        }
    }

    .export-form-column-selection-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        background-color: $neutral-100;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        &:not(.show) {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        &.show {
            padding: 16px;
        }
    }

    .export-form-column-selection-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 16px;
        padding-top: 0;
        background-color: $neutral-100;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .export-colomn-item-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            background-color: $neutral-100;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .export-form-column-selection-items {
            padding: 4px 8px;
            border-radius: 10px;
            background-color: $white;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            gap: 8px;
            position: static;

            .draggable-item {
                display: flex;
                flex-direction: row;
                gap: 1px;
            }

            .index-ellipsis {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                .ellipsis-gap {
                    display: flex;
                    flex-direction: row;
                    gap: 3px;
                    color: $neutral-500;
                    i {
                        width: 3px;

                        &:before {
                            width: 3px;
                        }
                    }
                }
                span {
                    &:first-of-type {
                        margin-left: 10px;
                    }
                }
            }
        }

        .select {
            .select-header {
                span {
                    font-weight: bold;
                    color: $lilas-900;
                }

                i {
                    color: $lilas-900;
                }
            }
        }
        .select-export-form-column-selection {
            display: flex;
            flex-direction: column;

            .select-export-form-column-selection-item {
                padding: 4px 8px;
                border-radius: 10px;
                transition: 0.2s;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                &:hover {
                    background-color: $neutral-100;
                    transition: 0.2s;
                    cursor: pointer;
                }
            }
        }

        .divider {
            background: $neutral-200;
        }
    }

    .button-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-self: flex-end;
    }
}

.export-form-selection-container {
    .drawer-content-section-header {
        height: 50px;
    }
}
