@import "src/shared/styles/colors.scss";

.sms-generator-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    animation: fadeIn 0.3s;

    .sms-generator-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .tooltip-container {
        cursor: pointer;
    }
    .text-area {
        background-color: $neutral-100 !important;
    }

    .sms-generator-variables-container {
        display: flex;
        flex-direction: row;
        gap: 4px;

        .sms-generator-variables {
            cursor: pointer;
            background-color: $neutral-300;
            border-radius: 5px;
            padding: 4px;
            &:hover {
                background-color: $neutral-200;
            }
        }
    }
}
