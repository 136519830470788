@import "src/shared/styles/colors.scss";

.wallet-informations-drawer-details-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    .item {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        gap: 16px;

        span:first-child {
            width: 50%;
            color: $neutral-500;
        }

        .tag-container {
            height: 17px;
        }
    }
}
