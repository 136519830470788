@import "src/shared/styles/colors.scss";

.loan-panel-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .placeholder {
        height: 300px;
        width: 100%;
        background-color: $neutral-50;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: wait;
        animation: glow 0.7s ease-in-out infinite alternate;
        &:last-of-type {
            margin-top: 20px;
            height: 120px;
        }
    }
}
@keyframes glow {
    from {
        background-color: $white;
    }
    to {
        background-color: $neutral-100;
    }
}
