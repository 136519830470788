@import "src/shared/styles/colors.scss";

.profile-settings-tab {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-contents {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .left-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50%;
    }

    .right-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50%;
    }

    .profile-settings-form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .name-content {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .input-container {
                width: 50%;
            }
        }

        .alert-container {
            .button-container {
                width: 100%;
            }
        }
    }

    .referal-settings {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .referal-info {
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
    }

    .language-selection-container {
        .select {
            .header {
                background-color: $neutral-100 !important;
            }
        }
        .select-container .select {
            .select-header {
                width: calc(100% - 32px);
            }
            .select-content {
                width: 100% !important;
            }
        }
    }

    .profile-picture-section {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .text-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .file-upload-button {
        cursor: pointer;
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        color: $white;
        background-color: $neutral-900;
        font-weight: bold;
    }
}
