@import "src/shared/styles/colors.scss";

.spinner {
    display: inline-block;
    border: 3px solid $lilas-200;
    border-radius: 50%;
    border-top-color: $lilas-700;
    animation: spin 1s cubic-bezier(0.39, 0.25, 0.4, 0.82) infinite;
    -webkit-animation: spin 1s cubic-bezier(0.39, 0.25, 0.4, 0.82) infinite;

    &.size-xs {
        height: 16px;
        width: 16px;
    }
    &.size-sm {
        height: 24px;
        width: 24px;
    }
    &.size-md {
        height: 32px;
        width: 32px;
    }
    &.size-lg {
        height: 40px;
        width: 40px;
    }
    &.size-xl {
        height: 48px;
        width: 48px;
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
