@import "src/shared/styles/colors.scss";
@import "src/shared/styles/animations.scss";

.button-select-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .button-select-divider {
        width: 2px;
        height: auto;
    }

    .button-container {
        border-radius: 0;
        &:first-of-type {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-of-type {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    .select-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        padding: 8px;
        cursor: pointer;
        border-radius: 10px;
        background-color: $white;
        position: absolute;
        top: 50px;
        right: 0;
        z-index: 10;
        width: 280px;
        transition: 0.3s;
        opacity: 0;
        box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.06);
        display: none;

        &.open {
            display: flex;
            transition: 0.3s;
            opacity: 1;
            animation: fadeInUp 0.3s ease-out;
        }

        .select-content-item {
            display: flex;
            height: 24px;
            padding: 8px 16px;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            border-radius: 10px;

            &:hover {
                background-color: $neutral-100;
            }

            .select-content-item-text {
                display: flex;
                flex-direction: column;
            }
        }

        .select-content-divider {
            width: 100%;
            height: 2px;
            background-color: $neutral-100;
        }
    }

    .button-container {
        &.open {
            i {
                transform: rotate(180deg);
            }
        }
    }
}
