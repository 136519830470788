@import "src/shared/styles/colors.scss";
@import "src/shared/styles/fonts/icons.scss";

.checkbox-container {
    display: flex;
    align-items: center;
    user-select: none;

    &:has(input[type="checkbox"]:disabled) {
        opacity: 0.5;
    }

    &:hover {
        input[type="checkbox"] {
            cursor: pointer;
        }
        &:has(input[type="checkbox"]:disabled) {
            cursor: not-allowed;
            input[type="checkbox"]:disabled {
                cursor: not-allowed;
            }
        }
    }

    .checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type="checkbox"] {
        height: 20px;
        width: 20px;
        margin: 0;
        padding: 0;
        opacity: 1;
        appearance: none;
        border: 1.5px solid $neutral-300;
        border-radius: 4px;
        background: $white;
        position: relative;
        margin-right: 12px;
        transition: 0.2s background-color;
        flex-shrink: 0;
    }

    input[type="checkbox"]:disabled {
        border: 1.5px solid $neutral-300;
    }

    input[type="checkbox"]:checked {
        border: 2px solid transparent;
        background: $lilas-900;
    }

    input[type="checkbox"]:checked:hover {
        background: $lilas-700;
    }

    input[type="checkbox"]:checked:disabled {
        border: 2px solid $lilas-500;
        background: $lilas-500;
    }

    input[type="checkbox"]:checked:before,
    input[type="checkbox"]:checked:after {
        content: "";
        position: absolute;
        height: 2px;
        background: $white;
    }

    input[type="checkbox"]:checked:before {
        width: 8px;
        top: 10px;
        left: 1px;
        transform: rotate(44deg);
    }

    input[type="checkbox"]:checked:after {
        width: 14px;
        top: 7px;
        left: 4px;
        transform: rotate(-55deg);
    }

    input[type="checkbox"]:focus {
        outline: none;
    }
}
