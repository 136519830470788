@import "src/shared/styles/colors.scss";

.amount-input-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .amount-input {
        position: relative;
        display: block;
        padding: 10px 12px;
        border-radius: 10px;
        background-color: $neutral-100;
        border: 1px solid transparent;
        transition: 0.2s;
        height: 60px;

        &.input-primary {
            &.filled {
                border: 1px solid $lilas-700;
            }
            &.focused {
                border: 1px solid $lilas-700;
            }
        }

        &.input-success {
            border: 1px solid $success-500;
        }

        &.input-error {
            border: 1px solid $error-500;
        }

        &.input-warning {
            border: 1px solid $warning-500;
        }

        .amount-input-label {
            color: $neutral-900;
            font-weight: bold;
            display: block;
        }

        .amount-currency-container {
            bottom: calc(1rem + -3px);
            position: absolute;
            display: flex;
            align-items: center;
            width: calc(100% - 2rem);
            z-index: 1;
            color: $neutral-900;

            .amount-input-value {
                opacity: 0;
                font-size: 24px !important;
                font-weight: bold;
                max-width: calc(100% - 5rem);
                overflow: hidden;
            }

            .amount-currency {
                font-size: 24px !important;
                font-weight: bold;
                line-height: 1;
                margin-left: 0.625rem;
                color: $neutral-900;

                &.zero {
                    color: $neutral-400 !important;
                }
            }
        }

        .amount-text-input {
            font-weight: bold;
            font-size: 1.5rem;
            display: block;
            width: calc(100% - 5rem);
            line-height: 1;
            color: $neutral-900 !important;
            background-color: transparent;
            border: none;
            z-index: 2;
            position: relative;
            font-family: "Inter", sans-serif;
            bottom: -10px;

            &.zero {
                &::placeholder {
                    color: $neutral-400 !important;
                }
                color: $neutral-400 !important;
            }

            &:focus {
                background-color: transparent;
                border-color: $neutral-600;
                outline: 0;

                .amount-currency {
                    display: none;
                }
            }
        }
    }

    .under-message {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.variant-primary {
            color: $lilas-700;
        }

        &.variant-success {
            color: $success-500;
        }

        &.variant-error {
            color: $error-500;
        }

        &.variant-warning {
            color: $warning-500;
        }
    }
}
