@import "src/shared/styles/colors.scss";

@mixin skeleton-animation {
    overflow: hidden;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholder-glow;
    animation-timing-function: linear;
    background: $neutral-500;
}

@keyframes placeholder-glow {
    0% {
        background-color: $neutral-500;
    }
    50% {
        background-color: lighten($neutral-500, 20%);
    }
    100% {
        background-color: $neutral-500;
    }
}

.skeleton {
    @include skeleton-animation();
    border-radius: 5px;
    display: inline-block;
    min-height: 1em;
    width: 100%;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.5;

    &.lg {
        height: 48px;
    }
}
