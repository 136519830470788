@import "src/shared/styles/colors.scss";

.copy-input-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .copyable-text-container {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
    }

    .copyable-text {
        background-color: $neutral-100;
        font-weight: lighter;
        padding: 10px;
        border-radius: 10px;
        width: calc(100%);
        height: 20px;
    }

    .copy-input-below-message {
        font-weight: lighter;
        color: $neutral-500;
    }

    .link-copied-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
}
