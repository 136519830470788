@import "src/shared/styles/colors.scss";

.transfert-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .text-danger {
        padding: 0 15px;
        color: $error-500;
    }

    .buttons-spacing {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .beneficiary-illustration {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 15px 0;

        .beneficiary-illustration-image {
            width: 100px;
            height: 100px;
            margin-bottom: 15px;
        }
    }

    .selected-credit-account-summary {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .input-container {
            .input {
                background: $neutral-100;
            }
        }

        .selected-account-summary-item {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
}
