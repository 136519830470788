@import "src/shared/styles/colors.scss";
@import "src/shared/styles/general.scss";

.create-task-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    width: 100%;
    height: calc(100% - 62px);
    text-align: center;

    .error-message {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        gap: 8px;
    }

    .content-width {
        width: 33rem;
    }

    .create-task-section-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 256px;
        height: 200px;
        background: $white;
        border-radius: 15px;
        padding: 0px;
        transition: 0.2s;

        &:hover {
            box-shadow: $shadow-lg;
            transition: box-shadow 0.2s;
            background: $white;
        }
    }

    .create-task-section-button-disabled {
        cursor: unset;
        background: $neutral-200 !important;
        &:hover {
            background: $neutral-200 !important;
            box-shadow: none;
        }
    }

    .create-task-image {
        display: flex;
        width: 100%;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        border-radius: 0.625rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .text-align-start {
        text-align: start;
    }

    .test-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;
        padding: 6px 8px;
        align-self: baseline;
        width: calc(100% - 16px);
        background-color: $white;
        height: 50px;
        border-radius: 10px;
        box-shadow: $shadow-md;
    }

    .alert-create-task {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-radius: 10px;
        background-color: $warning-50;
        border: 1px solid $warning-200;
        margin-top: 16px;

        .first-part {
            display: flex;
            flex-direction: row;
            gap: 16px;
            text-align: left;

            span {
                margin: auto;
            }
        }
    }
}

.create-task-height {
    height: calc(100% - 2px);
}
