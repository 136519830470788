@import "src/shared/styles/colors.scss";

.avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    .avatar-image {
        max-width: 100%;
        height: auto;
        display: block;
        object-fit: cover;
    }

    &.avatar-xs {
        min-width: 24px;
        min-height: 24px;
        width: 24px;
        height: 24px;
        border-radius: 6px;

        .avatar-image {
            border-radius: 6px;
        }
        .avatar-initials {
            &.color-initials-primary {
                color: $lilas-900 !important;
            }
            &.color-initials-neutral {
                color: $neutral-500 !important;
            }
            font-size: 12px !important;
        }
        i {
            width: 20px;
        }
    }

    &.avatar-sm {
        min-width: 32px;
        min-height: 32px;
        width: 32px;
        height: 32px;
        border-radius: 8px;

        .avatar-image {
            border-radius: 8px;
        }
        .avatar-initials {
            &.color-initials-primary {
                color: $lilas-900 !important;
            }
            &.color-initials-neutral {
                color: $neutral-500 !important;
            }
            font-size: 12px !important;
        }
        i {
            width: 20px;
        }
    }

    &.avatar-md {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
        border-radius: 10px;

        .avatar-image {
            border-radius: 10px;
        }

        .avatar-initials {
            &.color-initials-primary {
                color: $lilas-900 !important;
            }
            &.color-initials-neutral {
                color: $neutral-500 !important;
            }
            font-size: 12px !important;
        }
        i {
            width: 20px;
        }
    }

    &.avatar-lg {
        min-width: 48px;
        min-height: 48px;
        width: 48px;
        height: 48px;
        border-radius: 10px;

        .avatar-image {
            border-radius: 10px;
        }

        .avatar-initials {
            &.color-initials-primary {
                color: $lilas-900 !important;
            }
            &.color-initials-neutral {
                color: $neutral-500 !important;
            }
            font-size: 14px !important;
        }
        i {
            width: 24px;
        }
    }

    &.avatar-xl {
        min-width: 56px;
        min-height: 56px;
        width: 56px;
        height: 56px;
        border-radius: 10px;

        .avatar-image {
            border-radius: 10px;
        }

        .avatar-initials {
            &.color-initials-primary {
                color: $lilas-900 !important;
            }
            &.color-initials-neutral {
                color: $neutral-500 !important;
            }
            font-size: 20px !important;
        }
        i {
            width: 24px;
        }
    }

    &.avatar-xxl {
        width: 64px;
        height: 64px;
        border-radius: 10px;

        .avatar-image {
            border-radius: 10px;
        }

        .avatar-initials {
            &.color-initials-primary {
                color: $lilas-900 !important;
            }
            &.color-initials-neutral {
                color: $neutral-500 !important;
            }
            font-size: 20px !important;
        }
        i {
            width: 32px;
        }
    }
}
