@import "src/shared/styles/colors.scss";

.admin-beneficiaries-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 90%;

    &.create {
        height: 100%;
        margin-top: 100px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .page-header-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .create-button {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 2px;
            cursor: pointer;
        }
    }

    .external-account-avatars {
        display: flex;
        flex-direction: row;
        gap: 4px;
    }

    .table-container {
        th:first-child {
            width: 60px;
        }
        td:last-child {
            button {
                width: 40px !important;
            }
        }
    }

    .selectable-table-container {
        margin-bottom: 85px;
    }

    .selectable-bar-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 8px;

        &:hover {
            cursor: pointer;
            background-color: $neutral-100;
            border-radius: 10px;
        }
    }
}
