@import "src/shared/styles/colors.scss";

.dashboard-paid-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .toggler-wrapper {
        width: fit-content;
    }
}
