@import "src/shared/styles/colors.scss";

.edit-expense-policy-drawer {
    padding: 16px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .drawer-divider {
        margin-right: -16px;
        margin-left: -16px;
        width: calc(100% + 32px);
        background-color: $neutral-100;
    }

    .edition {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .buttons {
            display: flex;
            justify-content: flex-end;
            gap: 16px;
        }
    }

    .label-or-description {
        display: flex;
        margin-bottom: 16px;
        width: 100%;

        &.centered {
            align-items: center;
        }
        .element {
            flex: 1;
        }
    }

    .select-container .select .select-header {
        background-color: white !important;
    }

    .input-container .input {
        background-color: white !important;
    }
}
