@import "src/shared/styles/colors.scss";

.note-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    .note-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 16px;
        gap: 8px;
    }

    .note-content {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 16px;

        .note-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            .note-item-title {
                width: 45%;
            }

            .note-item-value {
                width: 55%;
            }

            &.vertical {
                flex-direction: column;
                gap: 8px;
                align-items: flex-start;

                .note-item-title .note-item-value {
                    width: 100%;
                }
            }
        }
    }
    &.container-primary {
        background-color: $neutral-50;

        .note-header {
            border-bottom: 2px solid $neutral-100;
        }
    }

    &.container-success {
        background-color: $success-50;

        .note-header {
            border-bottom: 2px solid $success-100;
        }
    }

    &.container-warning {
        background-color: $warning-50;

        .note-header {
            border-bottom: 2px solid $warning-100;
        }
    }

    &.container-error {
        background-color: $error-50;

        .note-header {
            border-bottom: 2px solid $error-100;
        }
    }
}
