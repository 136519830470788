@import "src/shared/styles/colors.scss";

.tabs-primary,
.tabs-secondary {
    display: flex;
    flex-direction: row;
    width: fit-content;
    border-radius: 10px;
}

.tab-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    color: $neutral-900;

    &.active {
        transition: 0.2s;
    }

    &.disabled {
        color: $neutral-500;
        cursor: not-allowed;
    }
}

.tabs-primary {
    background: $white;
    padding: 4px;

    .tab-item {
        padding: 6px 16px;
        border-radius: 10px;

        &.active {
            color: $white;
            background-color: $neutral-900;

            i {
                color: $white;
            }
        }
    }
}

.tabs-secondary {
    .tab-item {
        padding: 12px 16px;
        border-bottom: 2px solid transparent;

        &.active {
            color: $neutral-900;
            border-bottom: 2px solid $neutral-900;
        }
    }
}
