.task-list-table {
    display: flex;
    flex-direction: column;

    .details-cell {
        width: 270px;
    }

    .avatar-column {
        width: 40px;
    }

    .table-container {
        th {
            min-width: 30px;
        }
        td:last-child {
            i {
                margin: auto;
            }
        }
    }
}

.task-list-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 8px;

    button {
        width: 40px !important;
        padding: 0 !important;
    }
}
