@import "src/shared/styles/colors.scss";

.quests-container {
    .quests-header {
        height: 80px;
        border-radius: 10px 10px 0 0;
        background: $white;
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        border-bottom: 1px solid $neutral-100;
    }

    .quests-carousel-item {
        display: flex;
        flex-direction: column;
        width: 220px;

        .quests-carousel-item-header {
            display: flex;
            border-radius: 10px 10px 0px 0px;
            width: calc(100% - 32px);
            height: calc(100px - 32px);
            padding: 16px;
            justify-content: space-between;

            .header-image {
                align-self: center;
            }

            &.to-do-status {
                background-color: $pink-500;
            }

            &.done-status {
                background-image: $gradiant-pink-horizontal-1;
            }
        }
        .quests-carousel-item-content {
            display: flex;
            height: 180px;
            justify-content: space-between;
            border-radius: 0px 0px 10px 10px;
            background-color: $lilas-50;
            flex-direction: column;
            padding: 16px;

            &.to-do-status {
                background-color: $pink-50;
            }

            &.done-status {
                background-image: $lilas-50;
            }
        }
    }

    .quests {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background: $white;
        padding: 16px;
        border-radius: 0 0 10px 10px;
    }

    .quest-status {
        display: flex;
        gap: 4px;
        background-color: $white;
        align-items: center;
        height: fit-content;
        border-radius: 8px;
        padding: 2px 4px 2px 4px;
    }
}

.quest-modal-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    .quest-video-container {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 100%;
        margin-left: -16px;
        margin-top: -16px;
        transform: scale(0.95);

        .quest-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            border-radius: 10px;
            box-sizing: border-box;
        }
    }
}
