@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/general.scss";

.paginate-container {
    display: flex;
    flex-direction: row;
    height: 58px;
    background-color: $white;
    border-radius: 10px;
    margin-top: auto;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    box-shadow: $shadow-lg;
    bottom: 15px;
    width: calc(100% - 277px);
    z-index: 2;

    @include breakpoint(md) {
        bottom: 80px;
    }

    &::after {
        content: "";
        position: absolute;
        top: 57px;
        left: 0;
        width: 100%;
        height: 15px;
        background-color: $neutral-100;
        z-index: -1;
    }

    .select-page-size-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        margin-left: 16px;

        .select-multiple-option-container {
            width: 100px;

            .select-content {
                margin-top: -160px;
            }

            .content-body {
                div {
                    gap: 4px;
                }
            }
        }
    }

    .pagination-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        .arrow-buttons {
            height: 40px;
            width: 40px;
        }
        .number-buttons {
            height: 40px;
            width: 40px;
        }
    }

    .direct-page-search-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        margin-right: 16px;
        .input-container {
            width: 40px;
        }
        .search-button {
            height: 40px;
            width: 40px;
        }
    }
}

.paginate-dynamo-container {
    display: flex;
    flex-direction: row;
    height: 58px;
    background-color: $white;
    border-radius: 10px;
    margin-top: auto;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    box-shadow: $shadow-lg;
    bottom: 15px;
    z-index: 2;
    width: calc(100% - 260px);

    @include breakpoint(md) {
        bottom: 80px;
    }

    &::after {
        content: "";
        position: absolute;
        top: 57px;
        left: 0;
        width: 100%;
        height: 15px;
        background-color: $neutral-100;
        z-index: -1;
    }

    .paginate-dynamo-content {
        display: flex;
        flex-direction: row;
        margin-right: 16px;
    }

    .paginate-anotation {
        font-weight: bold;
        margin-right: 16px;
    }

    .paginate-button {
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        background-color: $neutral-50;
        transition: 0.2s;

        &:hover {
            background-color: $neutral-200;
            transition: 0.2s;
        }

        &.disabled {
            background-color: $neutral-100;
            &:hover {
                background-color: $neutral-50;
                cursor: not-allowed;
            }
        }

        &.first {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        &.last {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}
