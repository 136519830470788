@import "src/shared/styles/colors.scss";

.bank-info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .details-list {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .bank-link {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($primary, 0.2);
        padding: 0.5rem;
        border-radius: 0.652rem;
        color: $primary;
    }

    .bank-button-container {
        button {
            width: 100%;
        }
    }
}

.bank-select-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .image-container {
        width: 100px;
    }
}
