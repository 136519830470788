@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.overlay {
    background-color: rgba($black, 0.5);
    filter: blur(1px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    transition: all 0.3s ease-in-out;

    &.left {
        left: 245px;
    }
}

.drawer-container {
    .drawer {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 550px;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.left {
            left: -550px;
            transition: all 0.3s ease-in-out;

            &.open {
                left: 245px;
                animation: fadeInLeft 0.5s;
                animation-fill-mode: both;
            }
        }

        &.right {
            right: -550px;
            transition: all 0.3s ease-in-out;

            &.open {
                right: 0;
                animation: fadeInRight 0.5s;
                animation-fill-mode: both;
            }
        }

        &.lg {
            width: 640px;
            &.left {
                left: -640px;
                &.open {
                    left: 245px;
                }
            }
            &.right {
                right: -640px;
                &.open {
                    right: 0;
                }
            }
        }

        @include breakpoint(md) {
            width: 100%;
        }

        .drawer-body {
            height: 100%;
            overflow: auto;
            padding: 20px 16px;
        }
    }
}
