@import "src/shared/styles/colors.scss";

.bills-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .input {
        background-color: $neutral-100;
    }

    .bills-transfert-biller {
        display: flex;
        justify-content: center;
        background-color: $white;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .reference-name-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .dot-container {
            display: flex;
            align-items: center;
            justify-content: center;
            .dot {
                width: 5px;
                height: 5px;
                border-radius: 10px;
                background-color: $lilas-500;
            }
        }
    }

    .image {
        min-width: 8px;
        width: 8px;
        min-height: 8px;
        height: 8px;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border-radius: 6px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.bills-details-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: bold;
    margin-bottom: 16px;

    .bills-details-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .service-row {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            .transaction-service-img {
                height: 20px;
                width: 20px;
            }
            span {
                color: $black;
            }
        }
    }
}
