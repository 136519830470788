@import "src/shared/styles/colors.scss";

.wallet-information-drawer-signataires-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;

    .header {
        height: 40px;
        padding: 16px;
        font-weight: bold;
        align-content: center;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 2px solid $neutral-100;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;

        .signataires-bloc {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .title-container {
                display: flex;
                flex-direction: column;
            }
            .signataires-list {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 16px;

                    border-left: 2px dashed $lilas-900;
                    padding-left: 30px;
                    margin-left: 20px;
                }
            }
            .title-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
            }
        }
    }

    .signataire-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        background-color: $neutral-50;
        border-radius: 10px;
        width: 100%;

        .signataire-item-name {
            display: flex;
            flex-direction: column;
        }
    }
}
