@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.login-panel-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $white;
    justify-content: space-evenly;

    &.demo-warning {
        height: calc(100% - 48px);
    }

    .form-part {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobile-form {
            width: 360px;
            max-width: 360px;
        }

        .login-form {
            width: 470px;

            .input-container {
                .input {
                    background-color: $neutral-100;
                }
            }

            .julaya-logo {
                width: 100px;
                margin-bottom: 16px;
            }

            .login-title-happy {
                font-weight: 700;
                margin-bottom: 8px;
            }

            .login-subtitle {
                color: $neutral-500;
                font-weight: bold;
                font-size: 14px !important;
            }

            .form-container {
                display: flex;
                flex-direction: column;
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin: 16px 0;

                .forgot-password-button {
                    align-self: flex-end;
                    text-decoration: underline;
                    cursor: pointer;
                    color: $lilas-900;

                    &:hover {
                        color: $neutral-400;
                    }
                }
            }

            .login-code-validation-container {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .code-validation-tooltip {
                    width: 250px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .forgot-password-form {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .julaya-logo {
                margin-bottom: 0;
            }
            .title-part {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .button-part {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
        }
    }

    .send-email-again-button {
        align-self: flex-end;
        text-decoration: underline;
        cursor: pointer;
        color: $lilas-900;

        &:hover {
            color: $neutral-400;
        }
    }

    .login-button {
        width: 100%;
    }

    .no-account-sentence {
        margin-top: 16px;

        .no-account {
            color: $neutral-500;
        }

        .typeform-slider-button-override {
            background-color: $white;
            border: 0;
            font-size: 14px;
        }

        .signup {
            cursor: pointer;
            font-family: "Inter", sans-serif;
            text-decoration: underline;
            color: $lilas-900;

            &:hover {
                color: $neutral-400;
            }
        }
    }

    .image-part {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $gradiant-pink-horizontal-1;

        .content {
            display: flex;
            flex-direction: column;
            width: 60%;
        }

        .login-title-img {
            font-family: "GT Walsheim" !important;
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: 24px;
            text-transform: uppercase;
            margin-bottom: 24px;
            margin-top: 4px;
        }
    }

    .code-validation-container {
        display: flex;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .input-items {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        .input-validation {
            width: 58px;
            height: 100px;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            border: none;
            border-radius: 1rem;
            background: $neutral-100;
            line-height: 32px;

            &:focus {
                outline: 1px solid $lilas-700;
            }
        }
    }

    .send-another-code-button {
        text-decoration: underline;

        &.active {
            cursor: pointer;
            &:hover {
                color: $lilas-700;
            }
        }
    }

    .login-code-validation-sentence {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-top: 16px;
    }

    .button-part {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .button-container {
            width: 100%;
        }
    }
}

.password-change-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .password-change-title {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .password-validation-checker {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            transition: 0.2s;
            color: $neutral-500;

            &.valid {
                color: $success;
                transition: 0.2s;
            }

            &.invalid {
                color: $error;
                transition: 0.2s;
            }
        }
    }

    .progress-bar-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 8px;

        .progress-bar {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 8px;

            .progress-bar-item {
                background-color: $neutral-100;
                width: 100%;
                height: 8px;

                &.valid {
                    background-color: $success;

                    &:last-child {
                        border-top-right-radius: 10px !important;
                        border-bottom-right-radius: 10px !important;
                    }

                    &.error {
                        background-color: $error;
                    }

                    &.warning {
                        background-color: $warning;
                    }

                    &.success {
                        background-color: $success;
                    }
                }

                &.last {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    .error-message-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        color: $error;
    }
}

.success-login-image {
    width: 200px;
    height: 150px;
}
