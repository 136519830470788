@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.loan-history-container {
    display: flex;
    flex-direction: column;
    background-color: $neutral-50;
    border-radius: 10px;
    padding: 20px 16px;

    .loan-history-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .locked-icon {
            opacity: 0.4;
        }
    }

    .loan-history-content {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $neutral-200;
        margin-top: 16px;
        padding-top: 16px;
        gap: 16px;

        .loan-history-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            gap: 8px;

            padding: 16px;
            background: $neutral-100;
            border-radius: 10px;
            box-sizing: border-box;
            @include breakpoint(md) {
                height: 100%;
            }
            .loan-history-item-inline-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;

                @include breakpoint(md) {
                    margin-top: 10px;
                    align-items: flex-start;
                    flex-direction: column;
                }
                .item-inline {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                }
            }
        }
    }
}
