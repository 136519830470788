@import "src/shared/styles/colors.scss";

.expence-policy-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .expense-policy-filters-container {
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: $neutral-100;

            .input {
                background-color: $white;
            }
        }
    }

    .expense-policy-tooltip {
        display: flex;
        flex-direction: column;
        width: max-content;
    }

    .expense-policy-table-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        tr {
            td:first-child {
                width: 40px;
            }

            td:last-child {
                button {
                    width: 40px !important;
                    margin-left: auto;
                }
            }

            .icon-squared-adjustments {
                width: 28px;
                height: 28px;
            }
        }
    }

    .expense-policy-create-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    .tooltip-container .tooltip {
        width: 100%;
    }
}
