@import "src/shared/styles/colors.scss";

.section-join-container {
    display: flex;
    padding: 12px 16px 12px 16px;
    background-color: $neutral-50;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}
