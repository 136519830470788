@import "src/shared/styles/colors.scss";

.filter-history-container {
    .filters-select {
        width: 340px;

        .body-content {
            gap: 15px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .footer-content {
            display: flex;
            gap: 12px;
            width: 100%;

            .footer-btn {
                flex: 1 1 0;
            }
        }

        .transaction-type-filter {
            .select {
                .select-content {
                    width: 340px;

                    .select {
                        .select-content {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .select-container {
            .select {
                .header {
                    background-color: $neutral-100;
                }
            }
        }
    }
}

.wallet-panel-container {
    display: flex;
    flex-direction: column;
    .history-list-item {
        background-color: $white;
        padding: 1rem;
        border-radius: 0.625rem;
        border: 2px solid transparent;
        display: flex;
        flex-direction: column;
    }

    .wallet-panel-page-header {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .wallet-panel-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 16px;

        .wallet-header-left {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
        }
    }

    .table-container {
        tr {
            td:first-child {
                width: 48px;
            }
        }

        .transaction-name {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            font-size: 14px !important;
        }
        .icon-status {
            i {
                height: 24px;
                width: 24px;
            }
        }
    }
}

.dropdown-menu {
    min-width: 400px;
}

.modal-multiple-action-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

    .multiple-action-cancel-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
        .multiple-action-cancel-item {
            display: flex;
            flex-direction: row;

            span {
                width: 50%;

                &:first-of-type {
                    font-weight: bold;
                    color: $neutral-500;
                }

                &:last-of-type {
                    font-weight: bold;
                }

                .color-error {
                    color: $error-500;
                }
            }
        }
    }

    .select-multiple-actions {
        box-sizing: border-box;
        width: 100%;
    }
}
.success-multiple-receipt {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    padding: 16px;

    img {
        width: 50%;
    }
}

.email-input-multiple-receipt-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;

    button {
        flex-grow: 1;
        margin-bottom: 1px;

        &.error {
            margin-bottom: 30px;
            transition: 0;
        }
    }
}

.email-list-multiple-receipt-show {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;

    .item {
        display: flex;
        border-radius: 10px;
        background-color: $lilas-50;
        font-weight: bold;
        width: fit-content;
        padding: 3px 8px;
        gap: 4px;
        span {
            margin-top: 2px;
        }
    }
}

.transaction-service-img {
    &.image-sm {
        width: 16px;
        height: 16px;
    }
    &.image-md {
        width: 24px;
        height: 24px;
    }
    &.image-lg {
        width: 32px;
        height: 32px;
    }

    .service-img {
        width: 100%;
        border-radius: 0.3rem;
    }
}

.strike-through-text {
    text-decoration: line-through;
}

.comment {
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    max-width: 180px;
}

.export-success-content-modal {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
}

.actions {
    display: flex;
    flex-direction: column;

    .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 8px;
        border-radius: 10px;

        &:hover {
            transition: 0.2s;
            background-color: $neutral-200;
        }
    }
}

.transaction-name {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}
