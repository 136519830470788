@import "src/shared/styles/colors.scss";

.input-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .input-label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .input {
        width: calc(100% - 20px);
        display: flex;
        flex-direction: row;
        background-color: $neutral-100;
        height: 40px;
        border-radius: 10px;
        padding: 0 10px;
        transition: 0.2s;
        font-size: 14px;

        .input-field {
            width: 100% !important;
            height: 100%;
            background-color: transparent;
            all: unset;

            &:-webkit-autofill,
            &:-webkit-autofill:focus {
                transition: background-color 600000s 0s, color 600000s 0s;
            }

            &.disabled {
                color: $neutral-400;
                cursor: not-allowed;
            }
        }

        &.variant-primary {
            border: 1px solid transparent;
            &.focused {
                border: 1px solid $lilas-700;
            }
        }

        &.variant-success {
            border: 1px solid $success-500;
        }

        &.variant-error {
            border: 1px solid $error-500;
        }

        &.variant-warning {
            border: 1px solid $warning-500;
        }
    }

    .under-message {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        margin-left: 4px;

        &.variant-primary {
            color: $lilas-700;
        }

        &.variant-success {
            color: $success-500;
        }

        &.variant-error {
            color: $error-500;
        }

        &.variant-warning {
            color: $warning-500;
        }
    }
}

.text-area {
    outline: 0;
    background-color: #ffffff !important;
    border-radius: 10px;
    padding: 10px;
    line-height: 20px;
    resize: none;
    font-family: inherit;
    font-size: 14px;
    border: 1px solid transparent;

    &:focus {
        border: 1px solid $lilas-700;
    }

    &.variant-neutral {
        background-color: $neutral-100 !important;
    }

    &.disabled {
        cursor: not-allowed;
    }
}
