@import "src/shared/styles/colors.scss";
.wallet-transactions-pie-chart-container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;

    .wallet-transactions-pie-chart-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 8px;
        border-bottom: 2px solid $neutral-100;
    }

    .wallet-transactions-pie-chart-content {
        display: block;
        padding: 16px;
        text-align: center;
        align-content: center;
        max-width: 100%;
        height: 500px;

        .wallet-transactions-pie-chart-hover-window {
            display: flex;
            flex-direction: column;
            padding: 16px;
            background-color: $white;
            text-align: left;
            border-radius: 10px;
            box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.06);
        }
    }
}
