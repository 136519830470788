@import "src/shared/styles/colors.scss";

.drawer-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    border-top: 2px solid $neutral-200;
    background-color: $white;

    .drawer-footer-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        gap: 8px;

        .centered-dot {
            background-color: $lilas-500;
            height: 4px;
            width: 4px;
            border-radius: 100%;
        }
    }

    .drawer-footer-buttons {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
    }
}
