@import "src/shared/styles/colors.scss";

.carousel-container {
    overflow: hidden;

    .carousel-header {
        display: flex;
        width: 100%;
        padding-bottom: 14px;
        align-items: center;

        &.space-between {
            justify-content: space-between;
        }

        &.align-end {
            justify-content: end;
        }

        .carousel-buttons-container {
            display: flex;
            gap: 8px;
        }
    }

    .carousel {
        position: relative;

        .carousel-items {
            display: flex;
            transition: transform 1s ease;

            .carousel-item {
                padding-right: 14px;
            }
        }
    }
}
