@import "src/shared/styles/colors.scss";

.get-paid-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100% - 64px);

    .get-paid-modules {
        display: flex;
        gap: 16px;
    }

    .get-paid-error {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

.bill-modules-skeleton-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .main-module {
        width: 100%;
        height: 220px;
        border-radius: 10px;
        animation: glow 0.7s ease-in-out infinite alternate;
    }

    .secondary-module-container {
        display: flex;
        gap: 16px;

        .module {
            width: 100%;
            height: 80px;
            border-radius: 10px;
            animation: glow 0.7s ease-in-out infinite alternate;
        }
    }
}
