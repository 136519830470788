@import "src/shared/styles/colors.scss";

.collect-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .collect-settings-edit {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 16px;

        .collect-select {
            box-sizing: border-box;
            width: 100%;
        }

        .collect-edit-button {
            display: flex;
            gap: 16px;
            justify-content: end;
            width: 100%;
        }
    }

    .collect-divider {
        width: calc(100% + 32px);
        margin-left: -16px;
        height: 2px;
        background: $neutral-100;
        margin-top: 16px;
    }

    .collect-settings-container {
        background-color: $white;
        border-radius: 8px;
        padding: 16px;

        .collect-settings-title {
            display: flex;
            justify-content: space-between;
        }

        .collect-settings-info-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-top: 16px;
        }

        .collect-settings-text {
            width: 200px;
        }
    }

    .collect-helper-container {
        display: flex;
        gap: 16px;
        padding: 16px;

        .collect-helper-item {
            background-color: $neutral-100;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 16px;
            gap: 16px;
            width: 360px;
            text-align: center;

            .collect-helper-image {
                width: 200px;
                height: 150px;
            }
        }
    }
}
