@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.loan-current-container {
    display: flex;
    flex-direction: column;
    background-color: $neutral-50;
    border-radius: 10px;
    padding: 20px 16px;

    .loan-current-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        justify-content: space-between;

        .loan-current-header-left-part {
            display: flex;
            flex-direction: row;

            @include breakpoint(md) {
                margin-bottom: 8px;
            }

            .loan-current-title {
                display: flex;
                flex-direction: column;
                align-self: center;
                width: 100%;
                margin-left: 8px;
            }
        }

        @include breakpoint(md) {
            flex-direction: column;
        }

        .loan-current-buttons {
            display: flex;
            flex-direction: row;
            gap: 10px;

            @include breakpoint(md) {
                flex-direction: column;
            }
        }
    }

    .loan-current-content {
        display: flex;
        flex-direction: row;
        gap: 16px;
        border-top: 1px solid $neutral-200;
        padding: 16px;

        @include breakpoint(md) {
            flex-direction: column;
        }

        .loan-gauge-section {
            width: 33%;
            text-align: center;
            @include breakpoint(md) {
                width: 100%;
                margin-bottom: 16px;
            }

            .loan-gauge-text {
                display: flex;
                flex-direction: column;
                text-align: center;
            }
        }

        .loan-rest-to-pay-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            width: 100%;
            border-left: 1px solid $neutral-200;
            border-right: 1px solid $neutral-200;
            padding-left: 16px;
            width: 33%;
            margin-left: 16px;

            @include breakpoint(md) {
                width: 100%;
                margin-bottom: 20px;
                align-items: center;
                border-left: 0;
                border-right: 0;
                border-top: 1px solid $neutral-200;
                border-bottom: 1px solid $neutral-200;
                padding: 16px 0;
                margin: 16px 0;
            }

            .loan-rest-to-pay-interests {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                color: $primary;

                @include breakpoint(md) {
                    margin: auto;
                }
            }
        }

        .loan-additional-fees-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            width: 33%;

            @include breakpoint(md) {
                width: 100%;
                margin-bottom: 16px;
                align-items: center;
            }

            .loan-additional-fees-percentile {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;

                .information-tooltip-container {
                    cursor: pointer;
                }
                .tooltip {
                    width: 500px;
                    background-color: $white;
                    color: black;

                    @include breakpoint(md) {
                        width: 200px;
                    }
                }
            }

            .loan-rest-to-pay-interests {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                color: $primary;
            }
        }
    }
}
