.link-image-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.link-image-success {
    width: 200px;
    height: 150px;
}

.modal-email-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
