@import "src/shared/styles/colors.scss";

.tooltip-container {
    position: relative;
    display: inline-block;
    height: 100%;
    .tooltip {
        position: absolute;
        z-index: 1;
        padding: 1rem;
        background-color: $white;
        color: $black;
        border-radius: 10px;
        min-width: 120px;
        pointer-events: none;
        opacity: 0;
        transition: all ease-in 0.2s;

        &-top {
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        &-bottom {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        &-left {
            top: 50%;
            right: 100%;
            transform: translateY(-50%);
        }

        &-right {
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
        }

        &.show {
            opacity: 1;
        }
    }
}
