@import "src/shared/styles/colors.scss";

.point {
    color: $primary;
    font-weight: bold;
}

li {
    height: 100%;
    &>.disabled{
        cursor: not-allowed !important;
        opacity: 0.5;
    }
}

.dashboard-list-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    

    .money-display {
        font-weight: bold;
        font-size: 20px !important;
    }

    .list-item-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;

        .title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            .dot {
                width: 4px;
                height: 4px;
                flex-shrink: 0;
                border-radius: 100%;
                background-color: $lilas-500;
            }
        }

        .iban-button-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            .iban-button {
                cursor: pointer;
                font-weight: bold;
                color: $lilas-900;

                &:hover {
                    color: $lilas-800;
                }
            }
        }
    }
}

.dashboard-container {
    display: flex;
    flex-direction: column;

    .dashboard-title {
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 30px !important;
    }

    .dashboard-subtitle {
        color: $neutral-500;
        font-size: 16px !important;
        align-items: center;
        display: flex;
        font-weight: bold;
    }

    .dashboard-list-container {
        margin-bottom: 16px;

        .list-title-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: bold;
            align-items: center;
            margin-bottom: 16px;

            .list-title {
                font-size: 24px !important;
            }

            .list-total {
                font-size: 14px !important;
                color: $neutral-500;
            }
        }

        .dashboard-card-list {
            li {
                background-color: $white;
            }
        }
    }
}
