@import "src/shared/styles/colors.scss";

.admin-account-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .create-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2px;
            cursor: pointer;
        }
    }
    .table-container-wrapper {
        overflow: auto;
        .avatar-column {
            width: 40px;
        }
        .table-container {
            th {
                min-width: 30px;
            }
        }
    }

    .chip-list-container {
        display: flex;
        gap: 4px;
    }

    .wallet-numbers {
        display: flex;
        justify-content: center;
        justify-self: center;
        align-items: center;
        gap: 8px;

        .wallet-numbers-tooltip {
            z-index: 100;
        }
    }
    .tooltip-container .tooltip {
        width: 100%;
    }
}

.filter-history-container {
    .body-content {
        button {
            width: 100%;
        }
    }
    .input {
        background-color: $white;
    }
}
