@import "src/shared/styles/colors.scss";

.notifications-settings-tab {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-contents {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .left-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        min-width: 40%;
    }

    .right-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 60%;
    }

    .notifications-form {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .notification-list-settings {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .notification-settings-item {
            display: flex;
            flex-direction: row;
            gap: 16px;
            justify-content: space-between;
            padding-bottom: 16px;
            border-bottom: 2px solid $neutral-100;

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }

            .left-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                .text-part {
                    display: flex;
                    flex-direction: column;
                }
            }

            .right-content {
                display: flex;
                justify-content: center;
                width: auto;
                flex-direction: row;
                align-items: center;

                .switch-container {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
            }
        }

        .loading-spinner {
            display: flex;
            justify-content: center;
        }
    }
}
