@import "src/shared/styles/colors.scss";
.App {
    height: 100vh;
    background-color: $neutral-100;
}
html {
    scrollbar-color: $neutral-200 $neutral-100; /* change to the colors you want */
    scrollbar-width: thin; /* or 'auto' or 'thin' */
}

::-webkit-scrollbar {
    width: 17px;
}

::-webkit-scrollbar-track {
    background-color: $neutral-100;
}

::-webkit-scrollbar-thumb {
    background-color: $neutral-200;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: $neutral-500;
}

::-webkit-scrollbar-corner {
    background-color: $neutral-100;
}
