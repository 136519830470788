@import "src/shared/styles/colors.scss";

.custom-radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.disabled {
        color: $neutral-400;
    }

    input[type="radio"] {
        height: 20px;
        width: 20px;
        padding: 0;
        opacity: 1;
        appearance: none;
        border: 1.5px solid $neutral-300;
        background: $white;
        position: relative;
        margin: 0 12px 0 0;
        transition: 0.2s background-color;
        border-radius: 100%;
        flex-shrink: 0;
    }

    input[type="radio"]:disabled {
        border: 1.5px solid $neutral-100;
    }

    input[type="radio"]:checked {
        border: 2px solid transparent;
        border-radius: 100%;
        background: $lilas-900;
    }

    input[type="radio"]:checked:hover {
        background: $lilas-700;
    }

    input[type="radio"]:checked:disabled {
        border: 2px solid $neutral-300;
        background: $neutral-300;
    }

    input[type="radio"]:checked:before,
    input[type="radio"]:checked:after {
        content: "";
        position: absolute;
        height: 2px;
        background: $white;
    }

    input[type="radio"]:checked:before {
        width: 7px;
        top: 10px;
        left: 1px;
        transform: rotate(44deg);
    }

    input[type="radio"]:checked:after {
        width: 12px;
        top: 7px;
        left: 4px;
        transform: rotate(-55deg);
    }

    input[type="radio"]:focus {
        outline: none;
    }
}
