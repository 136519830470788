@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";
@import url("https://paydunya.com/assets/psr/css/psr.paydunya.min.css");

/* --- The Main Container that fills the entire page --- */
.payment-panel-container {
    min-height: 100vh;
    width: 100%;
    background-color: $white;
    position: relative;
    overflow-x: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 300px;
        background: linear-gradient(to right, #cbb8ff, #fecdd3);
        z-index: 0;
        /* behind content */
        transition: height 0.2s ease;
    }

    .content-wrapper {
        position: relative;
        z-index: 1;
        padding: 3rem 1rem 4rem;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .julaya-logo {
            width: 120px;
            margin-bottom: 1.5rem;
        }

        .form-card {
            width: 100%;
            max-width: 480px;
            background-color: $white;
            border-radius: 12px;
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.07);
            padding: 2rem;
            display: flex;
            flex-direction: column;
            transition: padding 0.2s ease, max-width 0.2s ease;

            .payment-title {
                font-weight: 700;
                font-size: 1.5rem;
                margin-bottom: 1rem;
                color: $black;

                @media (max-width: 480px) {
                    font-size: 1.3rem;
                }
            }

            .form-container {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .payment-button {
                    width: 100%;
                    border: none;
                    border-radius: 8px;
                    padding: 0.75rem 1rem;
                    background-color: $primary;
                    color: $white;
                    font-weight: bold;
                    cursor: pointer;
                    transition: background-color 0.2s ease;

                    &:hover {
                        background-color: darken($primary, 8%);
                    }
                }
            }
        }
    }

    /* --- Media Queries for responsiveness --- */

    @media (max-width: 768px) {
        &::before {
            height: 220px;
        }

        .content-wrapper {
            padding: 2rem 1rem 3rem;

            .julaya-logo {
                width: 100px;
                margin-bottom: 1rem;
            }

            .form-card {
                max-width: 100%;
                padding: 1.5rem;
            }
        }
    }

    /* Mobile phones and below */
    @media (max-width: 480px) {
        &::before {
            height: 180px;
        }

        .content-wrapper {
            padding: 2rem 0.75rem 3rem;

            .julaya-logo {
                width: 80px;
            }

            .form-card {
                max-width: 100%;
                border-radius: 8px;
                padding: 1rem;

                .payment-title {
                    margin-bottom: 0.75rem;
                }
            }
        }
    }
}