@charset "utf-8";

@import "src/shared/styles/fonts/icons.scss";
@import "src/shared/styles/colors.scss";

.icon-sm {
    font-size: 20px;
}
.icon-md {
    font-size: 24px;
}
.icon-lg {
    font-size: 32px;
}

.status-icon-with-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    padding: 2px;
    border-radius: 0.4rem !important;
    background-color: rgba($lilas, 0.4);

    .status-icon-content-container {
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;

        .status-icon-text {
            font-weight: bold;
            width: 100% !important;
            text-align: start;
        }
    }

    span {
        color: $black;
    }

    &.status {
        height: 32px;

        &.neutral {
            background-color: $neutral-50 !important;
            color: $neutral-500 !important;
        }

        &.success {
            background-color: $success-50 !important;
            color: $success-500 !important;
        }

        &.warning {
            background-color: $warning-50 !important;
            color: $warning-500 !important;
        }

        &.error {
            background-color: $error-50 !important;
            color: $error-500 !important;
        }

        &.lilas {
            background-color: $lilas-50 !important;
            color: $lilas-500 !important;
        }

        &.gradiant-lilas  {
            background: $gradiant-pink-horizontal-1;
            color: $lilas-900 !important;
        }
    }
}

.icon-squared {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    padding: 6px;
    border-radius: 0.4rem !important;

    &.icon-sm {
        font-size: 24px;
        height: 20px;
        width: 20px;
    }

    &.icon-md {
        font-size: 24px;
        height: 28px;
        width: 28px;
    }
    &.icon-lg {
        font-size: 26px;
        height: 28px;
        width: 28px;
    }

    &.color-primary {
        background-color: $neutral-50 !important;
        color: $neutral-500 !important;
    }

    &.color-lilas {
        background-color: $lilas-50 !important;
        color: $lilas-900 !important;
    }

    &.color-success {
        color: $success-500;
        background-color: $success-50;
    }

    &.color-warning {
        color: $warning-500;
        background-color: $warning-50;
    }

    &.color-error {
        color: $error-500;
        background-color: $error-50;
    }

    &.color-gradiant-lilas {
        color: $lilas-900 !important;
        background: $gradiant-pink-horizontal-1;
    }

    &.status {
        &.primary {
            background-color: $neutral-500 !important;
            color: white !important;
        }

        &.success {
            background-color: $success-500 !important;
            color: white !important;
        }

        &.warning {
            background-color: $warning-500 !important;
            color: white !important;
        }

        &.error {
            background-color: $error-500 !important;
            color: white !important;
        }

        &.lilas {
            background-color: $lilas-500 !important;
            color: white !important;
        }

        &.gradiant-lilas {
            color: $lilas-500 !important;
            background: $gradiant-pink-horizontal-1;
        }
    }
}

.status {
    &.primary {
        color: $neutral-500 !important;
    }

    &.success {
        color: $success-500 !important;
    }

    &.warning {
        color: $warning-500 !important;
    }

    &.error {
        color: $error-500 !important;
    }

    &.lilas &.gradiant-lilas  {
        color: $lilas-500 !important;
    }
}

[class^="jla-icon-"],
[class*=" jla-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "Julaya-v2" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.jla-icon-adjustments-horizontal-filled:before {
    content: "\e900";
}
.jla-icon-adjustments-vertical-filled:before {
    content: "\e901";
}
.jla-icon-archive-filled:before {
    content: "\e902";
}
.jla-icon-arrow-bar-bottom-filled:before {
    content: "\e903";
}
.jla-icon-arrow-bar-top-filled:before {
    content: "\e904";
}
.jla-icon-arrow-down-circle-filled:before {
    content: "\e905";
}
.jla-icon-arrow-down-filled:before {
    content: "\e906";
}
.jla-icon-arrow-down-on-square-filled:before {
    content: "\e907";
}
.jla-icon-arrow-left-circle-filled:before {
    content: "\e908";
}
.jla-icon-arrow-left-down-filled:before {
    content: "\e909";
}
.jla-icon-arrow-left-filled:before {
    content: "\e90a";
}
.jla-icon-arrow-left-on-rectangle-filled:before {
    content: "\e90b";
}
.jla-icon-arrow-left-top-filled:before {
    content: "\e90c";
}
.jla-icon-arrow-path-filled:before {
    content: "\e90d";
}
.jla-icon-arrow-right-circle-filled:before {
    content: "\e90e";
}
.jla-icon-arrow-right-down-filled:before {
    content: "\e90f";
}
.jla-icon-arrow-right-filled:before {
    content: "\e910";
}
.jla-icon-arrow-right-on-rectangle-filled:before {
    content: "\e911";
}
.jla-icon-arrow-right-top-filled:before {
    content: "\e912";
}
.jla-icon-arrow-top-filled:before {
    content: "\e913";
}
.jla-icon-arrow-top-right-on-square-filled:before {
    content: "\e914";
}
.jla-icon-arrow-up-circle-filled:before {
    content: "\e915";
}
.jla-icon-arrow-up-on-square-filled:before {
    content: "\e916";
}
.jla-icon-arrows-right-left-filled:before {
    content: "\e917";
}
.jla-icon-bank-filled:before {
    content: "\e918";
}
.jla-icon-bars-3-filled:before {
    content: "\e919";
}
.jla-icon-bell-filled:before {
    content: "\e91a";
}
.jla-icon-bills-filled:before {
    content: "\e91b";
}
.jla-icon-bolt-filled:before {
    content: "\e91c";
}
.jla-icon-briefcase-filled:before {
    content: "\e91d";
}
.jla-icon-bulk-filled:before {
    content: "\e91e";
}
.jla-icon-calendar-filled:before {
    content: "\e91f";
}
.jla-icon-camera-filled:before {
    content: "\e920";
}
.jla-icon-cart-filled:before {
    content: "\e921";
}
.jla-icon-chart-pie-filled:before {
    content: "\e922";
}
.jla-icon-chat-bubble-filled:before {
    content: "\e923";
}
.jla-icon-check-bold-filled:before {
    content: "\e924";
}
.jla-icon-check-circle-filled:before {
    content: "\e925";
}
.jla-icon-check-filled:before {
    content: "\e926";
}
.jla-icon-chevron-down-filled:before {
    content: "\e927";
}
.jla-icon-chevron-left-filled:before {
    content: "\e928";
}
.jla-icon-chevron-right-filled:before {
    content: "\e929";
}
.jla-icon-chevron-up-down-filled:before {
    content: "\e92a";
}
.jla-icon-chevron-up-filled:before {
    content: "\e92b";
}
.jla-icon-clock-filled:before {
    content: "\e92c";
}
.jla-icon-cloud-filled:before {
    content: "\e92d";
}
.jla-icon-credit-card-filled:before {
    content: "\e92e";
}
.jla-icon-device-computer-filled:before {
    content: "\e92f";
}
.jla-icon-device-phone-mobile-filled:before {
    content: "\e930";
}
.jla-icon-document-filled:before {
    content: "\e931";
}
.jla-icon-document-plus-filled:before {
    content: "\e932";
}
.jla-icon-double-check-circle-filled:before {
    content: "\e933";
}
.jla-icon-ellipsis-horizontal-circle-filled:before {
    content: "\e934";
}
.jla-icon-ellipsis-horizontal-filled:before {
    content: "\e935";
}
.jla-icon-ellipsis-vertical-filled:before {
    content: "\e936";
}
.jla-icon-envelope-filled:before {
    content: "\e937";
}
.jla-icon-exclamation-circle-filled:before {
    content: "\e938";
}
.jla-icon-exclamation-triangle-filled:before {
    content: "\e939";
}
.jla-icon-eye-filled:before {
    content: "\e93a";
}
.jla-icon-eye-off-filled:before {
    content: "\e93b";
}
.jla-icon-fingerprint-filled:before {
    content: "\e93c";
}
.jla-icon-flag-filled:before {
    content: "\e93d";
}
.jla-icon-folder-filled:before {
    content: "\e93e";
}
.jla-icon-forbidden-filled:before {
    content: "\e93f";
}
.jla-icon-gear-filled:before {
    content: "\e940";
}
.jla-icon-gift-filled:before {
    content: "\e941";
}
.jla-icon-globe-europe-africa-filled:before {
    content: "\e942";
}
.jla-icon-globe-filled:before {
    content: "\e943";
}
.jla-icon-handshake-filled:before {
    content: "\e944";
}
.jla-icon-heart-filled:before {
    content: "\e945";
}
.jla-icon-home-filled:before {
    content: "\e946";
}
.jla-icon-identification-filled:before {
    content: "\e947";
}
.jla-icon-inbox-filled:before {
    content: "\e948";
}
.jla-icon-information-circle-filled:before {
    content: "\e949";
}
.jla-icon-julaya-circle-filled:before {
    content: "\e94a";
}
.jla-icon-key-filled:before {
    content: "\e94b";
}
.jla-icon-kiosk-filled:before {
    content: "\e94c";
}
.jla-icon-link-filled:before {
    content: "\e94d";
}
.jla-icon-list-bullet-filled:before {
    content: "\e94e";
}
.jla-icon-lock-filled:before {
    content: "\e94f";
}
.jla-icon-logs-filled:before {
    content: "\e950";
}
.jla-icon-magnifying-glass-filled:before {
    content: "\e951";
}
.jla-icon-map-filled:before {
    content: "\e952";
}
.jla-icon-map-pin-filled:before {
    content: "\e953";
}
.jla-icon-minus-circle-filled:before {
    content: "\e954";
}
.jla-icon-minus-filled:before {
    content: "\e955";
}
.jla-icon-office-filled:before {
    content: "\e956";
}
.jla-icon-passport-filled:before {
    content: "\e957";
}
.jla-icon-pencil-filled:before {
    content: "\e958";
}
.jla-icon-pencil-square-filled:before {
    content: "\e959";
}
.jla-icon-phone-filled:before {
    content: "\e95a";
}
.jla-icon-phone-plus-circle-filled:before {
    content: "\e95b";
}
.jla-icon-photo-filled:before {
    content: "\e95c";
}
.jla-icon-plus-circle-filled:before {
    content: "\e95d";
}
.jla-icon-plus-filled:before {
    content: "\e95e";
}
.jla-icon-power-filled:before {
    content: "\e95f";
}
.jla-icon-printer-filled:before {
    content: "\e960";
}
.jla-icon-qr-code-filled:before {
    content: "\e961";
}
.jla-icon-question-mark-circle-filled:before {
    content: "\e962";
}
.jla-icon-receipt-accepted-filled:before {
    content: "\e963";
}
.jla-icon-receipt-clock-filled:before {
    content: "\e964";
}
.jla-icon-receipt-percent-filled:before {
    content: "\e965";
}
.jla-icon-receipt-refund-filled:before {
    content: "\e966";
}
.jla-icon-receipt-refused-filled:before {
    content: "\e967";
}
.jla-icon-refresh-filled:before {
    content: "\e968";
}
.jla-icon-retry-filled:before {
    content: "\e969";
}
.jla-icon-scale-filled:before {
    content: "\e96a";
}
.jla-icon-send-filled:before {
    content: "\e96b";
}
.jla-icon-share-filled:before {
    content: "\e96c";
}
.jla-icon-shield-check-filled:before {
    content: "\e96d";
}
.jla-icon-sparkles-filled:before {
    content: "\e96e";
}
.jla-icon-square-2-stack-filled:before {
    content: "\e96f";
}
.jla-icon-square-check-filled:before {
    content: "\e970";
}
.jla-icon-squares-filled:before {
    content: "\e971";
}
.jla-icon-star-filled:before {
    content: "\e972";
}
.jla-icon-table-cell-filled:before {
    content: "\e973";
}
.jla-icon-task-filled:before {
    content: "\e974";
}
.jla-icon-thrash-filled:before {
    content: "\e975";
}
.jla-icon-trending-down-filled:before {
    content: "\e976";
}
.jla-icon-trending-up-filled:before {
    content: "\e977";
}
.jla-icon-truck-filled:before {
    content: "\e978";
}
.jla-icon-unlock-filled:before {
    content: "\e979";
}
.jla-icon-user-check-filled:before {
    content: "\e97a";
}
.jla-icon-user-circle-filled:before {
    content: "\e97b";
}
.jla-icon-user-clock-filled:before {
    content: "\e97c";
}
.jla-icon-user-filled:before {
    content: "\e97d";
}
.jla-icon-user-group-filled:before {
    content: "\e97e";
}
.jla-icon-user-plus-filled:before {
    content: "\e97f";
}
.jla-icon-user-x-filled:before {
    content: "\e980";
}
.jla-icon-wallet-filled:before {
    content: "\e981";
}
.jla-icon-wifi-filled:before {
    content: "\e982";
}
.jla-icon-wrench-screwdriver-filled:before {
    content: "\e983";
}
.jla-icon-x-bold-filled:before {
    content: "\e984";
}
.jla-icon-x-circle-filled:before {
    content: "\e985";
}
.jla-icon-x-mark-filled:before {
    content: "\e986";
}
.jla-icon-adjustments-horizontal:before {
    content: "\e987";
}
.jla-icon-adjustments-vertical:before {
    content: "\e988";
}
.jla-icon-archive:before {
    content: "\e989";
}
.jla-icon-arrow-bar-bottom:before {
    content: "\e98a";
}
.jla-icon-arrow-bar-top:before {
    content: "\e98b";
}
.jla-icon-arrow-down-circle:before {
    content: "\e98c";
}
.jla-icon-arrow-down-on-square:before {
    content: "\e98d";
}
.jla-icon-arrow-down:before {
    content: "\e98e";
}
.jla-icon-arrow-left-circle:before {
    content: "\e98f";
}
.jla-icon-arrow-left-down:before {
    content: "\e990";
}
.jla-icon-arrow-left-on-rectangle:before {
    content: "\e991";
}
.jla-icon-arrow-left-top:before {
    content: "\e992";
}
.jla-icon-arrow-left:before {
    content: "\e993";
}
.jla-icon-arrow-path:before {
    content: "\e994";
}
.jla-icon-arrow-right-circle:before {
    content: "\e995";
}
.jla-icon-arrow-right-down:before {
    content: "\e996";
}
.jla-icon-arrow-right-on-rectangle:before {
    content: "\e997";
}
.jla-icon-arrow-right-top:before {
    content: "\e998";
}
.jla-icon-arrow-right:before {
    content: "\e999";
}
.jla-icon-arrow-top-right-on-square:before {
    content: "\e99a";
}
.jla-icon-arrow-up-circle:before {
    content: "\e99b";
}
.jla-icon-arrow-up-on-square:before {
    content: "\e99c";
}
.jla-icon-arrow-up:before {
    content: "\e99d";
}
.jla-icon-arrows-right-left:before {
    content: "\e99e";
}
.jla-icon-bank:before {
    content: "\e99f";
}
.jla-icon-bars-3:before {
    content: "\e9a0";
}
.jla-icon-bell:before {
    content: "\e9a1";
}
.jla-icon-bills:before {
    content: "\e9a2";
}
.jla-icon-bolt:before {
    content: "\e9a3";
}
.jla-icon-briefcase:before {
    content: "\e9a4";
}
.jla-icon-bulk:before {
    content: "\e9a5";
}
.jla-icon-calendar:before {
    content: "\e9a6";
}
.jla-icon-camera:before {
    content: "\e9a7";
}
.jla-icon-cart:before {
    content: "\e9a8";
}
.jla-icon-chart-pie:before {
    content: "\e9a9";
}
.jla-icon-chat-bubble:before {
    content: "\e9aa";
}
.jla-icon-check-bold:before {
    content: "\e9ab";
}
.jla-icon-check-circle:before {
    content: "\e9ac";
}
.jla-icon-check:before {
    content: "\e9ad";
}
.jla-icon-chevron-down:before {
    content: "\e9ae";
}
.jla-icon-chevron-left:before {
    content: "\e9af";
}
.jla-icon-chevron-right:before {
    content: "\e9b0";
}
.jla-icon-chevron-up-down:before {
    content: "\e9b1";
}
.jla-icon-chevron-up:before {
    content: "\e9b2";
}
.jla-icon-clock:before {
    content: "\e9b3";
}
.jla-icon-cloud:before {
    content: "\e9b4";
}
.jla-icon-credit-card:before {
    content: "\e9b5";
}
.jla-icon-device-computer:before {
    content: "\e9b6";
}
.jla-icon-device-phone-mobile:before {
    content: "\e9b7";
}
.jla-icon-document-plus:before {
    content: "\e9b8";
}
.jla-icon-document:before {
    content: "\e9b9";
}
.jla-icon-double-check-circle:before {
    content: "\e9ba";
}
.jla-icon-ellipsis-horizontal-circle:before {
    content: "\e9bb";
}
.jla-icon-ellipsis-horizontal:before {
    content: "\e9bc";
}
.jla-icon-ellipsis-vertical:before {
    content: "\e9bd";
}
.jla-icon-envelope:before {
    content: "\e9be";
}
.jla-icon-exclamation-circle:before {
    content: "\e9bf";
}
.jla-icon-exclamation-triangle:before {
    content: "\e9c0";
}
.jla-icon-eye-off:before {
    content: "\e9c1";
}
.jla-icon-eye:before {
    content: "\e9c2";
}
.jla-icon-fingerprint:before {
    content: "\e9c3";
}
.jla-icon-flag:before {
    content: "\e9c4";
}
.jla-icon-folder:before {
    content: "\e9c5";
}
.jla-icon-forbidden:before {
    content: "\e9c6";
}
.jla-icon-gear:before {
    content: "\e9c7";
}
.jla-icon-gift:before {
    content: "\e9c8";
}
.jla-icon-globe-europe-africa:before {
    content: "\e9c9";
}
.jla-icon-globe:before {
    content: "\e9ca";
}
.jla-icon-handshake:before {
    content: "\e9cb";
}
.jla-icon-heart:before {
    content: "\e9cc";
}
.jla-icon-home:before {
    content: "\e9cd";
}
.jla-icon-user:before {
    content: "\e9ce";
}
.jla-icon-identification:before {
    content: "\e9cf";
}
.jla-icon-inbox:before {
    content: "\e9d0";
}
.jla-icon-information-circle:before {
    content: "\e9d1";
}
.jla-icon-julaya-circle:before {
    content: "\e9d2";
}
.jla-icon-key:before {
    content: "\e9d3";
}
.jla-icon-kiosk:before {
    content: "\e9d4";
}
.jla-icon-link:before {
    content: "\e9d5";
}
.jla-icon-list-bullet:before {
    content: "\e9d6";
}
.jla-icon-lock:before {
    content: "\e9d7";
}
.jla-icon-logs:before {
    content: "\e9d8";
}
.jla-icon-magnifying-glass:before {
    content: "\e9d9";
}
.jla-icon-map-pin:before {
    content: "\e9da";
}
.jla-icon-map:before {
    content: "\e9db";
}
.jla-icon-minus-circle:before {
    content: "\e9dc";
}
.jla-icon-minus:before {
    content: "\e9dd";
}
.jla-icon-office:before {
    content: "\e9de";
}
.jla-icon-passport:before {
    content: "\e9df";
}
.jla-icon-pencil-square:before {
    content: "\e9e0";
}
.jla-icon-pencil:before {
    content: "\e9e1";
}
.jla-icon-phone-plus-circle:before {
    content: "\e9e2";
}
.jla-icon-phone:before {
    content: "\e9e3";
}
.jla-icon-photo:before {
    content: "\e9e4";
}
.jla-icon-plus-circle:before {
    content: "\e9e5";
}
.jla-icon-plus:before {
    content: "\e9e6";
}
.jla-icon-power:before {
    content: "\e9e7";
}
.jla-icon-printer:before {
    content: "\e9e8";
}
.jla-icon-qr-code:before {
    content: "\e9e9";
}
.jla-icon-question-mark-circle:before {
    content: "\e9ea";
}
.jla-icon-receipt-accepted:before {
    content: "\e9eb";
}
.jla-icon-receipt-clock:before {
    content: "\e9ec";
}
.jla-icon-receipt-percent:before {
    content: "\e9ed";
}
.jla-icon-receipt-refund:before {
    content: "\e9ee";
}
.jla-icon-receipt-refused:before {
    content: "\e9ef";
}
.jla-icon-refresh:before {
    content: "\e9f0";
}
.jla-icon-retry:before {
    content: "\e9f1";
}
.jla-icon-scale:before {
    content: "\e9f2";
}
.jla-icon-send:before {
    content: "\e9f3";
}
.jla-icon-share:before {
    content: "\e9f4";
}
.jla-icon-shield-check:before {
    content: "\e9f5";
}
.jla-icon-sparkles:before {
    content: "\e9f6";
}
.jla-icon-square-2-stack:before {
    content: "\e9f7";
}
.jla-icon-square-check:before {
    content: "\e9f8";
}
.jla-icon-squares:before {
    content: "\e9f9";
}
.jla-icon-star:before {
    content: "\e9fa";
}
.jla-icon-table-cell:before {
    content: "\e9fb";
}
.jla-icon-task:before {
    content: "\e9fc";
}
.jla-icon-thrash:before {
    content: "\e9fd";
}
.jla-icon-trending-down:before {
    content: "\e9fe";
}
.jla-icon-trending-up:before {
    content: "\e9ff";
}
.jla-icon-truck:before {
    content: "\ea00";
}
.jla-icon-unlock:before {
    content: "\ea01";
}
.jla-icon-user-check:before {
    content: "\ea02";
}
.jla-icon-user-circle:before {
    content: "\ea03";
}
.jla-icon-user-clock:before {
    content: "\ea04";
}
.jla-icon-user-group:before {
    content: "\ea05";
}
.jla-icon-user-plus:before {
    content: "\ea06";
}
.jla-icon-user-x:before {
    content: "\ea07";
}
.jla-icon-wallet:before {
    content: "\ea08";
}
.jla-icon-wifi:before {
    content: "\ea09";
}
.jla-icon-wrench-screwdriver:before {
    content: "\ea0a";
}
.jla-icon-x-bold:before {
    content: "\ea0b";
}
.jla-icon-x-circle:before {
    content: "\ea0c";
}
.jla-icon-x-mark:before {
    content: "\ea0d";
}
.jla-icon-thumb-down:before {
    content: "\ea10";
}
.jla-icon-thumb-down-filled:before {
    content: "\ea11";
}
.jla-icon-thumb-up:before {
    content: "\ea12";
}
.jla-icon-thumb-up-filled:before {
    content: "\ea13";
}
.jla-icon-cursor-arrow-rays:before {
    content: "\ea14";
}
.jla-icon-cursor-arrow-rays-filled:before {
    content: "\ea15";
}
.jla-icon-tag:before {
    content: "\ea17";
}
.jla-icon-tag-filled:before {
    content: "\ea16";
}
.jla-icon-queue-list:before {
    content: "\ea18";
}
.jla-icon-queue-list-filled:before {
    content: "\ea19";
}
.jla-icon-placeholder-filled:before {
    content: "\ea0f";
}
.jla-icon-placeholder:before {
    content: "\ea0e";
}
.jla-icon-bulb:before {
    content: "\ea1a";
}
.jla-icon-bulb-filled:before {
    content: "\ea1b";
}
.jla-icon-hashtag:before {
    content: "\ea1c";
}
.jla-icon-hashtag-filled:before {
    content: "\ea1d";
}
.jla-icon-get-paid-filled:before {
    content: "\ea1e";
}
.jla-icon-get-paid:before {
    content: "\ea1f";
}
.jla-icon-calculator-filled:before {
    content: "\ea20";
}
.jla-icon-calculator:before {
    content: "\ea21";
}
.jla-icon-water:before {
    content: "\ea23";
}
.jla-icon-water-filled:before {
    content: "\ea22";
}
.jla-icon-academic:before {
    content: "\ea24";
}
.jla-icon-academic-filled:before {
    content: "\ea25";
}
.jla-icon-rocket:before {
    content: "\ea26";
}
.jla-icon-rocket-filled:before {
    content: "\ea27";
}
.jla-icon-gas:before {
    content: "\ea28";
}
.jla-icon-gas-filled:before {
    content: "\ea29";
}
.jla-icon-health-care:before {
    content: "\ea2a";
}
.jla-icon-health-care-filled:before {
    content: "\ea2b";
}
.jla-icon-agriculture:before {
    content: "\ea2c";
}
.jla-icon-agriculture-filled:before {
    content: "\ea2d";
}
.jla-icon-leaf:before {
    content: "\ea2e";
}
.jla-icon-leaf-filled:before {
    content: "\ea2f";
}
.jla-icon-mode:before {
    content: "\ea30";
}
.jla-icon-mode-filled:before {
    content: "\ea31";
}
.jla-icon-marketing:before {
    content: "\ea32";
}
.jla-icon-marketing-filled:before {
    content: "\ea33";
}
.jla-icon-palette:before {
    content: "\ea34";
}
.jla-icon-palette-filled:before {
    content: "\ea35";
}
.jla-icon-food:before {
    content: "\ea36";
}
.jla-icon-food-filled:before {
    content: "\ea37";
}
.jla-icon-tree:before {
    content: "\ea38";
}
.jla-icon-tree-filled:before {
    content: "\ea39";
}
.jla-icon-newspaper:before {
    content: "\ea3a";
}
.jla-icon-newspaper-filled:before {
    content: "\ea3b";
}
.jla-icon-mining:before {
    content: "\ea3c";
}
.jla-icon-mining-filled:before {
    content: "\ea3d";
}
.jla-icon-factory:before {
    content: "\ea3e";
}
.jla-icon-mining-filled1:before {
    content: "\ea3f";
}
.jla-icon-desk-bell:before {
    content: "\ea40";
}
.jla-icon-desk-bell-filled:before {
    content: "\ea41";
}
.jla-icon-embassy:before {
    content: "\ea42";
}
.jla-icon-embassy-filled:before {
    content: "\ea43";
}
.jla-icon-biotechnology:before {
    content: "\ea44";
}
.jla-icon-biotechnology-filled:before {
    content: "\ea45";
}
.jla-icon-boxes:before {
    content: "\ea46";
}
.jla-icon-embassy-filled1:before {
    content: "\ea47";
}
.jla-icon-umbrella:before {
    content: "\ea48";
}
.jla-icon-umbrella-filled:before {
    content: "\ea49";
}
.jla-icon-cube:before {
    content: "\ea4a";
}
.jla-icon-cube-filled:before {
    content: "\ea4b";
}
.jla-icon-bus:before {
    content: "\ea4c";
}
.jla-icon-bus-filled:before {
    content: "\ea4d";
}
.jla-icon-electronics-filled:before {
    content: "\ea4e";
}
.jla-icon-electronics:before {
    content: "\ea4f";
}
.jla-icon-user-admin:before {
    content: "\ea50";
}
.jla-icon-user-admin-filled:before {
    content: "\ea51";
}
