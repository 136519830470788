@import "src/shared/styles/colors.scss";

.security-settings-tab {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-contents {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .left-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50%;
    }

    .right-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50%;
    }

    .twofa-body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .text-part {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
}
