// Design System Colors Palete
// See more :
// https://www.figma.com/file/LIlmv20CsaWSOaHziFu5Qb/Design-System-%2F-Desktop?type=design&node-id=229%3A1531&mode=design&t=Go3kI9j1VbuwLvIW-1

// Neutral Palete
$neutral-50: #fafafa;
$neutral-100: #f5f5f5;
$neutral-200: #e6e6e6;
$neutral-300: #d4d4d4;
$neutral-400: #a3a3a3;
$neutral-500: #737373;
$neutral-600: #525252;
$neutral-700: #404040;
$neutral-800: #333333;
$neutral-900: #292929;

// Neutral Colors
.color-neutral-50 {
    color: $neutral-50;
}
.color-neutral-100 {
    color: $neutral-100;
}
.color-neutral-200 {
    color: $neutral-200;
}
.color-neutral-300 {
    color: $neutral-300;
}
.color-neutral-400 {
    color: $neutral-400;
}
.color-neutral-500 {
    color: $neutral-500;
}
.color-neutral-600 {
    color: $neutral-600;
}
.color-neutral-700 {
    color: $neutral-700;
}
.color-neutral-800 {
    color: $neutral-800;
}
.color-neutral-900 {
    color: $neutral-900;
}

// Neutral Backgrounds
.background-neutral-50 {
    background-color: $neutral-50;
}
.background-neutral-100 {
    background-color: $neutral-100;
}
.background-neutral-200 {
    background-color: $neutral-200;
}
.background-neutral-300 {
    background-color: $neutral-300;
}
.background-neutral-400 {
    background-color: $neutral-400;
}
.background-neutral-500 {
    background-color: $neutral-500;
}
.background-neutral-600 {
    background-color: $neutral-600;
}
.background-neutral-700 {
    background-color: $neutral-700;
}
.background-neutral-800 {
    background-color: $neutral-800;
}
.background-neutral-900 {
    background-color: $neutral-900;
}

// Lilas Palete
$lilas-50: #f3f2ff;
$lilas-100: #edecff;
$lilas-200: #e7e7ff;
$lilas-300: #e0e0ff;
$lilas-400: #d9d9ff;
$lilas-500: #d1d0ff;
$lilas-600: #c0beff;
$lilas-700: #a5a3ff;
$lilas-800: #7674e5;
$lilas-900: #5856c6;

// Lilas Colors
.color-lilas-50 {
    color: $lilas-50;
}
.color-lilas-100 {
    color: $lilas-100;
}
.color-lilas-200 {
    color: $lilas-200;
}
.color-lilas-300 {
    color: $lilas-300;
}
.color-lilas-400 {
    color: $lilas-400;
}
.color-lilas-500 {
    color: $lilas-500;
}
.color-lilas-600 {
    color: $lilas-600;
}
.color-lilas-700 {
    color: $lilas-700;
}
.color-lilas-800 {
    color: $lilas-800;
}
.color-lilas-900 {
    color: $lilas-900;
}

// Lilas Backgrounds
.background-lilas-50 {
    background-color: $lilas-50;
}
.background-lilas-100 {
    background-color: $lilas-100;
}
.background-lilas-200 {
    background-color: $lilas-200;
}
.background-lilas-300 {
    background-color: $lilas-300;
}
.background-lilas-400 {
    background-color: $lilas-400;
}
.background-lilas-500 {
    background-color: $lilas-500;
}
.background-lilas-600 {
    background-color: $lilas-600;
}
.background-lilas-700 {
    background-color: $lilas-700;
}
.background-lilas-800 {
    background-color: $lilas-800;
}
.background-lilas-900 {
    background-color: $lilas-900;
}

// Blue Palete
$blue-50: #eef5ff;
$blue-100: #e7f1ff;
$blue-200: #ddebff;
$blue-300: #d3e5ff;
$blue-400: #cde2ff;
$blue-500: #cbe0ff;
$blue-600: #bcd7ff;
$blue-700: #a9ccff;
$blue-800: #99c2ff;
$blue-900: #7eb2fe;

// Blue Colors
.color-blue-50 {
    color: $blue-50;
}
.color-blue-100 {
    color: $blue-100;
}
.color-blue-200 {
    color: $blue-200;
}
.color-blue-300 {
    color: $blue-300;
}
.color-blue-400 {
    color: $blue-400;
}
.color-blue-500 {
    color: $blue-500;
}
.color-blue-600 {
    color: $blue-600;
}
.color-blue-700 {
    color: $blue-700;
}
.color-blue-800 {
    color: $blue-800;
}
.color-blue-900 {
    color: $blue-900;
}

// Blue Backgrounds
.background-blue-50 {
    background-color: $blue-50;
}
.background-blue-100 {
    background-color: $blue-100;
}
.background-blue-200 {
    background-color: $blue-200;
}
.background-blue-300 {
    background-color: $blue-300;
}
.background-blue-400 {
    background-color: $blue-400;
}
.background-blue-500 {
    background-color: $blue-500;
}
.background-blue-600 {
    background-color: $blue-600;
}
.background-blue-700 {
    background-color: $blue-700;
}
.background-blue-800 {
    background-color: $blue-800;
}
.background-blue-900 {
    background-color: $blue-900;
}

// Pink Palete
$pink-50: #fff4fe;
$pink-100: #ffeefe;
$pink-200: #ffe9fd;
$pink-300: #ffe4fd;
$pink-400: #ffe1fd;
$pink-500: #ffdcfc;
$pink-600: #ffcafa;
$pink-700: #f9b7f3;
$pink-800: #f8a6f1;
$pink-900: #ec93e4;

// Pink Colors
.color-pink-50 {
    color: $pink-50;
}
.color-pink-100 {
    color: $pink-100;
}
.color-pink-200 {
    color: $pink-200;
}
.color-pink-300 {
    color: $pink-300;
}
.color-pink-400 {
    color: $pink-400;
}
.color-pink-500 {
    color: $pink-500;
}
.color-pink-600 {
    color: $pink-600;
}
.color-pink-700 {
    color: $pink-700;
}
.color-pink-800 {
    color: $pink-800;
}
.color-pink-900 {
    color: $pink-900;
}

// Pink Backgrounds
.background-pink-50 {
    background-color: $pink-50;
}
.background-pink-100 {
    background-color: $pink-100;
}
.background-pink-200 {
    background-color: $pink-200;
}
.background-pink-300 {
    background-color: $pink-300;
}
.background-pink-400 {
    background-color: $pink-400;
}
.background-pink-500 {
    background-color: $pink-500;
}
.background-pink-600 {
    background-color: $pink-600;
}
.background-pink-700 {
    background-color: $pink-700;
}
.background-pink-800 {
    background-color: $pink-800;
}
.background-pink-900 {
    background-color: $pink-900;
}

// Success Palete
$success-50: #ebfaf1;
$success-100: #baedcd;
$success-200: #9ae4b5;
$success-300: #6ad792;
$success-400: #4dd17d;
$success-500: #21c45d;
$success-600: #1fb255;
$success-700: #188c42;
$success-800: #136c34;
$success-900: #0e5327;

// Success Colors
.color-success-50 {
    color: $success-50;
}
.color-success-100 {
    color: $success-100;
}
.color-success-200 {
    color: $success-200;
}
.color-success-300 {
    color: $success-300;
}
.color-success-400 {
    color: $success-400;
}
.color-success-500 {
    color: $success-500;
}
.color-success-600 {
    color: $success-600;
}
.color-success-700 {
    color: $success-700;
}
.color-success-800 {
    color: $success-800;
}
.color-success-900 {
    color: $success-900;
}

// Success Backgrounds
.background-success-50 {
    background-color: $success-50;
}
.background-success-100 {
    background-color: $success-100;
}
.background-success-200 {
    background-color: $success-200;
}
.background-success-300 {
    background-color: $success-300;
}
.background-success-400 {
    background-color: $success-400;
}
.background-success-500 {
    background-color: $success-500;
}
.background-success-600 {
    background-color: $success-600;
}
.background-success-700 {
    background-color: $success-700;
}
.background-success-800 {
    background-color: $success-800;
}
.background-success-900 {
    background-color: $success-900;
}

// Warning Palete
$warning-50: #fff3e4;
$warning-100: #ffe7cb;
$warning-200: #ffdfbb;
$warning-300: #ffd7af;
$warning-400: #f6ca9e;
$warning-500: #f3b16f;
$warning-600: #dc9248;
$warning-700: #ca7928;
$warning-800: #ac5c0b;
$warning-900: #874300;

// Warning Colors
.color-warning-50 {
    color: $warning-50;
}
.color-warning-100 {
    color: $warning-100;
}
.color-warning-200 {
    color: $warning-200;
}
.color-warning-300 {
    color: $warning-300;
}
.color-warning-400 {
    color: $warning-400;
}
.color-warning-500 {
    color: $warning-500;
}
.color-warning-600 {
    color: $warning-600;
}
.color-warning-700 {
    color: $warning-700;
}
.color-warning-800 {
    color: $warning-800;
}
.color-warning-900 {
    color: $warning-900;
}

// Warning Backgrounds
.background-warning-50 {
    background-color: $warning-50;
}
.background-warning-100 {
    background-color: $warning-100;
}
.background-warning-200 {
    background-color: $warning-200;
}
.background-warning-300 {
    background-color: $warning-300;
}
.background-warning-400 {
    background-color: $warning-400;
}
.background-warning-500 {
    background-color: $warning-500;
}
.background-warning-600 {
    background-color: $warning-600;
}
.background-warning-700 {
    background-color: $warning-700;
}
.background-warning-800 {
    background-color: $warning-800;
}
.background-warning-900 {
    background-color: $warning-900;
}

// Error Palete
$error-50: #fdedec;
$error-100: #fac4c2;
$error-200: #f8a8a5;
$error-300: #f47c76;
$error-400: #f2665f;
$error-500: #ef3d34;
$error-600: #d93630;
$error-700: #aa2d27;
$error-800: #85221e;
$error-900: #641917;

// Error Colors
.color-error-50 {
    color: $error-50;
}
.color-error-100 {
    color: $error-100;
}
.color-error-200 {
    color: $error-200;
}
.color-error-300 {
    color: $error-300;
}
.color-error-400 {
    color: $error-400;
}
.color-error-500 {
    color: $error-500;
}
.color-error-600 {
    color: $error-600;
}
.color-error-700 {
    color: $error-700;
}
.color-error-800 {
    color: $error-800;
}
.color-error-900 {
    color: $error-900;
}

// Error Backgrounds
.background-error-50 {
    background-color: $error-50;
}
.background-error-100 {
    background-color: $error-100;
}
.background-error-200 {
    background-color: $error-200;
}
.background-error-300 {
    background-color: $error-300;
}
.background-error-400 {
    background-color: $error-400;
}
.background-error-500 {
    background-color: $error-500;
}
.background-error-600 {
    background-color: $error-600;
}
.background-error-700 {
    background-color: $error-700;
}
.background-error-800 {
    background-color: $error-800;
}
.background-error-900 {
    background-color: $error-900;
}

// Pink Gradiants Palete
$gradiant-pink-horizontal-1: linear-gradient(90deg, #d1d0ff 0%, #ffdcfc 100%);
$gradiant-pink-horizontal-2: linear-gradient(90deg, #ffdcfc 0%, #d1d0ff 100%);
$gradiant-pink-vertical-1: linear-gradient(180deg, #d1d0ff 0%, #ffdcfc 100%);
$gradiant-pink-vertical-2: linear-gradient(0deg, #d1d0ff 0%, #ffdcfc 100%);

//Pink Gradiants Colors
.color-gradiant-pink-horizontal-1 {
    color: $gradiant-pink-horizontal-1;
}
.color-gradiant-pink-horizontal-2 {
    color: $gradiant-pink-horizontal-2;
}
.color-gradiant-pink-vertical-1 {
    color: $gradiant-pink-vertical-1;
}
.color-gradiant-pink-vertical-2 {
    color: $gradiant-pink-vertical-2;
}

// Pink Gradiants Backgrounds
.background-gradiant-pink-horizontal-1 {
    background: $gradiant-pink-horizontal-1;
}
.background-gradiant-pink-horizontal-2 {
    background: $gradiant-pink-horizontal-2;
}
.background-gradiant-pink-vertical-1 {
    background: $gradiant-pink-vertical-1;
}
.background-gradiant-pink-vertical-2 {
    background: $gradiant-pink-vertical-2;
}

// Orange Gradiants Palete
$gradiant-orange-horizontal-1: linear-gradient(90deg, #d1d0ff 0%, #ffdfbb 100%);
$gradiant-orange-horizontal-2: linear-gradient(90deg, #ffdfbb 0%, #d1d0ff 100%);
$gradiant-orange-vertical-1: linear-gradient(180deg, #d1d0ff 0%, #ffdfbb 100%);
$gradiant-orange-vertical-2: linear-gradient(180deg, #ffdfbb 0%, #d1d0ff 100%);

// Orange Gradiants Colors
.color-gradiant-orange-horizontal-1 {
    color: $gradiant-orange-horizontal-1;
}
.color-gradiant-orange-horizontal-2 {
    color: $gradiant-orange-horizontal-2;
}
.color-gradiant-orange-vertical-1 {
    color: $gradiant-orange-vertical-1;
}
.color-gradiant-orange-vertical-2 {
    color: $gradiant-orange-vertical-2;
}

// Orange Gradiants Backgrounds
.background-gradiant-orange-horizontal-1 {
    background: $gradiant-orange-horizontal-1;
}
.background-gradiant-orange-horizontal-2 {
    background: $gradiant-orange-horizontal-2;
}
.background-gradiant-orange-vertical-1 {
    background: $gradiant-orange-vertical-1;
}
.background-gradiant-orange-vertical-2 {
    background: $gradiant-orange-vertical-2;
}

// Primary Palete

$primary: $neutral-500;
$black: $neutral-900;
$white: white;
$lilas: $lilas-900;
$blue: $blue-900;
$pink: $pink-900;
$success: $success-500;
$warning: $warning-500;
$error: $error-500;

.primary-color {
    color: $primary;
}
.background-primary {
    background-color: $primary;
}
.color-black {
    color: $black;
}
.background-black {
    background-color: $black;
}
.color-white {
    color: $white;
}
.background-white {
    background-color: $white;
}
.color-lilas {
    color: $lilas;
}
.background-lilas {
    background-color: $lilas;
}
.color-blue {
    color: $blue;
}
.background-blue {
    background-color: $blue;
}
.color-pink {
    color: $pink;
}
.background-pink {
    background-color: $pink;
}
.color-success {
    color: $success;
}
.background-success {
    background-color: $success;
}
.color-warning {
    color: $warning;
}
.background-warning {
    background-color: $warning;
}
.color-error {
    color: $error;
}
.background-error {
    background-color: $error;
}
.transparent {
    background-color: transparent;
}
