@import "src/shared/styles/colors.scss";

.export-templates-panel-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .export-header-container {
        display: flex;
        justify-content: space-between;

        .left-content {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .search-input {
                .input {
                    background-color: $neutral-50;
                }
            }
            .select {
                min-width: 300px;
                .select-header {
                    background-color: $neutral-50;
                }
            }
            .filters-select {
                width: 340px;

                .body-content {
                    gap: 15px;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                .footer-content {
                    display: flex;
                    gap: 12px;
                    width: 100%;

                    .footer-btn {
                        flex: 1 1 0;
                    }
                }

                .export-filter-container {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;

                    .select-container {
                        .select {
                            .select-header {
                                background-color: $neutral-100;
                            }
                        }
                    }

                    .content-body {
                        div {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                        }
                    }
                }
            }
        }

        .tooltip-container .tooltip {
            width: 100%;
        }
    }

    .export-template-table {
        display: flex;
        flex-direction: column;

        .selectable-table-container {
            margin-bottom: 45px;
        }

        .tooltip-container .tooltip {
            width: 100%;
        }

        .first-col {
            display: flex;
            flex-direction: row;
            gap: 15px;
            align-items: center;
            height: 100%;
        }

        .last-item-button {
            display: flex;
            justify-content: flex-end;

            .avatar-container {
                cursor: pointer;
                justify-content: center;
            }
        }

        .select-header {
            padding: 0 8px;
            height: 38px;
        }

        .export-templates-select-actions {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .export-templates-action-item {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;
                border-radius: 5px;
                padding: 4px;
                text-align: left;
                cursor: pointer;
                font-weight: bold;

                &:hover {
                    background-color: $neutral-100;
                }

                i {
                    color: $neutral-500;
                }
            }
        }

        .select-container {
            display: flex;
            .select {
                .select-header {
                    width: 24px;

                    .jla-icon-chevron-up {
                        display: none;
                    }
                }

                .select-content {
                    width: 275px;
                    right: 0;
                }
            }
        }
    }

    .export-success-content-modal {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: center;
    }
}
