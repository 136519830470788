@import "src/shared/styles/colors.scss";

.create-export-template-drawer-body-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-type-selection-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .form-type-selection-item {
            display: flex;
            flex-direction: row;
            border-radius: 10px;
            justify-content: space-between;
            transition: 0.2s;
            padding: 8px 16px;
            cursor: pointer;
            background-color: $neutral-100;

            &.selected {
                background-color: $lilas-50;
                transition: 0.2s;
            }

            .left-content {
                display: flex;
                flex-direction: row;
                gap: 16px;
                align-items: center;
            }

            .form-type-selection-texts {
                display: flex;
                flex-direction: column;

                span {
                    &:first-child {
                        font-weight: bold;
                    }
                    &:last-child {
                        color: $neutral-500;
                    }
                }
            }
        }

        li {
            padding: 0;
            margin-bottom: 16px;
        }
    }

    .create-export-template-regular {
        .create-export-template-form-container {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .required-hint {
                display: flex;
                flex-direction: row;
                justify-content: end;
                gap: 3px;
            }

            .date-range-label {
                display: flex;
                flex-direction: column;

                span {
                    &:first-child {
                        font-weight: bold;
                    }
                    &:last-child {
                        color: $neutral-500;
                    }
                }
            }
            .select-container {
                .select {
                    .select-header {
                        width: calc(100% - 32px);
                    }
                }
            }
        }
    }
}
