@import "src/shared/styles/colors.scss";

.disposal-proof-container {
    .disposal-proof-info {
        background: $lilas-100;
        cursor: pointer;
        border-radius: 10px;
        padding: 16px;

        .content-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .content-item {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;

            .proof-info-item {
                display: flex;
                flex-direction: row;
                gap: 8px;
                img {
                    width: 32px;
                }

                .text-part {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .alert-down-icon {
            &.opened {
                transform: rotate(180deg);
            }
        }
    }
}

.disposal-proof-helper-container {
    margin-top: 16px;
    margin-bottom: 16px;

    .disposal-proof-helper-accordion {
        display: flex;
        gap: 8px;
        margin-top: 16px;
        overflow: auto;

        .disposal-proof-helper-item {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 356px;
            max-width: 356px;
            height: 350px;
            max-height: 350px;
            border-radius: 10px;
            padding: 24px;

            &.color-green {
                background-color: $success-50;
            }

            &.color-red {
                background-color: $error-50;
            }
        }

        .disposal-proof-helper-title {
            display: flex;
            padding-bottom: 16px;
            gap: 8px;
            width: 100%;
            align-items: center;
        }

        .disposal-proof-helper-image {
            object-fit: contain;
            width: 306px;
            max-width: 306px;
            height: 238px;
            max-height: 238px;
        }
    }
}
