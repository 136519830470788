@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";
@import "src/shared/styles/general.scss";

.details-beneficiary-drawer-body-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: space-between;

    .beneficiary-informations-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
    }

    .beneficiary-informations-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
    }

    .operation-details-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .content-item {
            display: flex;
            flex-direction: row;

            @include breakpoint(md) {
                flex-direction: column;
            }

            .status-icon-with-text {
                max-width: 100px;
            }
        }

        .content-item > *:first-child {
            flex: 1;
            text-align: left;
            padding-right: 10px;
            font-weight: bold;
            color: $neutral-500;
        }

        .content-item > *:last-child {
            flex: 1;
            font-weight: bold;
            text-align: left;
        }

        .account-item {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid $neutral-200;

            &.error {
                border: 1px solid $error;
            }

            .account-item-info {
                display: flex;
                flex-direction: column;
            }

            .account-item-info > *:first-child {
                font-weight: bold;
            }
        }

        .beneficiary-service-select {
            width: 100%;
            box-sizing: border-box;
            background-color: $neutral-50;
        }
    }

    .beneficiary-informations-content {
        .form-part {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .inline-inputs {
            display: flex;
            flex-direction: row;
            gap: 25px;

            .input-container {
                width: 100%;
            }
            @include breakpoint(sm) {
                flex-direction: column;
                gap: 0;
            }
        }

        .no-account-added {
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 10px;
        }

        .button-add-account {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            cursor: pointer;
        }

        .buttons-container {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        .account-list {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .account-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                background-color: $white;
                border-radius: 10px;

                .account-item-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    .text-part {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .account-item-actions {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    button {
                        width: 40px !important;
                    }
                }
            }
        }
        .add-account-button {
            button {
                width: 100%;
            }
        }
    }

    .button-send {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .button-part {
        display: flex;
        flex-direction: row;
        border-top: 1px solid $neutral-200;
        padding: 16px;
        gap: 16px;
        justify-content: flex-end;
    }

    .add-account-form {
        background-color: $neutral-100;
        border-radius: 10px;

        .form-part {
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .buttons-part {
            display: flex;
            flex-direction: row;
            gap: 16px;
            border-top: 1px solid $neutral-200;
            padding: 16px;

            .button-container {
                width: 100%;
            }
        }

        .select-container .select .select-header {
            background-color: $white;
        }

        .select-container .select .select-header.show {
            background-color: $white;
        }

        .input-container .input {
            background-color: $white;
        }

        .input-phone-container .input {
            background-color: $white;
        }
    }

    .delete-section {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .section-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .content-section {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 0;
        }

        .button-part {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 16px;
            border-top: 0;
            padding: 0;

            .button-container {
                width: 100%;
            }
        }
    }

    .benef-fields-error-msg {
        display: flex;
        text-align: center;
        margin-bottom: 16px;
    }
}
