@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.timetable-container {
    background-color: $white;
    border: 1px solid $primary;
    border-radius: 10px;
    margin-bottom: 16px;

    .timetable-header {
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @include breakpoint(md) {
            padding: 16px 8px;
        }

        .left-part {
            flex-direction: row;
            align-items: center;
            display: flex;
            gap: 8px;
        }
        .payment-button {
            color: $primary;
            cursor: pointer;

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

            @include breakpoint(md) {
                text-align: right;
            }
        }
    }

    .timetable-content {
        border-top: 1px solid $neutral-200;
        display: flex;
        gap: 16px;
        padding: 16px;
        flex-direction: column;

        @include breakpoint(md) {
            padding: 16px 2px;
        }

        .gauge-content {
            display: flex;
            flex-direction: row;
            padding: 16px;
            background-color: $neutral-50;
            border-radius: 10px;
            @include breakpoint(md) {
                flex-direction: column;
            }
            .loan-gauge-section {
                width: 50%;
                text-align: center;

                @include breakpoint(md) {
                    width: 100%;
                    margin-bottom: 16px;
                }

                .loan-gauge-text {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                }
            }

            .loan-rest-to-pay-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 4px;
                border-left: 1px solid $neutral-200;
                padding-left: 16px;
                width: 50%;
                margin-left: 16px;

                @include breakpoint(md) {
                    width: 100%;
                    margin-bottom: 20px;
                    align-items: center;
                    border-left: 0;
                    border-right: 0;
                    border-top: 1px solid $neutral-200;
                    padding: 16px 0;
                    margin: 16px 0;
                }

                .loan-rest-to-pay-interests {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                    color: $primary;

                    @include breakpoint(md) {
                        margin: auto;
                    }
                }
            }
        }

        .payment-history-section {
            .payment-history-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                height: 54px;
                margin-bottom: 8px;
                padding: 0 8px;
                border-radius: 10px;
                background-color: $white;

                .left-content {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;

                    .bills-icon {
                        height: 28px;
                        width: 28px;
                        margin-right: 8px;
                    }
                    .payment-history-amount-date {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        .show-contrat-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;
            margin: 8px 0;
            background-color: $neutral-50;
            margin-bottom: 8px;
            border-radius: 10px;

            .document-icon {
                background-color: $neutral-200;
                color: $neutral-500;
            }
        }
    }

    .fast-repayment-item {
        display: flex;
        flex-direction: row;
        gap: 25px;
        border-radius: 10px;
        padding: 26px;
        margin-bottom: 10px;

        @include breakpoint(md) {
            flex-direction: column;
        }

        &.success {
            background-color: $success-50;
        }

        &.warning {
            background-color: $warning-50;
        }

        &.error {
            background-color: $error-50;
        }

        .gauge-part {
            margin: auto;
            .loan-gauge-text {
                display: flex;
                flex-direction: column;
                text-align: center;
            }
            @include breakpoint(md) {
                text-align: center;
            }
        }

        .text-part {
            display: flex;
            flex-direction: column;
        }
    }
}
