@import "src/shared/styles/colors.scss";

.notifications-drawer {
    .drawer .drawer-body {
        padding: 0;
    }
}

.notifications-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 16px);
    height: 100%;
    padding: 0 8px;

    .notifications-count {
        background: rgba($primary, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.625rem;
        padding: 0.425rem 1.25rem;
    }

    .header-action-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5rem;
        width: 2.5rem;
        padding: 0;
        background: $neutral-50;
        transition: all ease-in 0.1s;

        &:hover {
            cursor: pointer;
            background: $neutral-100;
        }

        .action-icon {
            color: $neutral-500;
            transition: all ease-in 0.1s;
        }

        &.active {
            background: $primary;

            .action-icon {
                color: $white;
            }
        }
    }
}

.notification-drawer-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .notification-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 0 16px;
        margin-bottom: 8px;
    }

    .tabs-primary {
        width: 100%;

        .tab-item {
            width: 50%;
        }
    }

    .notification-list-container {
        flex-grow: 1;
        height: 0;
        margin-bottom: 16px;
    }

    .notification-list {
        max-height: 100%;
        overflow-y: auto;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.notification-card {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 8px;
    background: $white;

    &.new {
        background: rgba($warning, 0.2);
        border-left: 2px solid $warning;
    }

    .notification-card-content {
        width: 100%;
        gap: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .button-container {
            width: 32px;
        }

        .notification-card-content-text {
            display: flex;
            flex-direction: column;
        }

        .close-button {
            align-self: flex-start;
        }
    }

    .notification-icon-wrapper {
        padding: 0.625rem;
        background: rgba($success, 0.2);
        border-radius: 0.625rem;
        align-self: flex-start;
        .notification-icon {
            color: $success;
        }

        &.success {
            background: rgba($success, 0.2);

            .notification-icon {
                color: $success;
            }
        }

        &.warning {
            background: rgba($warning, 0.2);

            .notification-icon {
                color: $warning;
            }
        }

        &.info {
            background: rgba($blue, 0.2);

            .notification-icon {
                color: $blue;
            }
        }

        &.error {
            background: rgba($error, 0.2);

            .notification-icon {
                color: $error;
            }
        }
    }

    &:last-of-type {
        border-bottom: none;
    }

    .close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.625rem;
        padding: 0.675rem;
        transition: 0.3s all;
        background: $neutral-50;

        &:hover {
            cursor: pointer;
            background: $neutral-200;
        }
    }
}
