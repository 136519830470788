@import "src/shared/styles/colors.scss";

.task-to-sign-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $lilas-50;
    border: 1px solid $lilas-200;
    border-radius: 10px;
    padding: 16px;
    cursor: pointer;

    .left-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        .counter {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            border-radius: 10px;
            background-color: $neutral-50;
            position: relative;

            .red-dot {
                width: 10px;
                height: 10px;
                background: $error-500;
                border-radius: 100%;
                position: absolute;
                top: -2px;
                right: -2px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 10px;
                    height: 10px;
                    background-color: rgba(255, 0, 0, 0.5);
                    border-radius: 100%;
                    animation: grow-and-disappear 2.5s infinite linear;
                }
            }
        }
    }
}

@keyframes grow-and-disappear {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
    }
}
