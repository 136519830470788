@import "src/shared/styles/colors.scss";

$colors-map: (
    'primary': (
        'bg': $warning-100,
    ),
    'success': (
        'bg': $success-50,
    ),
    'error': (
        'bg': $error-50,
    ),
    'warning': (
        'bg': $warning-50,
    )
);

.sticky-alert {
    @each $color, $data in $colors-map {
        &--#{$color} {
            background-color: map-get($data, 'bg');
        }
    }

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
}
