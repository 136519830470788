// Design System Borders
// See more :
// https://www.figma.com/file/LIlmv20CsaWSOaHziFu5Qb/Design-System-%2F-Desktop?type=design&node-id=229%3A1531&mode=design&t=Go3kI9j1VbuwLvIW-1

// Border-subtle (N100) - Inner
.border-neutral-100-all {
    box-shadow: 0px 0px 0px 1px #f5f5f5 inset;
}
.border-neutral-100-top {
    box-shadow: 0px 1px 0px 0px #f5f5f5 inset;
}
.border-neutral-100-bottom {
    box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
}
.border-neutral-100-right {
    box-shadow: -1px 0px 0px 0px #f5f5f5 inset;
}
.border-neutral-100-left {
    box-shadow: 1px 0px 0px 0px #f5f5f5 inset;
}
.border-neutral-100-top-left-right {
    box-shadow: 0px 1px 0px 0px #f5f5f5 inset;
    box-shadow: 1px 0px 0px 0px #f5f5f5 inset;
    box-shadow: -1px 0px 0px 0px #f5f5f5 inset;
}
.border-neutral-100-top-left-bottom {
    box-shadow: 0px 1px 0px 0px #f5f5f5 inset;
    box-shadow: 1px 0px 0px 0px #f5f5f5 inset;
    box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
}
.border-neutral-100-bottom-left-right {
    box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
    box-shadow: 1px 0px 0px 0px #f5f5f5 inset;
    box-shadow: -1px 0px 0px 0px #f5f5f5 inset;
}
.border-neutral-100-top-right-bottom {
    box-shadow: 0px 1px 0px 0px #f5f5f5 inset;
    box-shadow: -1px 0px 0px 0px #f5f5f5 inset;
    box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
}
.border-neutral-100-top-bottom {
    box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
    box-shadow: 0px 1px 0px 0px #f5f5f5 inset;
}
.border-neutral-100-left-right {
    box-shadow: 1px 0px 0px 0px #f5f5f5 inset;
    box-shadow: -1px 0px 0px 0px #f5f5f5 inset;
}

// Border-strong (N900) - Inner
.border-neutral-900-all {
    box-shadow: 0px 0px 0px 1px #262626 inset;
}
.border-neutral-900-top {
    box-shadow: 0px 1px 0px 0px #262626 inset;
}
.border-neutral-900-bottom {
    box-shadow: 0px -1px 0px 0px #262626 inset;
}
.border-neutral-900-right {
    box-shadow: -1px 0px 0px 0px #262626 inset;
}
.border-neutral-900-left {
    box-shadow: 1px 0px 0px 0px #262626 inset;
}
.border-neutral-900-top-left-right {
    box-shadow: 0px 1px 0px 0px #262626 inset;
    box-shadow: 1px 0px 0px 0px #262626 inset;
    box-shadow: -1px 0px 0px 0px #262626 inset;
}
.border-neutral-900-top-left-bottom {
    box-shadow: 0px 1px 0px 0px #262626 inset;
    box-shadow: 1px 0px 0px 0px #262626 inset;
    box-shadow: 0px -1px 0px 0px #262626 inset;
}
.border-neutral-900-bottom-left-right {
    box-shadow: 0px -1px 0px 0px #262626 inset;
    box-shadow: 1px 0px 0px 0px #262626 inset;
    box-shadow: -1px 0px 0px 0px #262626 inset;
}
.border-neutral-900-top-right-bottom {
    box-shadow: 0px 1px 0px 0px #262626 inset;
    box-shadow: -1px 0px 0px 0px #262626 inset;
    box-shadow: 0px -1px 0px 0px #262626 inset;
}
.border-neutral-900-top-bottom {
    box-shadow: 0px -1px 0px 0px #262626 inset;
    box-shadow: 0px 1px 0px 0px #262626 inset;
}
.border-neutral-900-left-right {
    box-shadow: 1px 0px 0px 0px #262626 inset;
    box-shadow: -1px 0px 0px 0px #262626 inset;
}

// Focus border
.border-gradiant-Pink {
    border: 2px solid;
    border-image-source: linear-gradient(90deg, $lilas-500 0%, $pink-500 100%);
}
.border-gradiant-Orange {
    border: 2px solid;
    border-image-source: linear-gradient(90deg, $lilas-500 0%, $warning-200 100%);
}
.border-error {
    border: 2px solid $error-500;
}
.border-success {
    border: 2px solid $success-500;
}
.border-warning {
    border: 2px solid $warning-500;
}
.border-blue {
    border: 2px solid $blue-500;
}
.border-lilas {
    border: 2px solid $lilas-700;
}
.border-neutral {
    border: 2px solid $neutral-500;
}
