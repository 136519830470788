@import "src/shared/styles/colors.scss";

.bill-module-container {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: $white;

    .bill-enterprise-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 32px);

        .bill-module-content {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 16px;

            .avatar {
                min-width: 48px;
                min-height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $lilas-50;
                border-radius: 8px;
            }
        }
    }

    .bill-module-content {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 16px;

        .avatar {
            min-width: 48px;
            min-height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $lilas-50;
            border-radius: 8px;
        }
    }

    .bill-module-footer {
        display: flex;

        .bill-tag {
            margin-top: 16px;
            margin-right: 16px;
            display: flex;
            align-items: center;
            gap: 8px;

            .bill-background-primary {
                padding: 8px;
                background-color: $lilas-50;
                border-radius: 10px;
            }
        }
    }

    .bill-description {
        margin-top: 16px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
