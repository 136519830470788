@import "src/shared/styles/colors.scss";

.breadcrumb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;

    .breadcrumb-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        .breadcrumb-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 20px;
            padding: 6px 8px;
            gap: 8px;
            border-radius: 10px;
            transition: 0.5s;

            &:not(.no-link):hover {
                background-color: $neutral-200;
            }

            &.last-item {
                background-color: $lilas-200;
            }

            &:not(.no-link) {
                cursor: pointer;
            }
        }

        .icon-chevron {
            display: flex;
            padding: 8px;
        }
    }
}
