@import "src/shared/styles/colors.scss";

.method-select-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .method-select-title {
        display: flex;
        flex-direction: column;
        gap: 16px;

        border-bottom: 1px solid $neutral-200;
        padding-bottom: 16px;
    }

    .method-select-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $neutral-100;
        border-radius: 10px;
        padding: 16px;
        border: 2px solid transparent;
        transition: 0.2s;


        &:hover {
            cursor: pointer;
        }

        &.selected {
            border: 2px solid $lilas-700;
            transition: 0.2s;
        }

        .method-select-left-part {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .text-part {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .subtext-part {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
    }
}
