@import "src/shared/styles/colors.scss";

.admin-documents-file {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .file-icon-container {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $error-50;
        border-radius: 8px;
    }

    .buttons-container {
        display: flex;
        gap: 8px;

        .icon-button {
            width: 32px;
            height: 32px;
        }
    }
}

.generic-file-uploader {
    display: flex;
    justify-content: center;
    border: 1px solid $lilas-700;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    line-height: 1;

    &.active {
        cursor: pointer;
        transition: all 200ms ease-in-out;

        &:hover {
            background-color: $lilas-50;
        }
    }

    &.disabled {
        cursor: none;
        opacity: 0.5;
        &:hover {
            background-color: none;
        }
    }

    .link {
        color: $primary;
        font-weight: bold;
    }

    .image {
        width: 32px;
        height: 32px;
    }
}

.file-uploader-with-files {
    .section {
        display: flex;
        border-radius: 10px;

        &.variant-grey {
            background-color: $neutral-50;
            padding: 8px 16px;
            width: calc(100% - 32px);
        }
        &.variant-white {
            background-color: $white;
            padding: 8px 16px;
            width: calc(100% - 32px);
        }
        &.variant-transparent {
            background-color: transparent;
            padding: 0px;
            padding-bottom: 4px;
            width: 100%;
        }

        .file-uploader-flex-min-width {
            display: flex;
            min-width: 0;
        }

        .file-uploader-text-overflow {
            flex-grow: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .image {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        border-radius: 0.625rem;
        background-size: cover;
        background-position: center;
    }
}

.file-uploader-drag-n-drop {
    .preview-img {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        align-self: center;
        width: 100%;
        height: 100%;
    }

    .iframe-preview {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
