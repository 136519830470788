@import "src/shared/styles/colors.scss";

.get-paid-drawer-section {
    padding: 16px;
    margin-bottom: 1rem;

    &.disabled {
        opacity: 0.5;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .drawer-divider {
        margin-right: -16px;
        margin-left: -16px;
        width: calc(100% + 32px);
        height: 2px;
        margin-bottom: 1rem;
        margin-top: 1rem;
        background-color: $neutral-100;
    }

    .drawer-input-header {
        width: 100%;
        box-sizing: border-box;
    }

    .drawer-details-edit {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .biller-logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            opacity: 0.5;
            gap: 16px;

            .avatar {
                width: 48px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $lilas-50;
                border-radius: 8px;
            }
        }
    }

    .label-or-description {
        display: flex;
        margin-top: 16px;
        margin-bottom: 16px;
        width: 100%;

        &.centered {
            align-items: center;
        }

        .element {
            flex: 1;
            .avatar {
                width: 48px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $lilas-50;
                border-radius: 8px;
            }
        }
    }
}
