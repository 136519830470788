.table-column-item-container {
    .select-container {
        .select {
            .select-content {
                .content-header {
                    padding: 8px;
                    height: auto;
                    align-items: center;

                    .wallet-header-select-title {
                        padding: 8px;
                    }
                }

                .content-footer {
                    gap: 16px;
                    button {
                        width: 100%;
                    }
                }
            }
            .jla-icon-chevron-up {
                display: none;
            }
        }

        .column-hider-container {
            display: flex;
            flex-direction: column;
            width: 300px;

            .column-hider-title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-weight: bold;
                padding: 8px;
                opacity: 1;
            }

            .column-hider-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 8px;

                i {
                    cursor: pointer;
                }
            }
        }
    }
}
