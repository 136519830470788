@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap");

@font-face {
    font-family: "GT Walsheim";
    src: url("./GT-Walsheim-Condensed-Ultra-Bold.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
