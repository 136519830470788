@import "src/shared/styles/colors.scss";
@import "src/shared/styles/mixins.scss";

.loan-simulator-container {
    display: flex;
    flex-direction: column;
    background-color: $neutral-50;
    border-radius: 10px;
    padding: 20px 16px;

    .loan-simulator-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;

        .loan-simulator-header-title {
            font-weight: bold;
            margin-left: 8px;
        }
    }

    .loan-simulator-content {
        display: flex;
        flex-direction: row;
        gap: 16px;
        border-top: 1px solid $neutral-200;
        padding: 16px;

        @include breakpoint(md) {
            flex-direction: column;
        }

        .eligibility-text {
            width: 50%;
            display: flex;
            flex-direction: column;

            @include breakpoint(md) {
                width: 100%;
            }

            .text-inline {
                display: inline;
                margin-top: 8px;
                &.larger-margin {
                    margin-top: 12px;
                }
            }
        }

        .uneligible-image {
            min-width: 160px;
            height: 120px;
            background-size: contain;

            @include breakpoint(md) {
                margin: auto;
            }
        }

        .uneligible-text {
            width: 100%;
            display: flex;
            flex-direction: column;

            @include breakpoint(md) {
                width: 100%;
            }

            .text-inline {
                display: inline;
                margin-top: 8px;
                &.larger-margin {
                    margin-top: 12px;
                }
            }
        }
        .loan-simulator-slider-container {
            height: 100%;
            width: 50%;
            background-color: $lilas-50;
            border-radius: 10px;
            padding: 12px;
            text-align: center;
            box-sizing: border-box;
            @include breakpoint(md) {
                width: 100%;
            }

            .loan-range-slider-container {
                width: 50%;
                margin: auto;
                @include breakpoint(md) {
                    width: 75%;
                }
                @include breakpoint(sm) {
                    width: 100%;
                }
            }

            .tooltip {
                width: 200px;
                z-index: 2;
            }
        }
    }
    .accordion-container .accordion .accordion-header {
        background-color: $neutral-100;
    }
    .accordion-container .accordion .accordion-content {
        background-color: $neutral-100;
    }

    .eligibility-accordion-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin: 16px 0;

        @include breakpoint(md) {
            grid-template-columns: 1fr;
        }

        .eligibility-item {
            background-color: $white;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 10px;
            padding: 16px;
            gap: 16px;

            .eligibility-item-image {
                min-width: 200px;
                height: 150px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                align-self: center;
            }

            .list-style-override {
                list-style: none;
                margin-left: -20px;
            }
        }
    }

    .loan-simulator-accordion {
        .accordion {
            .header {
                padding: 8px 16px;
            }
        }
    }

    .advantages-accordion-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        margin: 16px 0;

        @include breakpoint(md) {
            grid-template-columns: 1fr;
        }

        .advantages-item {
            background-color: $white;
            display: flex;
            flex-direction: column;
            gap: 16px;
            border-radius: 10px;
            padding: 16px;

            .advantages-item-image {
                min-width: 33%;
                width: 200px;
                height: 150px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                align-self: center;
            }

            .advantages-item-text {
                display: flex;
                flex-direction: column;
                text-align: center;
            }
        }
    }

    .uneligible-image img,
    .eligibility-item-image img,
    .advantages-item-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
